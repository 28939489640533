<template>
    <div class="app-container">
        <div class="app-content-container">
            <div @click="this.$router.push('/')" style="cursor: pointer;" class="is-text-centered">
                <img src="../assets/images/yoloa-logo.svg" class="logo mb-4"/><br/>
                <button class="button has-text">Zurück zur Startseite</button>
                <br/><br/>
            </div>

            <h1 class="title is-text-centered">Impressum</h1>

            <p style="text-align:left;"><strong>Website</strong>
            </p>
            <p style="text-align:left;">Dieses Impressum gilt für alle Angebote unter der Domain www.yoloa.de
                inklusive
                aller Subdomains (Unterseiten).<br/><br/>
            </p>
            <p style="text-align:left;"><strong>Soziale Medien</strong>
            </p>
            <p style="text-align:left;">Dieses Impressum gilt auch für unsere Auftritte in den folgenden sozialen
                Medien:<br>Instagram: https://www.instagram.com/yoloa_lifedesign/<br>LinkedIn:
                https://www.linkedin.com/company/yoloa-lifedesign<br><br/>
            </p>
            <p style="text-align:left;"><strong>Angaben gemäß &sect; 5 TMG</strong>
            </p>
            <p style="text-align:left;">Yoloa GmbH<br>Planegger Straße 22g<br>82131 Gauting<br>Telefon: +49 1579
                2487321<br>E-Mail:
                kontakt@yoloa.de<br><span class="blankLine">&nbsp;</span>
            </p>
            <p>Version 0.1 (beta)</p>
            <p style="text-align:left;"><strong>Eintragung</strong>
            </p>
            <p style="text-align:left;">Register: Registergericht München<br>Registernummer: HRB 242140<br/><br/>
            </p>
            <p style="text-align:left;"><strong>Umsatzsteuer-Identifikationsnummer<br/></strong>
            </p>
            <p style="text-align:left;">DE319690705<br/><br/>
            </p>
            <p style="text-align:left;">
            </p>
            <p style="text-align:left;"><strong>Vertretungsberechtigte Personen</strong>
            </p>
            <p style="text-align:left;">Dominik John, Geschäftsführer<br>
            </p>
            <p style="text-align:left;"><span class="blankLine">&nbsp;</span>
            </p>
            <p style="text-align:left;"><strong>Inhaltlich verantwortlich</strong>
            </p>
            <p style="text-align:left;">Dominik John<br>Planegger Straße 22g, 82131 Gauting<br/><br/>
            </p>
            <p style="text-align:left;"><strong>Audiovisuelle Mediendienste</strong>
            </p>
            <p style="text-align:left;">Sitzland: Deutschland<br>Zuständige Aufsichtsbehörde: Bayern<br/><br/>
            </p>
            <p style="text-align:left;"><strong>Urheberrechtshinweis</strong>
            </p>
            <p style="text-align:left;">Die auf unserer Website veröffentlichen Inhalte und Werke unterliegen dem
                deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwendung des
                geistigen
                Eigentums in ideeller und materieller Sicht des Urhebers außerhalb der Grenzen des Urheberrechtes
                bedürfen
                der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers im Sinne des
                Urhebergesetzes.<br><br>Herunterladen
                und Kopien die Seite unserer Website sind nur für den privaten und nicht kommerziellen Gebrauch
                erlaubt.
                Sind die Inhalte auf unsere Website nicht von uns erstellt worden, sind die Urheberrechte Dritte zu
                beachten. Die Inhalte Dritter werden als solche kenntlich gemacht.<br><br>Sollten Sie trotzdem auf
                eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir Sie uns zu kontaktieren. Bei Bekanntwerden von
                Rechtsverletzungen werden wir derartige Inhalte unverzüglich entfernen.<br/><br/>
            </p>
            <p style="text-align:left;"><strong>Gültigkeit</strong>
            </p>
            <p style="text-align:left;">Dieses Impressum gilt ab dem 27. September 2023.
            </p>
        </div>
    </div>


</template>
<script setup lang="ts">
</script>
