import {values_numbered} from "@/assets/value-list";

export function getIdList() {
    return jobList.map((job) => job.id)
}

export const jobList = [
    {
        name: "Arbeit in Behörden",
        subgroups: "z.B. Aufgaben in der Verwaltung, verschiedene Dienstlaufbahnen als Beamter*in",
        description: "Arbeite auf verschiedenen Ebenen in der Verwaltung des Staates, beispielsweise in der Dokumentation, dem Kundenkontakt oder dem Datenschutz.",
        balance: 9,
        income: 4,
        stability: 4,
        independence: 0,
        data: 4,
        creativity: 0,
        people: 3,
        craftsmanship: 0,
        university: false,
        internationality: 3,
        id: "e92d8cad-2c4a-4812-acbb-8f3dba72b2f2",
        image: "Arbeit_Behörden"
    },
    {
        name: "Architektur",
        subgroups: "Außen vs. Innen",
        description: "Entwerfe und berechne die Umsetzung von Gebäuden oder die Gestaltung und Einrichtung von Innenräumen.",
        balance: 7,
        income: 6,
        stability: 4,
        independence: 6,
        data: 4,
        creativity: 5,
        people: 3,
        craftsmanship: 4,
        university: true,
        internationality: 2,
        id: "69dbcff8-71a6-4390-ac67-745872a63409",
        image: "Architektur"
    },
    {
        name: "Arzt/Ärztin im Krankenhaus",
        subgroups: "Uniklinik vs. kleineres Krankenhaus, z.B. Chirurgie, Internisten, Anästhesisten, Pathologen, Radiologen, Dermatologen, Pädiater",
        description: "Lerne alles über den Aufbau und die Funktion des menschlichen Körpers und wende dieses Wissen an, um Krankheiten zu diagnostizieren und behandeln und um Menschen zu helfen.",
        balance: "2*",
        income: 7,
        stability: 10,
        independence: 4,
        data: 4,
        creativity: 1,
        people: 5,
        craftsmanship: 4,
        university: true,
        internationality: 5,
        id: "6dae0928-79e7-4d09-a7c9-04ca35214a07",
        image: "Arzt_Krankenhaus"
    },
    {
        name: "Assistenz im Gesundheitsbereich",
        subgroups: "Anästhesie, Operation, Medizinisch technische*r Fachangestellte*r, labortechnische, biologische, zahntechnisch",
        description: "Unterstütze Mediziner*innen und Forscher*innen im Krankenhaus, einer Praxis oder im Labor bei ihrer Arbeit und lerne über medizinische und naturwissenschaftliche Zusammenhänge.",
        balance: 3,
        income: 5,
        stability: 9,
        independence: 3,
        data: 2,
        creativity: 1,
        people: 4,
        craftsmanship: 4,
        university: false,
        internationality: 4,
        id: "5b48255d-2016-4c87-9195-16e7f373a641",
        image: "Assistenz_Gesundheitsbereich"
    },
    {
        name: "Bäcker*in und Konditor*in",
        subgroups: "",
        description: "Backe und verziere leckere und schöne Kuchen, Torten und Desserts, überlege dir neue Kreationen und kümmere dich um den gesamten Ablauf von der Beschaffung der Zutaten bis zur Präsentation an die Kund*innen.",
        balance: 4,
        income: 2,
        stability: 2,
        independence: 7,
        data: 0,
        creativity: 3,
        people: 1,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "b8aa4c5c-c4f5-4b4a-8f1b-ba3ca521d360",
        image: "Bäcker_Konditor"
    },
    {
        name: "Bahnpersonal",
        subgroups: "z.B. Schaffner*in, Lokführer*in, Zugbegleiter*in",
        description: "Begleite oder führe Züge im Regional- und Fernverkehr, kümmere dich um die Versorgung und Ticketkontrolle der Fahrgäste und sorge für Sauberkeit und Ordnung an Bord.",
        balance: 6,
        income: 4,
        stability: 5,
        independence: 2,
        data: 1,
        creativity: 0,
        people: 4,
        craftsmanship: 3,
        university: false,
        internationality: 5,
        id: "cfa39ff0-a977-47a8-9de4-36d4a80b52e7",
        image: "Bahnpersonal"
    },
    {
        name: "Bankkaufmann*frau",
        subgroups: "",
        description: "Betreue und berate Kund*innen zu den Finanzprodukten deiner Bank, beispielsweise zu verschiedenen Konten, Anlagemöglichkeiten, Krediten und Versicherungen.",
        balance: 6,
        income: 5,
        stability: 2,
        independence: 2,
        data: 5,
        creativity: 1,
        people: 4,
        craftsmanship: 0,
        university: false,
        internationality: 6,
        id: "a8aacb17-53fa-4aea-acf5-3b4c111b7dc0",
        image: "Bankkaufmann_frau"
    },
    {
        name: "Bauzeichner*in",
        subgroups: "",
        description: "Erstelle im Auftrag von Bauingenieur*innen und Architekt*innen maßstabsgerechte Zeichnungen und Baupläne für Gebäude, Infrastruktur und weitere Bauvorhaben.",
        balance: 6,
        income: 4,
        stability: 2,
        independence: 4,
        data: 5,
        creativity: 4,
        people: 2,
        craftsmanship: 5,
        university: false,
        internationality: 3,
        id: "43389bc8-4502-46b9-bb60-75d64a4e9acb",
        image: "Bauzeichner"
    },
    {
        name: "Berufsfeuerwehr",
        subgroups: "",
        description: "Rette und berge Menschen, Tiere und Objekte aus Gefahrensituationen, beispielsweise bei Bränden, Unwettern, Unfällen oder Naturkatastrophen.",
        balance: 3,
        income: 4,
        stability: 9,
        independence: 6,
        data: 1,
        creativity: 2,
        people: 4,
        craftsmanship: 4,
        university: false,
        internationality: 2,
        id: "fca010b3-4d70-4d8a-9773-22cd31d1ebc0",
        image: "Berufsfeuerwehr"
    },
    {
        name: "Berufssport",
        subgroups: "",
        description: "Nutze dein Talent für Sport, mach dein Hobby zum Beruf und trete regelmäßig in Wettbewerben und Meisterschaften gegen die besten Sportler*innen auf der ganzen Welt an.",
        balance: "3*",
        income: "3*",
        stability: 10,
        independence: 8,
        data: 0,
        creativity: 1,
        people: 3,
        craftsmanship: 5,
        university: false,
        internationality: 9,
        id: "0d18b654-d2ab-4532-8551-3dfa26b8d52d",
        image: "Berufssportler"
    },
    {
        name: "Bestattungswesen",
        subgroups: "",
        description: "Begleite und tröste Hinterbliebene in aufwühlenden Lebenssituationen und stelle sicher, dass der*die Verstorbene entsprechend seinen*ihren Wünschen eine würdige Bestattung bekommt.",
        balance: 7,
        income: 4,
        stability: 9,
        independence: 7,
        data: 3,
        creativity: 4,
        people: 5,
        craftsmanship: 4,
        university: false,
        internationality: 2,
        id: "25d26aad-6a81-4bdc-b518-fd0e481b47c2",
        image: "Bestattungswesen"
    },
    {
        name: "Börsen- und Finanzmakler*in",
        subgroups: "",
        description: "Werde Experte*in für die vielen verschiedenen Finanzprodukte an der Börse und berate Anleger*innen dazu.",
        balance: 4,
        income: "8*",
        stability: 5,
        independence: 7,
        data: 5,
        creativity: 2,
        people: 2,
        craftsmanship: 0,
        university: false,
        internationality: 5,
        id: "7fbad560-87cc-4ce3-a9e3-1a377e4764af",
        image: "Börsen_Finanzmakler"
    },
    {
        name: "Brauwesen",
        subgroups: "Brauer*in und Mälzer*in",
        description: "Stelle beispielsweise Bier und Malz mithilfe automatisierter Methoden her und warte die dazu notwendigen Geräte.",
        balance: 6,
        income: 5,
        stability: 1,
        independence: 5,
        data: 4,
        creativity: 3,
        people: 4,
        craftsmanship: 4,
        university: false,
        internationality: 3,
        id: "b58f606d-7c09-47f5-8577-9f85739900a6",
        image: "Brauereiwesen"
    },
    {
        name: "Bühnendarsteller*in",
        subgroups: "Fernsehen, Theater, Musical, Tanz, Oper",
        description: "Schlüpfe in die unterschiedlichsten Rollen und nutze deine Talente in Schauspiel, Gesang, Tanz und vielem mehr, um Figuren aus Theaterstücken, Musicals, Opern oder Drehbüchern zu spielen.",
        balance: 5,
        income: "4*",
        stability: 7,
        independence: 9,
        data: 0,
        creativity: 5,
        people: 4,
        craftsmanship: 3,
        university: false,
        internationality: 8,
        id: "0a9a15e3-687c-4c5f-8bba-a083fcca2093",
        image: "Bühnendarsteller"
    },
    {
        name: "Bundeswehr",
        subgroups: "z.B. Berufssoldat*in, Verwaltung, Arzt*Ärztin, Jurist*in Mechaniker*in, IT-Spezialist*in, verschiedene Dienstlaufbahnen",
        description: "Schlage eine militärische oder zivile Laufbahn ein und arbeite als Berufssoldat*in, Arzt*Ärztin, Jurist*in, Mechaniker*in, IT-Spezialist*in oder in einem der zahlreichen anderen Einsatzgebiete.",
        balance: "4*",
        income: 6,
        stability: 8,
        independence: 1,
        data: "3*",
        creativity: "1*",
        people: "3*",
        craftsmanship: "3*",
        university: false,
        internationality: "8*",
        id: "242d98c7-1a97-4b06-b88b-e7df814786b6",
        image: "Bundeswehr"
    },
    {
        name: "Compliance und Rechtsberatung in Unternehmen",
        description: "Beschäftige dich mit nationalen und internationalen Gesetzen und Vorgaben und sorge dafür, dass dein Unternehmen diese bei seinen Produkten und Dienstleistungen einhält.",
        balance: 5,
        income: 8,
        stability: 8,
        independence: 5,
        data: 5,
        creativity: 1,
        people: 3,
        craftsmanship: 0,
        university: false,
        internationality: 8,
        id: "56c5edcf-07d2-4a3d-b2c7-6f4ba8badc3d",
        image: "Compliance_Rechtsberatung"
    },
    {
        name: "Data Science",
        description: "Analysiere große Datenmengen mithilfe von Algorithmen und KI und leite daraus Muster und Prognosen ab.",
        balance: 6,
        income: 6,
        stability: 6,
        independence: 5,
        data: 5,
        creativity: 0,
        people: 1,
        craftsmanship: 0,
        university: true,
        internationality: 8,
        id: "5a0557e2-cda7-4382-85fb-0d04aa6d5dae",
        image: "Data_Science"
    },
    {
        name: "Diplomatischer Dienst",
        description: "Vermittle auf internationaler Ebene zwischen den Interessen deines Landes und globalen Anliegen und lerne dabei fremde Länder, Sprachen und Kulturen kennen.",
        balance: 2,
        income: 8,
        stability: 7,
        independence: 2,
        data: 4,
        creativity: 2,
        people: 5,
        craftsmanship: 0,
        university: true,
        internationality: 10,
        id: "f46f2ada-2100-49c8-a8cb-3f1e40c05b97",
        image: "Diplomatischer_Dienst"
    },
    {
        name: "Einkauf in der Wirtschaft (Supply Chain Management)",
        description: "Kümmere dich um den gesamten logistischen Prozess hinter der Herstellung der Produkte deines Unternehmens – von der Rohstoffbeschaffung über die Produktionsabläufe bis hin zur Lieferung an Kunde*innen.",
        balance: 6,
        income: 6,
        stability: 6,
        independence: 5,
        data: 4,
        creativity: 1,
        people: 3,
        craftsmanship: 0,
        university: false,
        internationality: "5*",
        id: "d1cb11ab-5a63-4d9d-8ff3-144fc78a14d8",
        image: "Einkauf_Supply_Chain"
    },
    {
        name: "Elektrik / Elektronik",
        subtitle: "Industrie, Gebäude und Infrastruktur, Geräte und Systeme",
        description: "Entwerfe, baue, prüfe und warte elektrische Systeme und Maschinen in der Industrie, Gebäuden, Infrastruktur und Geräten.",
        balance: 6,
        income: 4,
        stability: 3,
        independence: 7,
        data: 4,
        creativity: 1,
        people: 2,
        craftsmanship: 5,
        university: false,
        internationality: 2,
        id: "7978e2d1-cf3c-4f11-81a5-5a1d83269e99",
        image: "Elektriker"
    },
    {
        name: "Erziehung",
        subtitle: "Erzieher*in",
        description: "Erziehe die Kleinsten in unserer Gesellschaft und sorge dafür, dass sie einen guten Start ins Leben bekommen.",
        balance: 7,
        income: 3,
        stability: 10,
        independence: 7,
        data: 0,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 5,
        id: "5d9306e6-9ed3-492c-b5a1-c66bf604ae89",
        image: "Erzieher"
    },
    {
        name: "Finanzwesen",
        subtitle: "Controlling, Buchhaltung, Rechnungswesen",
        description: "Überwache und analysiere die Zahlen deines Unternehmens und entwickle Strategien, wie Prozesse in Zukunft noch effizienter gestaltet werden können.",
        balance: 6,
        income: 6,
        stability: 2,
        independence: 2,
        data: 5,
        creativity: 0,
        people: 1,
        craftsmanship: 0,
        university: false,
        internationality: 6,
        id: "3e2c6ffb-d65e-4ba4-8790-90433c2162a2",
        image: "Finanzwesen"
    },
    {
        name: "Floristik",
        description: "Mach Menschen mit selbst gestalteten und gebauten Blumengestecken, Sträußen und Pflanzen eine Freude.",
        balance: 4,
        income: 1,
        stability: 7,
        independence: 8,
        data: 1,
        creativity: 5,
        people: 4,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "4b5e306c-0300-4555-8974-917478f2d7a7",
        image: "Florist"
    },
    {
        name: "Flughafenpersonal",
        subtitle: "z.B. Flugbegleiter*in, Bodenpersonal, Check-In-Agent",
        description: "Versorge Passagier*innen auf dem Flughafen oder im Flugzeug, kümmere dich um die Einhaltung der sicherheitsrelevanten Vorschriften und sorge für einen reibungslosen Ablauf des Flugbetriebs.",
        balance: 5,
        income: 3,
        stability: 7,
        independence: 1,
        data: 1,
        creativity: 0,
        people: 4,
        craftsmanship: 0,
        university: false,
        internationality: 9,
        id: "51ab1907-fea4-4b38-8621-bf0d28bf3884",
        image: "Flughafenpersonal"
    },
    {
        name: "Forstwirtschaft und Jagdbetrieb",
        subtitle: "Förster*in, Jäger*in",
        description: "Kümmere dich an der freien Natur um Pflege und Bewirtschaftung von Wald- und Forstgebieten sowie um Artenschutz und Planung der Bejagung.",
        balance: 7,
        income: 4,
        stability: 9,
        independence: 7,
        data: 2,
        creativity: 3,
        people: 2,
        craftsmanship: 4,
        university: false,
        internationality: 1,
        id: "a7226051-603e-47e4-94c5-bf020e64204a",
        image: "Forstwirtschaft"
    },
    {
        name: "Freischaffende Künste",
        subtitle: "z.B. Gemälde, Literatur, Musik, Bildhauerei, Theater, Fotografie",
        description: "Lebe selbstständig deine Kreativität aus und erschaffe Kunstwerke wie Gemälde, Musik, Bildhauerei, Fotografie, Gedichte, Theaterstücke oder vieles mehr.",
        balance: 7,
        income: 2,
        stability: 5,
        independence: 10,
        data: 0,
        creativity: 5,
        people: 3,
        craftsmanship: 4,
        university: false,
        internationality: 5,
        id: "63eb5d0b-f573-4f4c-be4f-d343b149eeae",
        image: "Freischaffende_Kunst"
    },
    {
        name: "Gartenbau und Landschaftsgärtnerei",
        description: "Gestalte wunderschöne Gartenanlagen und Parks und arbeite in der Natur.",
        balance: 6,
        income: 4,
        stability: 6,
        independence: 7,
        data: 1,
        creativity: 4,
        people: 4,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "2235d6aa-d939-4378-bf0f-f78152304478",
        image: "Gartenbau"
    },
    {
        name: "Grafikdesign",
        subtitle: "Websites, UX Design, Gamedesign, Illustrationen; Mediengestaltung",
        description: "Lebe deine Kreativität aus und designe schöne und professionelle 2- und 3D-Grafiken für Bücher, Websites, Apps, Spiele und vieles mehr.",
        balance: 7,
        income: 5,
        stability: 6,
        independence: 8,
        data: 2,
        creativity: 5,
        people: 3,
        craftsmanship: 1,
        university: false,
        internationality: 4,
        id: "1dc237fc-58b2-4f1f-b006-104b17e186fe",
        image: "Grafikdesign"
    },
    {
        name: "Heilerziehungspflege",
        subtitle: "Heilerziehungspfleger*in, Heilpädagogik",
        description: "Begleite, unterstütze und fördere Menschen aller Altersstufen mit körperlichen, seelischen und geistigen Beeinträchtigungen.",
        balance: 7,
        income: 4,
        stability: 10,
        independence: 7,
        data: 0,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 5,
        id: "e78922fa-2aa3-489c-a134-f2eccb6b5b66",
        image: "Heilerziehungspflege"
    },
    {
        name: "Hotellerie",
        subtitle: "z.B. Hotelfachmann*frau",
        description: "Betreue Gäste an der Rezeption, in der Gastronomie und in vielen weiteren Bereichen deines Hotels, deiner Herberge oder Pension und behalte die wirtschaftlichen und organisatorischen Herausforderungen im Auge.",
        balance: 4,
        income: 2,
        stability: 2,
        independence: 1,
        data: 1,
        creativity: 1,
        people: 5,
        craftsmanship: 2,
        university: false,
        internationality: 9,
        id: "e688c0b9-dbf9-4afa-a3cf-66d9c489d750",
        image: "Hotellerie"
    },
    {
        name: "Human Resources (Personalwesen)",
        description: "Vermittle zwischen Arbeitnehmer*in und Arbeitgeber, stelle die am besten qualifizierten Bewerber*innen ein und berate deine Firma in Fragen von Vergütung, Verträgen und Personalbedarf.",
        balance: 6,
        income: 6,
        stability: 5,
        independence: 5,
        data: 3,
        creativity: 1,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 7,
        id: "f7e0d563-58b9-4f4c-a13e-2f3f693574ab",
        image: "Human_Resources"
    },
    {
        name: "Ingenieurwesen",
        subtitle: "Bau-, Maschinenbau-, Wirtschafts-, Chemie-, Mechatronik-, Luft-, Raumfahrt-, Elektrotechnik-, Biotechnik-, Medizintechnik-, Umwelt-, Metallverarbeitungs-, Energietechnik-, Industrie",
        description: "Erfinde, entwickle und konstruiere technische Produkte und Lösungen für alle erdenklichen Anwendungsbereiche.",
        balance: 6,
        income: 6,
        stability: 4,
        independence: 4,
        data: 5,
        creativity: 3,
        people: 2,
        craftsmanship: 3,
        university: true,
        internationality: 2,
        id: "1885bfa5-e042-468d-9a45-cb734a0943b5",
        image: "Ingenieurwesen"
    },
    {
        name: "Instrumentenbau und Erhaltung",
        description: "Plane, entwerfe und baue perfekt klingende Instrumente für deine Kund*innen, halte sie instand und repariere sie bei Bedarf.",
        balance: 6,
        income: 3,
        stability: 3,
        independence: 9,
        data: 1,
        creativity: 5,
        people: 2,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "dfe46245-afda-4ca0-ae37-982620635667",
        image: "Instrumentenbau"
    },
    {
        name: "IT und Softwareentwicklung",
        subtitle: "Informatiker, Programmierer, Systemadministrator, Softwareentwickler (Frontend, Backend, Fullstack)",
        description: "Werde Experte*in für Web Development und arbeite mit Servern, neuen Softwarelösungen, Websites, Apps und vielem mehr.",
        balance: 6,
        income: 8,
        stability: 9,
        independence: 8,
        data: 5,
        creativity: 4,
        people: 1,
        craftsmanship: 1,
        university: false,
        internationality: 5,
        id: "dce26fd7-0132-4a7d-b4bb-824c22a9f794",
        image: "IT_Softwareentwicklung"
    },
    {
        name: "Journalismus",
        subtitle: "Selbstständig vs. angestellt bei Zeitungen/Sendern etc. Verschiedene Bereiche von Investigativjournalismus über Wirtschaft bis zu Unterhaltung",
        description: "Recherchiere und berichte selbstständig oder im festen Auftrag von Sendern, Magazinen etc über verschiedenste Bereiche von Investigativjournalismus über Wirtschafts- und Politikthemen bis hin zu Sport und Unterhaltung.",
        balance: 5,
        income: 6,
        stability: 6,
        independence: 8,
        data: 3,
        creativity: 5,
        people: 5,
        craftsmanship: 1,
        university: false,
        internationality: 9,
        id: "3fcf2a84-ab0b-4cce-aabe-c12b0f013a94",
        image: "Journalismus"
    },
    {
        name: "Jurist*in im Staatsdienst",
        subtitle: "z.B. Richter*in, Staatsanwalt*anwältin",
        description: "Verstehe rechtliche Zusammenhänge und Auslegungsmöglichkeiten und vertrete das Gesetz als Angestellte*r des Staates.",
        balance: 7,
        income: 7,
        stability: 6,
        independence: 4,
        data: 2,
        creativity: 2,
        people: 4,
        craftsmanship: 0,
        university: true,
        internationality: 4,
        id: "d896d899-e897-4315-a850-c8706a7865ba",
        image: "Jurist_Kanzlei"
    },
    {
        name: "Jurist*in in einer Kanzlei",
        subtitle: "groß vs. klein; Branchen wie Wirtschaftsrecht, Familienrecht, Arbeitsrecht etc",
        description: "Lerne, das Gesetz zu verstehen und auszulegen und nutze dein Wissen, um Mandant*innen in den verschiedensten Rechtsbereichen wie z.B. Arbeits-, Wirtschafts- oder Familienrecht zu vertreten.",
        balance: 6,
        income: 7,
        stability: 4,
        independence: 6,
        data: 2,
        creativity: 2,
        people: 4,
        craftsmanship: 0,
        university: true,
        internationality: 6,
        id: "f837a320-44a3-4c07-8ed2-a3e2e2859341",
        image: "Jurist_Staatsdienst"
    },
    {
        name: "KI-Entwicklung",
        description: "Nutze dein Wissen über Programmieren, Software und Datenverarbeitung, um für die verschiedensten Einsatz.B.ereiche Künstliche Intelligenz zu entwickeln und verbessern.",
        balance: 6,
        income: 7,
        stability: 9,
        independence: 7,
        data: 5,
        creativity: 3,
        people: 1,
        craftsmanship: 1,
        university: false,
        internationality: 6,
        id: "c785a522-38d8-417a-82e0-d70f8e33f2af",
        image: "KI_Entwicklung"
    },
    {
        name: "Kirchliches Amt",
        subtitle: "verschiedene Religionen; bei Christen z.B. Pfarrer*in, Priester, Seelsorge, etc",
        description: "Entwickle deinen eigenen religiösen Glauben weiter und kümmere dich um deine Gemeinschaft an Gläubigen sowie unter anderem um verwaltungstechnische und öffentlichkeitswirksame Aufgaben.",
        balance: 8,
        income: 5,
        stability: 4,
        independence: 7,
        data: 2,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 5,
        id: "3f325b92-43a5-469a-84de-22f478bade35",
        image: "Kirchliches_Amt"
    },
    {
        name: "Klempner*in",
        description: "Baue und unterhalte Anlagen und Technik in einem der handwerklichen Bereiche, den Menschen immer brauchen werden: dem Sanitärbereich.",
        balance: 6,
        income: 3,
        stability: 6,
        independence: 7,
        data: 1,
        creativity: 2,
        people: 3,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "2e492cdf-5536-41ba-9f45-269fbda85c33",
        image: "Klempner"
    },
    {
        name: "Koch*Köchin",
        description: "Lerne die besten Küchentricks, entwickle neue Rezepte, erstelle Menüs und kümmere dich um den gesamten Arbeitsablauf von der Beschaffung der Zutaten über die Herstellung der Speisen bis zur Präsentation an deine Kund*innen.",
        balance: 3,
        income: 4,
        stability: 6,
        independence: 5,
        data: 0,
        creativity: 4,
        people: 1,
        craftsmanship: 5,
        university: false,
        internationality: 5,
        id: "68dd3787-a73e-4a7f-92b3-42315351f2fd",
        image: "Koch"
    },
    {
        name: "Kraftfahrzeugmechatronik",
        description: "Lerne alles über die Funktionsweise von Autos und verstehe und repariere nicht nur mechanische, sondern auch elektrische Bestandteile.",
        balance: 5,
        income: 3,
        stability: 3,
        independence: 6,
        data: 4,
        creativity: 2,
        people: 4,
        craftsmanship: 5,
        university: false,
        internationality: 2,
        id: "0fc192f0-c4b4-4221-89a1-d9c4e235980e",
        image: "KFZ_Mechatroniker"
    },
    {
        name: "Kundenbetreuung im Unternehmen",
        subtitle: "Vertrieb, Account Management",
        description: "Sei Ansprechpartner*in für alle Anliegen von Kund*innen und hilf ihnen dabei, die bestmögliche Erfahrung mit deinem Unternehmen zu machen.",
        balance: 6,
        income: 7,
        stability: 8,
        independence: 5,
        data: 2,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 7,
        id: "0df6131c-2099-4138-a76e-2f2f4ef5a4d6",
        image: "Kundenbetreuung"
    },
    {
        name: "Landwirtschaft",
        subgroups: "z.B. Pferdewirtschaft, Landwirtschaftliche*r Beschäftigte*r, Winzer*in, Inhaber*in eines landwirtschaftlichen Betriebs",
        description: "Arbeite in einem der vielfältigen landwirtschaftlichen Bereiche mit Natur und Tieren und versorge andere mit wichtigen Produkten und Dienstleistungen.",
        balance: 6,
        income: 5,
        stability: 8,
        independence: 8,
        data: 3,
        creativity: 3,
        people: 2,
        craftsmanship: 5,
        university: false,
        internationality: 2,
        id: "309e666a-c208-46a2-9101-ab748aaf1e61",
        image: "Landwirtschaft"
    },
    {
        name: "Lehramt",
        subgroups: "Grundschule, Weiterführende Schulen verschiedene Jahrgangsstufen, Gehörlosenschulen etc",
        description: "Gib deine Freude am Lernen an Kinder und Jugendliche weiter und vermittele ihnen alles, was sie brauchen, um im späteren Leben erfolgreich zu sein.",
        balance: 9,
        income: 6,
        stability: 8,
        independence: 5,
        data: 2,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: true,
        internationality: 2,
        id: "8f9b4974-bb6f-48ff-b5a5-80eaef4fcfaf",
        image: "Lehramt"
    },
    {
        name: "Maler*in und Lackierer*in",
        subgroups: "",
        description: "Verpasse Gebäuden, Innenräumen und Objekten wie Autos einen neuen Anstrich und schöne Farben.",
        balance: 6,
        income: 3,
        stability: 7,
        independence: 7,
        data: 1,
        creativity: 3,
        people: 3,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "ff458e80-cb46-4006-b3e6-4aeec3d8ccf7",
        image: "Maler_Lackierer"
    },
    {
        name: "Management, Unternehmensführung",
        subgroups: "",
        description: "Treffe Entscheidungen, entwickle Strategien und leite Menschen in deiner Firma an – von einzelnen Teams über Abteilungen bis hin zu ganzen Unternehmen.",
        balance: 4,
        income: 9,
        stability: 8,
        independence: 8,
        data: 4,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 7,
        id: "03958653-185c-48f4-9d52-12c84c170305",
        image: "Management_Unternehmensführung"
    },
    {
        name: "Marketing und Werbung",
        subgroups: "",
        description: "Finde heraus, was Menschen und Zielgruppen wirklich anspricht und unterstütze Unternehmen dabei, ihre Produkte und Werbung genau darauf zuzuschneiden.",
        balance: 6,
        income: 5,
        stability: 5,
        independence: 6,
        data: 2,
        creativity: 5,
        people: 5,
        craftsmanship: 2,
        university: false,
        internationality: 6,
        id: "6d5ef0c1-d688-403a-a6ea-ba95acdc20e7",
        image: "Marketing_Werbung"
    },
    {
        name: "Masken- und Bühnenbildner*in",
        subgroups: "",
        description: "Erwecke die Ideen von Drehbuchautor*innen und Regisseur*innen zum Leben, indem du deine kreativen Ideen für Bühnenbild oder Maske und Kostüm umsetzt.",
        balance: 7,
        income: 1,
        stability: 8,
        independence: 9,
        data: 0,
        creativity: 5,
        people: 4,
        craftsmanship: 5,
        university: false,
        internationality: 6,
        id: "d871bc24-36ed-4986-be72-ec532b67b2b1",
        image: "Maskenbildner"
    },
    {
        name: "Mechaniker*in",
        subgroups: "Industrie-, Verfahrens- für z.B. Kunststofftechnik, Werkzeug-, Konstruktions-, Anlagen-, Reifentechnik-, Fertigungs-, Fluggeräte-, Fahrzeug- und Karosseriebau-, Zerspanung",
        description: "Baue für die verschiedensten Branchen technische Geräte und Anwendungen und halte sie instand.",
        balance: 5,
        income: 3,
        stability: 1,
        independence: 6,
        data: 4,
        creativity: 3,
        people: 2,
        craftsmanship: 5,
        university: false,
        internationality: 3,
        id: "25702a32-7b0f-46cd-bd01-354fbc80240a",
        image: "Mechaniker"
    },
    {
        name: "Modebereich",
        subgroups: "Modedesign, Schneider*in, Kostümbildner*in",
        description: "Entwerfe kreative und stylische Designs für Mode, Kostüme und Accessoires und setze sie selber um.",
        balance: 6,
        income: 5,
        stability: 6,
        independence: 8,
        data: 0,
        creativity: 5,
        people: 3,
        craftsmanship: 5,
        university: false,
        internationality: 7,
        id: "0dab61be-a09c-49b7-af6e-9a061cbe7272",
        image: "Modebereich"
    },
    {
        name: "Musiker*in im Orchester oder Solokünstler*in",
        subgroups: "z.B. Orchester, Klavier, Sänger*in, Band",
        description: "Lebe deine Kreativität und dein Talent aus und produziere in Chor, Orchester, Solo oder in einer Band mitreißende Musikwerke.",
        balance: 7,
        income: 3,
        stability: 7,
        independence: 8,
        data: 0,
        creativity: 5,
        people: 4,
        craftsmanship: 4,
        university: false,
        internationality: 8,
        id: "78760f15-ac47-4efb-add2-cf2b97fdd28e",
        image: "Musiker_Orchester_Solo"
    },
    {
        name: "Niedergelassene*r Mediziner*in",
        subgroups: "angestellt vs. eigene Praxis. z.B. MVZ, Allgemeinmediziner, Internisten, Pathologen, Radiologen, Dermatologen, Pädiater, Gyn, Notarzt",
        description: "Lerne alles über den Aufbau und die Funktion des menschlichen Körpers und wende dieses Wissen an, um Krankheiten zu diagnostizieren und behandeln und Menschen zu helfen.",
        balance: 4,
        income: 8,
        stability: 10,
        independence: 8,
        data: 4,
        creativity: 1,
        people: 5,
        craftsmanship: 3,
        university: true,
        internationality: 4,
        id: "49ffddd2-63d2-48d9-a0d0-dd5280147130",
        image: "Niedergelassene_Mediziner"
    },
    {
        name: "Notar*in",
        subgroups: "Nutze dein Wissen über Gesetzgebung und Recht, um als unabhängiger Vermittler wichtige rechtliche Geschäfte zu beurkunden und die beteiligten Personen zu schützen.",
        description: "Nutze dein Wissen über Gesetzgebung und Recht, um als unabhängiger Vermittler wichtige rechtliche Geschäfte zu beurkunden und die beteiligten Personen zu schützen.",
        balance: 6,
        income: 9,
        stability: 7,
        independence: 8,
        data: 5,
        creativity: 2,
        people: 4,
        craftsmanship: 0,
        university: true,  // "ja" is converted to true
        internationality: 4,
        id: "2aa5c1dd-c935-496d-831e-3b178d336960",
        image: "Notar"
    },
    {
        name: "Office Management",
        subgroups: "zB Bürokaufmann*frau, Personal Assistant, Assistenz der Geschäftsführung",
        description: "Unterstütze im Büro durch verschiedenste Aufgaben von Verwaltung über Buchhaltung und Telefonie bis hin zu Kundenkontakt und Vorbereitung von Meetings.",
        balance: 7,
        income: 5,
        stability: 5,
        independence: 2,
        data: 3,
        creativity: 1,
        people: 4,
        craftsmanship: 0,
        university: false,  // "nein" is converted to false
        internationality: 3,
        id: "6be38d14-ebe6-495a-89d0-b3188ab30d87",
        image: "Office_Management"
    },
    {
        name: "Pflegeberufe im Gesundheitssektor",
        subgroups: "zT auch über Studium möglich! zB Gesundheits- und Krankenpflege, Altenpflege, Kinderpflege, Hebammenwissenschaften, Pflegeassistenz",
        description: "Pflege und unterstütze Menschen in Ausnahmesituationen ihres Lebens.",
        balance: 2,
        income: 4,
        stability: 9,
        independence: 3,
        data: 1,
        creativity: 1,
        people: 5,
        craftsmanship: 3,
        university: false,  // "nein" is converted to false
        internationality: 2,
        id: "636daf51-d3d3-4ead-9244-e5fc5e3585bb",
        image: "Pflegeberufe"
    },
    {
        name: "Pharmazie",
        subgroups: "Apotheken vs Pharmaunternehmen",
        description: "Lerne alles über die Herstellung, Weiterentwicklung, Testung und Wirkung von Medikamenten und arbeite entweder in einem größeren Unternehmen oder in einer Apotheke.",
        balance: 6,
        income: 7,
        stability: 6,
        independence: 7,
        data: 4,
        creativity: 2,
        people: 3,
        craftsmanship: 3,
        university: true,  // "ja" is converted to true
        internationality: 7,
        id: "553ecff2-cf2f-44a9-a723-40912e341cb7",
        image: "Pharmazie"
    },
    {
        name: "Pilot*in",
        subgroups: "",
        description: "Sitze selber am Steuer, fliege Passagier- oder Frachtflugzeuge sicher von A nach B und lerne nebenbei die Welt kennen.",
        balance: 4,
        income: 7,
        stability: 7,
        independence: 4,
        data: 4,
        creativity: 0,
        people: 1,
        craftsmanship: 4,
        university: false,  // "nein" is converted to false
        internationality: 8,
        id: "846f51bf-163f-4a32-8bfd-4c5396638dab",
        image: "Pilot"
    },
    {
        name: "Politiker*in",
        subgroups: "",
        description: "Vertrete deine Wähler*innen auf verschiedenen politischen Ebenen und setze politische Entscheidungen zu ihren Gunsten durch.",
        balance: 4,
        income: "8*",
        stability: 9,
        independence: 7,
        data: 4,
        creativity: 2,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 9,
        id: "b4f15785-0ab9-439e-90f5-8af4f9856052",
        image: "Politik"
    },
    {
        name: "Polizei",
        subgroups: "z.B. IT, Grenzpolizei, Zoll, Schutz von staatlichen Organen, Ärztlicher Dienst, Verwaltung, Kriminalitätsbekämpfung",
        description: "Schütze Menschen und Objekte über die Arbeit in einem der verschiedensten Bereiche der Polizei – von der aktiven Kriminalitätsbekämpfung über den Grenzschutz bis hin zu Verwaltung und IT-Sicherheit.",
        balance: 5,
        income: 3,
        stability: "6*",
        independence: 1,
        data: 3,
        creativity: 2,
        people: 4,
        craftsmanship: 2,
        university: false,
        internationality: "5*",
        id: "bd15a0bd-d74d-44f6-913b-2a3d3ed45140",
        image: "Polizei"
    },
    {
        name: "Produktdesign",
        subgroups: "Konsumprodukte und Industrieprodukte",
        description: "Arbeite kreativ und entwerfe innovative Designs für Konsum- und Industrieprodukte.",
        balance: 6,
        income: 3,
        stability: 2,
        independence: 7,
        data: 2,
        creativity: 5,
        people: 4,
        craftsmanship: 2,
        university: false,
        internationality: 5,
        id: "1899f638-ef57-49e4-869b-87259cb27bd4",
        image: "Produktdesign"
    },
    {
        name: "Professur",
        subgroups: "Universitäten vs. Hochschulen",
        description: "Lehre und forsche an Universitäten oder Hochschulen und werde Experte*in für deinen Fachbereich.",
        balance: 8,
        income: 8,
        stability: 9,
        independence: 8,
        data: 3,
        creativity: 3,
        people: 5,
        craftsmanship: 1,
        university: true,
        internationality: 10,
        id: "2c77405c-d4bd-4d04-9f93-37ddce61b816",
        image: "Professur"
    },
    {
        name: "Psychotherapie, Personal Coaching",
        subgroups: "Psychologisch oder über Medizin; Coaching auch ohne Ausbildung in dem Bereich möglich",
        description: "Lerne, die menschliche Psyche besser zu verstehen und begleite und unterstütze Menschen in ihren verschiedensten, zum Teil schwierigsten Lebenssituationen.",
        balance: 7,
        income: 8,
        stability: 7,
        independence: 9,
        data: 1,
        creativity: 4,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 3,
        id: "1c14dcbb-562d-4d1c-8e86-14cda8243971",
        image: "Psychotherapie_Coaching"
    },
    {
        name: "Rechtsanwalts- und Notarfachangestellte*r",
        subgroups: "",
        description: "Unterstütze Rechtsanwälte*innen oder Notare*innen dabei, bei Fällen und Mandant*innen den Überblick zu behalten und lerne währenddessen über rechtliche Zusammenhänge.",
        balance: 6,
        income: 2,
        stability: 3,
        independence: 2,
        data: 3,
        creativity: 1,
        people: 3,
        craftsmanship: 1,
        university: false,
        internationality: 3,
        id: "47cad6ab-1892-49d7-a155-98afa9424dbe",
        image: "Rechtsanwaltsfachangestellte"
    },
    {
        name: "Rettungsdienst",
        subgroups: "Notfallsanitäter*in, Rettungssanitäter*in",
        description: "Rette Menschenleben und behalte einen kühlen Kopf in Situationen, die eine Ausnahmesituation für fast jeden darstellen würden.",
        balance: 2,
        income: 2,
        stability: 10,
        independence: 3,
        data: 1,
        creativity: 1,
        people: 5,
        craftsmanship: 3,
        university: false,
        internationality: 2,
        id: "23002190-fc2a-4967-b40e-a8bad5fe29b9",
        image: "Rettungsdienst"
    },
    {
        name: "Schmuck- und Uhrenbranche",
        subgroups: "z.B. Juwelier*in, Uhrmacher*in, Goldschmied*in",
        description: "Stelle als Goldschmied*in oder Uhrmacher*in selbst Schmuck und Uhren her oder verkaufe diese als Juwelier*in an Kund*innen.",
        balance: 6,
        income: 3,
        stability: 6,
        independence: 7,
        data: 2,
        creativity: 5,
        people: "3*",
        craftsmanship: "4*",
        university: false,
        internationality: 2,
        id: "5cf670a7-f291-441e-b4c6-e985e2e6dab7",
        image: "Schmuckbranche"
    },
    {
        name: "Schreinerei / Tischlerei",
        subgroups: "",
        description: "Baue mit deinen eigenen Händen schöne und praktikable Gegenstände für den Alltag oder die Industrie.",
        balance: 6,
        income: 3,
        stability: 5,
        independence: 7,
        data: 1,
        creativity: 5,
        people: 1,
        craftsmanship: 5,
        university: false,
        internationality: 1,
        id: "9d282e99-559a-40c0-917b-f4e547f9af20",
        image: "Tischler_Schreiner"
    },
    {
        name: "Schriftsteller*in",
        subgroups: "",
        description: "Schreibe Kurzgeschichten, Bücher, Theaterstücke, wissenschaftliche Zeitungsartikel, Drehbücher oder vieles mehr.",
        balance: 7,
        income: "3*",
        stability: 6,
        independence: 10,
        data: 0,
        creativity: 5,
        people: 1,
        craftsmanship: 0,
        university: false,
        internationality: 3,
        id: "c46f3554-7c62-40c3-9af6-2f9bf4adcc07",
        image: "Schriftsteller"
    },
    {
        name: "Sozialarbeit und Sozialpädagogik",
        subgroups: "",
        description: "Erziehe, berate und betreue Menschen jeden Alters in verschiedenen, teils sehr schwierigen Lebenssituationen und hilf ihnen, diese gut zu meistern.",
        balance: 6,
        income: 4,
        stability: 10,
        independence: 7,
        data: 1,
        creativity: 3,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 4,
        id: "40b9e05f-dc91-42b0-9034-8bf2ddd4cccb",
        image: "Sozialarbeit_Sozialpädagogik"
    },
    {
        name: "Soziale Betreuung und Unterstützung",
        subgroups: "Alltagsbetreuung, Schulbegleitung, Sozialbetreuung, Sozialassistent*in, Arbeitserzieher*in",
        description: "Begleite, fördere und betreue pflege-, hilfsbedürftige oder benachteiligte Menschen beispielsweise bei alltäglichen Aufgaben, ihrer Ausbildung oder auf ihrem Weg zu einem Arbeitsplatz.",
        balance: 5,
        income: 3,
        stability: 10,
        independence: 8,
        data: 1,
        creativity: 3,
        people: 5,
        craftsmanship: 0,
        university: false,  // "nein" is converted to false
        internationality: 7,
        id: "a1deb632-8c47-4e63-8cd5-12cc7dc0fad8",
        image: "Soziale_Betreuung_Unterstützung"
    },
    {
        name: "Sporttrainer*in",
        subgroups: "z.B. Fitness, Tanzen, Yoga, Fußball, …",
        description: "Mach dein Hobby zum Beruf und leite andere Menschen beim Sport an.",
        balance: 8,
        income: 2,
        stability: 8,
        independence: 9,
        data: 0,
        creativity: 4,
        people: 5,
        craftsmanship: 1,
        university: false,
        internationality: "3*",
        id: "1cb3d7ee-df2b-4952-a8ba-8e2d08e41138",
        image: "Sporttrainer"
    },
    {
        name: "Steuerberatung und Wirtschaftsprüfung",
        subgroups: "",
        description: "Verstehe wirtschaftliche und steuerliche Zusammenhänge und unterstütze deine Klient*innen und Kund*innen mit deinem Wissen.",
        balance: 5,
        income: "6*",
        stability: 5,
        independence: 6,
        data: 5,
        creativity: 1,
        people: 3,
        craftsmanship: 0,
        university: false,
        internationality: 5,
        id: "67417243-a958-4b1d-8bc2-4a40d581e21b",
        image: 'Steuerberatung_Wirtschaftsprüfung'
    },
    {
        name: "Styling und Körperpflege",
        subgroups: "z.B. Friseur*in, Stylist*in, Makeup-Artist, Kosmetiker*in",
        description: "Sei immer über die neusten Trends informiert, berate deine Kund*innen zu für sie passenden Frisuren, Make-Up und Kosmetikprodukten und setze deine Tipps in die Realität um.",
        balance: 4,
        income: 1,
        stability: 10,
        independence: 7,
        data: 1,
        creativity: 5,
        people: 5,
        craftsmanship: 5,
        university: false,
        internationality: "2*",
        id: "297e1933-cffb-48aa-ab32-14bc41977416",
        image: 'Styling_Körperpflege'
    },
    {
        name: "Technisches Consulting für Unternehmen",
        subgroups: "",
        description: "Hilf Unternehmen mit deinem Wissen über naturwissenschaftlich-technische Zusammenhänge, innovative, kundenfreundliche und sinnvolle neue Produkte zu entwickeln.",
        balance: 5,
        income: 7,
        stability: 8,
        independence: 7,
        data: 5,
        creativity: 2,
        people: 3,
        craftsmanship: 2,
        university: false,
        internationality: 7,
        id: "e98cf6b1-daf8-48c3-96cf-ee1387bbd872",
        image: 'Technisches_Consulting'
    },
    {
        name: "Tourismus und Freizeit",
        subgroups: "z.B. Kaufmann*frau im Reisebüro",
        description: "Berate Kund*innen zu Privat- und Geschäftsreisen, erstelle Angebote und bearbeite Reservierungen und Stornierungen.",
        balance: 6,
        income: 3,
        stability: 5,
        independence: 5,
        data: 2,
        creativity: 2,
        people: 5,
        craftsmanship: 0,
        university: false,
        internationality: 8,
        id: "5a66e08c-7a18-4cda-9a2d-6743db0a4efb",
        image: 'Tourismus_Freizeit'
    },
    {
        name: "Unternehmensberatung",
        subgroups: "Selbstständig als Coach für Unternehmen vs. in großen Beratungsfirmen",
        description: "Unterstütze als Teil einer großen Beratungsfirma oder als selbstständige*r Coach Unternehmen bei wirtschaftlichen Problemen, Umstrukturierungen und neuen Produktentwicklungen mit deinem Fachwissen.",
        balance: 3,
        income: "7*",
        stability: 8,
        independence: 5,
        data: 4,
        creativity: 1,
        people: 3,
        craftsmanship: 0,
        university: true,
        internationality: 8,
        id: "4588b00d-0632-4f93-be10-e1ea51fe719f",
        image: 'Unternehmensberatung'
    },
    {
        name: "Unternehmertum (Selbstständigkeit, Start-Up-Gründung)",
        subgroups: "",
        description: "Sei dein*e eigene*r Chef*in und setze deine Ideen und Visionen genauso um, wie du es dir vorstellst.",
        balance: "4*",
        income: "6*",
        stability: "8*",
        independence: 10,
        data: 5,
        creativity: 4,
        people: 4,
        craftsmanship: 0,
        university: false,
        internationality: "8*",
        id: "49ac7e70-5b23-4de2-b771-52a23cbaafc7",
        image: 'Unternehmer_Startup'
    },
    {
        name: "Unterstützende Heilberufe im medizinischen Bereich",
        subgroups: "z.B. Logopädie, Ergotherapie, Physiotherapie",
        description: "Unterstütze Menschen nach Unfällen, Krankheiten oder mit körperlichen Einschränkungen dabei, wieder gesund zu werden oder im Alltag besser mit ihren Einschränkungen umgehen zu können.",
        balance: 7,
        income: 3,
        stability: 10,
        independence: 9,
        data: 1,
        creativity: 3,
        people: 5,
        craftsmanship: 4,
        university: false,
        internationality: 3,
        id: "49301b0f-2614-4cba-baf6-61e9c9490521",
        image: 'Unterstützende_Heilberufe_Medizin'
    },
    {
        name: "Verkäufer*in im Einzelhandel",
        subgroups: "",
        description: "Berate Kund*innen zu den verschiedenen Produkten im Angebot deines Ladens und kümmere dich um Lagerbestand und Abrechnung der Ware.",
        balance: 6,
        income: 2,
        stability: 1,
        independence: 3,
        data: 1,
        creativity: 1,
        people: 4,
        craftsmanship: 0,
        university: false,
        internationality: 1,
        id: "23437236-a59a-4dad-bf75-772c8c564c62",
        image: 'Verkäufer_Einzelhandel'
    },
    {
        name: "Versicherungswirtschaft",
        subgroups: "Versicherungsmakler*in, -berater*in, Aktuar*in, Finanzmathematiker*in",
        description: "Verstehe Finanzprodukte und -märkte, Versicherungsprodukte und vieles mehr und helfe deinen Kund*innen, die besten Optionen zu finden.",
        balance: 6,
        income: 6,
        stability: 5,
        independence: 3,
        data: 5,
        creativity: 0,
        people: 3,
        craftsmanship: 0,
        university: false,
        internationality: 4,
        id: "c3daebac-377a-4ef6-8441-891d16cf7624",
        image: 'Versicherungswirtschaft'
    },
    {
        name: "Veterinärmedizin",
        subgroups: "",
        description: "Kümmere dich um kranke und pflegebedürftige Wild- und Haustiere und sorge dafür, dass sie wieder gesund und glücklich werden.",
        balance: 5,
        income: 6,
        stability: 10,
        independence: 7,
        data: 3,
        creativity: 3,
        people: 3,
        craftsmanship: 5,
        university: true,
        internationality: 1,
        id: "00edf135-3ee2-4b21-b98d-fbc7ad231762",
        image: 'Veterinärmedizin'
    },
    {
        name: "Wissenschaftliche Arbeit an Universitäten",
        subgroups: "Naturwissenschaftler, Wirtschaftswissenschaftler, Geisteswissenschaftler",
        description: "Forsche zu einem spezifischen wissenschaftlichen Thema an einer Universität und werde Experte*in auf deinem Gebiet.",
        balance: 7,
        income: 6,
        stability: 7,
        independence: 8,
        data: 5,
        creativity: 2,
        people: 2,
        craftsmanship: 1,
        university: true,
        internationality: 7,
        id: "9c7c19df-5900-4a24-b982-22df5fdf0f62",
        image: 'Wissenschaftliche_Arbeit_Universitäten'
    },
    {
        name: "Zahnmedizin",
        subgroups: "Krankenhaus oder Praxis, Zahnarzt*ärztin oder Kieferorthopädie oder MKG",
        description: "Lerne alles über Zähne, ihre Erhaltung und Korrektur von Fehlstellungen und schenke deinen Kund*innen ein strahlendes Lächeln.",
        balance: "5*",
        income: "5*",
        stability: 10,
        independence: "7*",
        data: 4,
        creativity: 3,
        people: 5,
        craftsmanship: 5,
        university: true,
        internationality: 3,
        id: "3d29255f-799a-4c89-934a-55381654ea80",
        image: 'Zahnmedizin'
    }
]
