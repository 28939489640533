<script>
import EmailVerification from "supertokens-web-js/recipe/emailverification";
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";

export default {
    mounted: async function () {
        try {
            const response = await EmailVerification.isEmailVerified()

            if (response.isVerified === true) {
                // user is already verified
                return window.location.assign("/");
            } else {

                const verification_response = await EmailVerification.verifyEmail()
                console.log(verification_response)
                if (verification_response.status === 'OK') {
                    return window.location.assign("/");
                }
            }

        } catch (_) {
            window.location.assign("/auth?error=signin");
        }
    },
    methods: {
        resendEmail() {
            EmailVerification.sendVerificationEmail()
        },
        signOut: async function () {
            await ThirdPartyEmailPassword.signOut();
            window.location.assign("/auth");
        },
    }
}
</script>

<template>
    <div class="app-container-small">
        <div class="app-content-container">
            <div class="is-text-centered">
                <img src="../assets/images/yoloa-logo.svg" class="logo mb-4"/>
            </div>
            <div class="title is-text-centered">Fast geschafft...</div>
            <p class="mb-4">Klicke jetzt noch auf den Link in der Bestätigungsemail, um die Registrierung
                abzuschließen.</p>
            <p class="mb-4">Sie sollte jeden Moment in deinem Postfach landen.</p>
            <span class="resend-button button is-fullwidth mb-4"
                  v-on:click="resendEmail">Email erneut senden</span>
            <p>Bei der Email-Adresse vertippt? Hier kannst du dich <span class="clickable-text" v-on:click="signOut">ausloggen</span> und mit der richtigen Email registrieren.
            </p>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
