<template>
    <TopNavbar title="Bevor wir loslegen..."/>
    <div class="app-container">
        <div class="app-content-container">
            <p>Erzähl uns ein bisschen was über dich, damit wir den Kurs und unsere Inhalte passend für deine Situation
                auswählen können.</p>
            <p class="is-4 has-text-centered">{{ stage + 1 }}/2</p>
            <div v-if="stage === 0">
                <div class="field mb-4">
                    <label class="label">Wie lautet dein Vorname?</label>
                    <div class="control">
                        <input v-model="firstname" class="input" type="input"
                               :class="firstnameError? 'is-danger' : ''"/>
                    </div>
                    <p v-if="firstnameError" class="help is-danger">Bitte fülle dieses Feld aus.</p>
                </div>

                <div class="field mb-4">
                    <label class="label">Dein Ausbildungsstand</label>
                    <div class="select is-fullwidth is-medium" :class="usergroupError? 'is-danger' : ''">
                        <select name="usergroup" v-model="usergroup">
                            <option value="-1">Bitte auswählen...</option>
                            <option v-for="group in userGroups" :value="group.value" :key="group.value">
                                {{ group.text }}
                            </option>
                        </select>
                    </div>
                    <p v-if="usergroupError" class="help is-danger">Bitte fülle dieses Feld aus.</p>
                </div>
                <div class="field mb-5">
                    <label class="label">Dein Alter</label>
                    <div class="control">
                        <div class="select is-medium is-fullwidth" :class="{ 'is-danger': ageError }">
                            <select name="age" v-model="age">
                                <option value="-1">Bitte auswählen...</option>
                                <option v-for="option in ageOptions" :value="option.value" :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                        <p v-if="ageError" class="help is-danger">Bitte fülle dieses Feld aus.</p>
                    </div>
                </div>
                <div class="field mb-5" v-if="isThirdParty">
                    <div class="control">
                        <label class="checkbox" :class="{ 'is-danger': agbAcceptedError }">
                            <input type="checkbox" name="agbAccepted" v-model="agbAccepted">
                            Ich akzeptiere die <a href="/datenschutz" target="_blank">Datenschutzbestimmungen</a>.
                        </label>
                    </div>
                    <p v-if="agbAcceptedError" class="help is-danger">Bitte akzeptiere die Datenschutzbestimmungen.</p>
                </div>
                <div class="field mb-5">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" name="emailPermission" v-model="email_opt_in">
                            Yoloa darf mir per E-Mail Updates zum Online-Kurs senden und über für mich relevante neue
                            Inhalte informieren (z.B. neue Podcast-Folgen). Ich kann mich jederzeit wieder abmelden.
                        </label>
                    </div>
                </div>
                <div class=" field">
                    <div class="control mt-4">
                        <button class="button is-primary is-fullwidth" @click="nextStage()">Weiter
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="stage === 1">
                <div class="field">
                    <div class="control mb-4">
                        <button class="button" @click="previousStage()">Zurück
                        </button>
                    </div>
                </div>
                <div class="field mb-4">
                    <label>Du bist...</label>
                    <div class="control">
                        <div class="select is-medium is-fullwidth" :class="genderError? 'is-danger' : ''">
                            <select name="gender" v-model="gender" :class="genderError? 'is-danger' : ''">
                                <option value="-1">Bitte auswählen...</option>
                                <option v-for="option in genderOptions" :value="option.value" :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <p v-if="genderError" class="help is-danger">Bitte fülle dieses Feld aus.</p>
                </div>
                <!-- Sektion für Schüler -->
                <div v-if="usergroup == 0">
                    <div class="field mb-4">
                        <label>Welche Schulart besuchst Du? <small>(optional)</small></label>
                        <div class="control">
                            <div class="select is-medium is-fullwidth">
                                <select name="schooltype" v-model="schoolType">
                                    <option value="-1">Bitte auswählen...</option>
                                    <option v-for="option in schoolTypeOptions" :value="option.value"
                                            :key="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Sektion für Studenten -->
                <div v-else-if="usergroup == 1">
                    <div class="field mb-4">
                        <label>Welchen Abschluss strebst du aktuell an?</label>
                        <div class="control">
                            <div class="select is-medium is-fullwidth">
                                <select name="study_status" v-model="studyStatus">
                                    <option value="-1">Bitte auswählen...</option>
                                    <option v-for="option in studyStatusOptions" :value="option.value"
                                            :key="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="usergroup == 4">
                    <div class="field mb-4">
                        <label>In welchem Bereich machst du gerade deine Ausbildung?</label>
                        <div class="control">
                            <div class="select is-medium is-fullwidth">
                                <select name="ausbildung_bereich" v-model="ausbildungsType">
                                    <option value="-1">Bitte auswählen...</option>
                                    <option v-for="option in ausbildungTypeOptions" :value="option.value"
                                            :key="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Sektion für Doktoranden -->
                <div v-else-if="usergroup == 2">
                    <div class="field mb-4">
                        <label>Wie lange promovierst du schon?</label>
                        <div class="control">
                            <div class="select is-medium is-fullwidth">
                                <select name="professional_experience" v-model="professionalExperience">
                                    <option value="-1">Bitte auswählen...</option>
                                    <option v-for="option in professionalExperienceOptions" :value="option.value"
                                            :key="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="field mb-4">
                        <label>Vor wie langer Zeit bist du in die Berufswelt eingestiegen?</label>
                        <div class="control">
                            <div class="select is-medium is-fullwidth">
                                <option value="-1">Bitte auswählen...</option>
                                <option v-for="option in professionalExperienceOptions" :value="option.value"
                                        :key="option.value">
                                    {{ option.text }}
                                </option>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field mb-5" v-if="usergroup != 0 && usergroup != 4">
                    <label>Welche Fachrichtung beschreibt deinen Hintergrund am besten?</label>
                    <div class="control">
                        <div class="select is-medium is-fullwidth">
                            <select name="background" v-model="background">
                                <option value="-1">Bitte auswählen...</option>
                                <option v-for="option in backgroundOptions" :value="option.value"
                                        :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="notification is-danger" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">
                        {{ error }}
                    </p>
                </div>

                <div class="field">
                    <div class="control mb-4">
                        <button class="button is-primary is-fullwidth" @click="submitPersonalData()">Fertig
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import {mapState} from 'vuex';

export default {
    name: 'PreCourse',
    components: {TopNavbar},
    computed: {
        ...mapState(['userGroups']),
        ...mapState(['ageOptions']),
        ...mapState(['genderOptions']),
        ...mapState(['schoolTypeOptions']),
        ...mapState(['studyStatusOptions']),
        ...mapState(['professionalExperienceOptions']),
        ...mapState(['backgroundOptions']),
        ...mapState(['ausbildungTypeOptions']),
    },
    mounted() {
        axios.get('/api/v1/is-third-party/')
            .then(response => {
                let thirdParty = response.data.thirdParty;
                if (thirdParty) {
                    this.isThirdParty = true;
                } else {
                    this.isThirdParty = false;
                }
            })
    },
    data() {
        return {
            stage: 0,
            isThirdParty: false,
            agbAccepted: false,
            firstnameError: false,
            ageError: false,
            genderError: false,
            background: -1,
            firstname: '',
            age: -1,
            gender: -1,
            schoolType: -1,
            professionalExperience: -1,
            studyStatus: -1,
            usergroup: -1,
            ausbildungsType: -1,
            errors: [],
            usergroupError: false,
            agbAcceptedError: false,
            email_opt_in: false,
        }
    },
    methods: {
        resetErrors() {
            this.firstnameError = false;
            this.genderError = false;
            this.ageError = false;
            this.usergroupError = false;
            this.agbAcceptedError = false;
        },
        previousStage() {
            this.stage--;
        },
        validateFirstStage() {
            let hasError = false;

            if (this.firstname.trim() === '' || this.firstname.length < 2) {
                this.firstnameError = true;
                hasError = true;
            }

            if (this.usergroup === '-1' || this.usergroup === -1 || this.usergroup === '' || this.usergroup == null) {
                this.usergroupError = true;
                hasError = true;
            }

            if (this.age == -1) {
                this.ageError = true;
                hasError = true;
            }

            if (!this.agbAccepted && this.isThirdParty) {
                this.agbAcceptedError = true;
                hasError = true;
            }

            return !hasError;
        },
        validateSecondStage() {
            let hasError = false;

            if (this.gender == -1) {
                this.genderError = true;
                hasError = true;
            }

            // Add any other second stage validations here

            return !hasError;
        },
        nextStage() {
            this.resetErrors();
            if (this.validateFirstStage()) {
                this.stage++;
            }
        },
        submitPersonalData() {
            this.resetErrors();

            if (!this.validateFirstStage() || !this.validateSecondStage()) {
                return;
            }

            const formData = {
                user_group: this.usergroup,
                firstname: this.firstname,
                age: this.age,
                background: this.background,
                school_type: this.schoolType,
                professional_experience: this.professionalExperience,
                study_status: this.studyStatus,
                gender: this.gender,
                ausbildungs_type: this.ausbildungsType,
                email_opt_in: this.email_opt_in
            }

            axios.post("/api/v1/yoloausers/", formData)
                .then(response => {
                    this.$router.push('/')
                }).catch(error => {
                    alert(error)
                })
        }
    }
}
</script>
