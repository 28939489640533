<template>
    <div id="wrapper">

        <!--<NavbarDesktop v-if="!$route.meta.hideNavbar"/>
        <div class="is-hidden-mobile" style="margin-top: 50px;">
        </div>-->

        <router-view/>

        <Navbar v-if="!$route.meta.hideNavbar"/>

        <footer class="footer">
            <p class="has-text-centered">&copy; 2024 Yoloa GmbH</p>
            <p class="has-text-centered is-size-7 mt-2"><a href="/impressum">Impressum</a> | <a
                    href="https://yoloa.de/datenschutz">Datenschutzerklärung</a>
            </p>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'App',
    beforeCreate() {
        this.$store.commit('initializeStore')
    },
}
</script>

<style lang="scss">
@import 'assets/main.scss';
</style>
<script setup>
import Navbar from "@/components/BasicUI/Navbar.vue";
import NavbarDesktop from "@/components/BasicUI/NavbarDesktop.vue";
</script>
