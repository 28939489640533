<script>
import JobCard from "@/components/JobCard.vue";
import {jobList} from "@/assets/job-list";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ScrollableBox from "@/components/BasicUI/ScrollableBox.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    name: 'JobOverview',
    computed: {
        filteredJobs() {
            return jobList
        },
    },
    methods: {
        getFilterLabel(filter) {
            const filterLabels = {
                'balance': 'Gute Work-Life-Balance',
                'income': 'Überdurchschn. Einkommen',
                'stability': 'Gute Zukunftssicherheit',
                'independence': 'Viel Selbständigkeit',
                'internationality': 'Viel Internationalität',
                'data': 'Datenbasiert',
                'creativity': 'Kreativer Job',
                'craftsmanship': 'Handwerklich',
                'people': 'Viel mit Menschen',
                'university': 'Studium benötigt'
            };
            return filterLabels[filter] || filter;
        },
        cleanedNumber(string) {
            return parseInt(String(string).replace('*', ''))
        },
        updateJobWishlist() {
            // Get the ids of all jobs from the job list that are selected
            const selectedJobIds = this.jobList.filter((job) => job.selected).map((job) => job.id)
            const data = {
                selected_jobs: selectedJobIds
            }

            axios.post('/api/v1/workquizresults/', data).then((response) => {
            })
        },
        selectJob(id) {
            this.jobList.filter((job) => job.id === id)[0].selected = true

            this.updateJobWishlist()
            this.showWishlistAdd = true;
            setTimeout(() => {
                this.showWishlistAdd = false;
            }, 2000); // Hide after 3 seconds
        },
        unselectJob(id) {
            this.jobList.filter((job) => job.id === id)[0].selected = false
            this.showWishlistRemove = true;

            this.updateJobWishlist()
            setTimeout(() => {
                this.showWishlistRemove = false;
            }, 2000); // Hide after 3 seconds
        },
        nextStage() {
            this.currentStage++;
        },
        addFilter(filter) {
            if (!this.filters.includes(filter)) {
                this.filters.push(filter);
            }
        },
        removeFilter(filter) {
            const index = this.filters.indexOf(filter);
            if (index > -1) {
                this.filters.splice(index, 1);
            }
        },
    },
    components: {DescriptionCard, ScrollableBox, FontAwesomeIcon, ExerciseIntro, TopNavbar, JobCard},
    data() {
        return {
            jobList: [],
            showWishlistRemove: false,
            showWishlistAdd: false,
            visionBoardProgress: 0,
            coreValuesProgress: 0,
            lifeCockpitProgress: 0,
            futureSkillsProgress: 0,
            currentStage: 0,
            filters: [],

        }
    },
}
</script>

<template>
    <TopNavbar title="Bibliothek der Berufsfelder" back-link="/futurework"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="currentStage === 0">
                <ExerciseIntro module-name="library" @intro-finished="nextStage"/>
                <button class="button is-primary mt-4 is-fullwidth" @click="nextStage">Los geht's</button>
            </div>
            <div v-else>
                <div class="field is-grouped is-grouped-multiline is-justify-content-center">
                    <div class="control" v-for="filter in filters" :key="filter">
                        <div class="tags has-addons">
                            <span class="tag is-info">{{ getFilterLabel(filter) }}</span>
                            <a class="tag is-delete" @click="removeFilter(filter)"></a>
                        </div>
                    </div>
                    <!--<div class="control">
                        <div class="dropdown is-hoverable">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                                    <span>Filter hinzufügen</span>
                                    <span class="icon is-small">
                                    <font-awesome-icon :icon="['fas', 'caret-down']"
                                                       aria-hidden="true"></font-awesome-icon>
                                </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a @click="addFilter('balance')" class="dropdown-item">Gute Work-Life-Balance</a>
                                    <a @click="addFilter('income')" class="dropdown-item">Überdurchschn. Einkommen</a>
                                    <a @click="addFilter('stability')" class="dropdown-item">Gute Zukunftssicherheit</a>
                                    <a @click="addFilter('independence')" class="dropdown-item">Viel Selbständigkeit</a>
                                    <a @click="addFilter('internationality')" class="dropdown-item">Viel
                                        Internationalität</a>
                                    <a @click="addFilter('data')" class="dropdown-item">Datenbasiert</a>
                                    <a @click="addFilter('creativity')" class="dropdown-item">Kreativer Job</a>
                                    <a @click="addFilter('craftsmanship')" class="dropdown-item">Handwerklich</a>
                                    <a @click="addFilter('people')" class="dropdown-item">Viel mit Menschen</a>
                                    <hr class="dropdown-divider">
                                    <a @click="addFilter('university')" class="dropdown-item">Studium benötigt</a>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
                <ScrollableBox>
                    <div v-if="filteredJobs.length === 0">
                        <DescriptionCard>
                            <p>Wir haben leider keinen Job gefunden, der all deine Filterkriterien erfüllt. Versuche, einen oder mehrere Filter zu entfernen.</p>
                        </DescriptionCard>
                    </div>
                    <div class="mb-3" v-for="job in filteredJobs" :key="job.id">
                        <div class="notification is-primary is-light" v-if="showWishlistRemove">
                            <button class="delete" @click="showWishlistRemove = false"></button>
                            Von der Wunschliste entfernt
                        </div>
                        <div class="notification is-primary is-light" v-if="showWishlistAdd">
                            <button class="delete" @click="showWishlistAdd = false"></button>
                            Zur Wunschliste hinzugefügt
                        </div>

                        <JobCard
                                :name="job.name"
                                :description="job.description"
                                :subgroups="job.subgroups"
                                :image="job.image"
                                :balance="job.balance"
                                :income="job.income"
                                :stability="job.stability"
                                :independence="job.independence"
                                :data="job.data"
                                :creativity="job.creativity"
                                :craftsmanship="job.craftsmanship"
                                :people="job.people"
                                :university="job.university"
                                :internationality="job.internationality"
                                :id="job.id"
                                :selected="job.selected"
                                @job-selected="selectJob($event)"
                                @job-unselected="unselectJob($event)"
                        />
                    </div>
                </ScrollableBox>
            </div>
        </div>
    </div>
</template>

<style scoped>
.notification {

    position: fixed;
    width: 300px;
    height: 65px;
    top: 50%;
    left: 50%;
    margin-top: -32px; /* Negative half of height. */
    margin-left: -150px; /* Negative half of width. */
}

.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}


.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
</style>
