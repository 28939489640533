<script>
export default {
    name: "TopNavbar",
    props: {
        title: '',
        backLink: {
            default: '',
            type: String,
        },
    },
    methods: {
        goBack() {
            this.$router.push(this.backLink)
        }
    }
}
</script>

<template>
    <div>
        <nav class="navbar is-fixed-top navbar-is-centered">
            <div class="navbar-content">
                <div class="title is-4">{{ title }}</div>
            </div>
            <div class="back-button" v-if="backLink" @click="goBack">
                <font-awesome-icon :icon="['fas', 'arrow-left']" size="xl"></font-awesome-icon>
            </div>
        </nav>
    </div>
</template>

<style scoped lang="scss">
.navbar-is-centered {

}

.navbar {
  background-color: #F9F3F9;
  position: relative;
}

.back-button {
  position: absolute;
  left: 20px;
  top: 26px;
  cursor: pointer;
}

.navbar-content {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
