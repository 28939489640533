<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "NavbarDesktop",
    components: {FontAwesomeIcon},
    data() {
        return {
            showNavbar: true,
            lastScrollPosition: 0,
        }
    },
    computed: {
        homeClass() {
            return this.$store.state.is_home_active ? 'icon-active' : ''
        },
        userClass() {
            return this.$store.state.is_profile_active ? 'icon-active' : ''
        },
        exploreClass() {
            return this.$store.state.is_explore_active ? 'icon-active' : ''
        },
    },
    methods: {
        directHome() {
            this.$store.commit('setHomeActive')
            this.$router.push(this.$store.state.home_destination)
        },
        directProfile() {
            this.$store.commit('setProfileActive')
            this.$router.push('/profile')
        },
        directExplore() {
            this.$store.commit('setExploreActive')
            this.$router.push('/explore')
        }
    }
}
</script>

<template>
    <nav class="navbar is-fixed-top custom-navbar is-hidden-touch" role="navigation" aria-label="main navigation">
        <div class="navbar-menu is-active">
            <div class="navbar-center">
                <a class="navbar-item" @click="directHome">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'home']" size="xl"/>
                    </span>
                    <span>&nbsp; Home</span>
                  </span>
                </a>
                <a class="navbar-item" @click="directExplore">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'compass']" size="xl"/>
                    </span>
                    <span>&nbsp; Inspirationen</span>
                  </span>
                </a>
                <a class="navbar-item" @click="directProfile">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'user']" size="xl"/>
                    </span>
                    <span>&nbsp;Mein Profil</span>
                  </span>
                </a>
            </div>
        </div>
    </nav>

</template>

<style scoped lang="scss">

.navbar-center {
display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-menu {
  justify-content: center; /* Center the items in the navbar */
  min-height: 70px;
}

.custom-navbar {
  border-bottom: solid rgb(230, 230, 230) 1px;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  box-shadow: 0 2px 10px rgba(120, 120, 120, 0.2);
}

.custom-navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, 100%, 0);
}

.nav-icon {
  color: rgb(210, 210, 210);
  cursor: pointer;
}

.icon-active {
  color: rgb(30, 30, 30)
}

.touchbar-content {
  padding-top: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
