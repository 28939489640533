<template>
  <div>
    <div class="card" v-if="currentQuestion < questions.length">
      <header class="card-header">
        <p class="card-header-title">
          Frage {{ currentQuestion + 1 }} von {{ questions.length }}
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <h3>{{ questions[currentQuestion].text }}</h3>
          <p>
            {{ isSingleChoice ? '(Wähle genau eine Antwort.)' : '(Mehrere Antworten sind richtig.)' }}</p>
          <div class="buttons">
            <button v-for="(answer, index) in questions[currentQuestion].answers"
                    :key="index"
                    class="button is-fullwidth quiz-button"
                    :class="{
              'is-primary': selections.includes(index) && !showExplanation,
              'is-success': showExplanation && answer.correct,
              'is-danger': showExplanation && selections.includes(index) && !answer.correct
            }"
                    @click="toggleSelection(index)">
              {{ answer.text }}
            </button>
          </div>
          <div v-if="showExplanation" class="explanation">
            <p>{{ questions[currentQuestion].explanation }}</p>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <a class="card-footer-item" @click="handleButtonClick">
          {{ showExplanation ? 'Nächste Frage' : 'Antwort bestätigen' }}
        </a>
      </footer>
    </div>

    <div v-else>
      <p v-if="percentageCorrect >= 70">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Quiz bestanden
            </p>
          </header>

          <div class="card-content">
            <div class="content">
              <h3>Herzlichen Glückwunsch! Du hast das Quiz bestanden.</h3>
              <p>
                Du hast {{ percentageCorrect }}% erreicht.
              </p>
              <button @click="goToNextModule" class="button is-primary is-fullwidth">Modul abschließen</button>
            </div>
          </div>
        </div>
      </p>
      <p v-else>
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Quiz nicht bestanden
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <h3>Das hat leider nicht gereicht!</h3>
              <p>
                Du hast {{ percentageCorrect }}% erreicht. Du benötigst mindestens 70%, um zu bestehen.
              </p>
              <button @click="restartVideo" class="button is-primary is-fullwidth">Zurück zum Video</button>
              <button @click="restartQuiz" class="button is-primary mt-4 is-fullwidth">Quiz wiederholen</button>
            </div>
          </div>
        </div>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questions: Array,
  },
  data() {
    return {
      currentQuestion: 0,
      selections: [],
      correctAnswers: 0,
      showExplanation: false,
    };
  },
  computed: {
    isSingleChoice() {
      const currentAnswers = this.questions[this.currentQuestion].answers;
      const correctAnswersCount = currentAnswers.reduce((acc, answer) => acc + (answer.correct ? 1 : 0), 0);
      return correctAnswersCount === 1;
    },
    percentageCorrect() {
      return Math.floor((this.correctAnswers / this.questions.length) * 100);
    },
  },
  methods: {
    toggleSelection(index) {
      // Prevent changing selections after submitting an answer
      if (this.showExplanation) return;

      if (this.isSingleChoice) {
        this.selections = [index];
      } else {
        const selectionIndex = this.selections.indexOf(index);
        if (selectionIndex >= 0) {
          this.selections.splice(selectionIndex, 1);
        } else {
          this.selections.push(index);
        }
      }
    },
    restartVideo() {
      this.restartQuiz()
        this.$emit('video-restart')
    },
    restartQuiz() {
      // Reset the quiz to its initial state
      this.currentQuestion = 0;
      this.selections = [];
      this.correctAnswers = 0;
      this.showExplanation = false;
    },
    submitAnswer() {
      const currentQuestion = this.questions[this.currentQuestion];
      const correctSelections = currentQuestion.answers
          .map((answer, index) => answer.correct ? index : null)
          .filter(index => index !== null);

      if (this.selections.sort().toString() === correctSelections.sort().toString()) {
        this.correctAnswers += 1;
      }
      this.showExplanation = true;
    },
    nextQuestion() {
      if (this.currentQuestion < this.questions.length - 1) {
        this.currentQuestion += 1;
        this.selections = [];
      } else {
        this.currentQuestion += 1; // Moves past the last question to show the completion message.
      }
      this.showExplanation = false;
    },
    handleButtonClick() {
      if (this.showExplanation) {
        this.nextQuestion();
      } else {
        this.submitAnswer();
      }
    },
    goToNextModule() {
      // Emit an event to notify the parent component that the quiz has been completed
      this.$emit('quiz-success');
    },
  }
};
</script>

<style scoped>
/* Style for your explanation or other dynamic elements */
.explanation {
  margin-top: 20px;
  font-style: italic;
}

.quiz-button {
  text-align: left;
  white-space: normal; /* Allows text to wrap */
  overflow: visible; /* Ensures text is not cut off */
  word-break: break-word; /* Ensures words break properly to prevent horizontal scrolling */
  display: block; /* Block display to utilize full width */
  padding: 15px 20px; /* Increased padding for better text spacing */
  margin: 5px 0; /* Adds margin between buttons for better separation */
  box-sizing: border-box; /* Ensures padding and border are included in the total width and height */
  min-height: 90px;
}

.card-content {
  padding: 20px; /* Adds more padding around the content */
}


</style>
