<script>
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";
import JobSlider from "@/components/JobSlider.vue";
import VueApexCharts from "vue3-apexcharts";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    components: {FontAwesomeIcon, JobSlider, ProgressBar, VueApexCharts},
    data() {
        return {}
    },
    props: {
        name,
        image: String,
        subgroups: String,
        balance: String,
        income: String,
        stability: String,
        independence: String,
        data: String,
        creativity: String,
        craftsmanship: String,
        people: String,
        university: Boolean,
        internationality: String,
        id: String,
        isExpanded: {
            default: false,
            type: Boolean
        },
        isEvaluationMode: {
            default: false,
            type: Boolean,
        },
        isSwipeMode: {
            default: false,
            type: Boolean,
        },
        selected: {
            default: false,
            type: Boolean,
        },
        description: {
            default: '',
            type: String
        }
    },
    methods: {
        removeAsterisk(string) {
            return String(string).replace('*', '')
        },
        showAsterisk(field) {
            return String(field).includes('*') ? '*' : ''
        },
    },
    computed: {
        cardBackgroundImage() {
            return {
                'background-image': `linear-gradient(360deg, rgba(0, 0, 0, 0.7161458333333333) 10%, rgba(255, 255, 255, 0) 100%), url(/workimages/${this.image}.webp)`,
                'min-height': (this.isSwipeMode ? 250 : 350) + 'px',
            }
        },
        balanceNumber() {
            return parseInt(this.removeAsterisk(this.balance))
        },
        incomeNumber() {
            return parseInt(this.removeAsterisk(this.income))
        },
        stabilityNumber() {
            return parseInt(this.removeAsterisk(this.stability))
        },
        independenceNumber() {
            return parseInt(this.removeAsterisk(this.independence))
        },
        dataNumber() {
            return parseInt(this.removeAsterisk(this.data))
        },
        creativityNumber() {
            return parseInt(this.removeAsterisk(this.creativity))
        },
        craftsmanshipNumber() {
            return parseInt(this.removeAsterisk(this.craftsmanship))
        },
        peopleNumber() {
            return parseInt(this.removeAsterisk(this.people))
        },
        internationalityNumber() {
            return parseInt(this.removeAsterisk(this.internationality))
        },
        series() {
            return [
                {
                    name: 'Arbeit mit...',
                    data: [this.dataNumber,
                        this.creativityNumber,
                        this.peopleNumber,
                        this.craftsmanshipNumber]
                }
            ]
        },
        chartOptions() {
            return {
                chart: {
                    id: 'vuechart-example',
                    toolbar: {
                        show: false  // This will remove the download option
                    }
                },
                colors: ['#007EFF'],
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'  // Disable the hover effect on data series
                        }
                    }
                },
                xaxis: {
                    categories: ['Zahlen & Daten' + this.showAsterisk(this.data), 'Kreativität' + this.showAsterisk(this.creativity), 'Menschen' + this.showAsterisk(this.people), 'Handwerk' + this.showAsterisk(this.craftsmanship)],
                    style: {
                        fontSize: '16px'  // Adjust this value as desired
                    }
                },
                yaxis: {
                    max: 5,
                    forceNiceScale: false,  // Ensure ApexCharts doesn't adjust the tick values on its own.
                    tickValues: [0, 1, 2, 3, 4, 5],  // Explicitly specify tick values.
                    labels: {
                        formatter: (val) => Math.floor(val),
                        style: {
                            fontSize: '16px'  // Adjust this value as desired
                        }
                    }
                },
                responsive: [
                    {
                        breakpoint: 620,  // below 480px width
                        options: {
                            yaxis: {
                                max: 5,
                                labels: {
                                    formatter: (val) => Math.floor(val),
                                    style: {
                                        fontSize: '12px'  // Adjust this value as desired
                                    }
                                }
                            }
                        }
                    }
                ],
                tooltip: {
                    enabled: false
                },
                grid: {
                    show: false  // This will remove all background grid lines
                }
            }
        },

    },
    emits: ['job-selected', 'job-unselected']
}
</script>

<template>
    <div class="card mb-5">
        <div class="card-image" :style="cardBackgroundImage" v-if="!isEvaluationMode">
            <div class="university-label" v-if="university && !isSwipeMode">
                <span class="tag is-primary">Studium benötigt</span>
            </div>
            <div v-if="isSwipeMode">
                <div class="unlove-button">
                    <button class="button is-primary very-big-button" @click="this.$emit('job-unselected', id)">
                        <font-awesome-icon :icon="['fas', 'times']" size="2x"></font-awesome-icon>
                    </button>
                </div>
                <div class="love-button">
                    <button class="button is-info very-big-button" @click="this.$emit('job-selected', id)">
                        <font-awesome-icon :icon="['fas', 'heart']" size="2x"></font-awesome-icon>
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="add-button" v-if="selected">
                    <button class="button is-light min-width-button" @click="this.$emit('job-unselected', id)">
                        <font-awesome-icon :icon="['fas', 'fa-minus']"></font-awesome-icon>
                    </button>
                </div>
                <div class="add-button" v-else>
                    <button class="button is-light min-width-button" @click="this.$emit('job-selected', id)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'fa-plus']"></font-awesome-icon>
                    </span>
                    </button>
                </div>
            </div>
            <div class="card-title">
                <h2 class="title is-5">{{ name }}
                </h2>
                <h3 class="subtitle is-size-7">{{ subgroups }}</h3>
            </div>
        </div>
        <div class="card-content">
            <div class="content">
                <p class="is-size-7">{{ description }}</p>
                <!--<JobSlider :value="balanceNumber" :title="'Work-Life-Balance' + showAsterisk(balance)" type="info"/>
                <JobSlider :value="incomeNumber" title="Einkommen" type="primary"/>
                <JobSlider :value="stabilityNumber" title="Zukunftssicherheit" type="purple" v-if="!isEvaluationMode"/>
                <JobSlider :value="independenceNumber" title="Selbstständigkeit" type="rose"/>
                <JobSlider :value="internationalityNumber" title="Internationalität" type="baby-blue"/>-->
                <div v-if="!isEvaluationMode">
                    <div v-if="!isExpanded">
                        <button class="button is-light is-fullwidth mt-5" @click="isExpanded = true">Mehr anzeigen
                        </button>
                    </div>
                    <div v-else>
                        <button class="button is-light is-fullwidth mt-5" @click="isExpanded = false">Weniger anzeigen
                        </button>
                    </div>
                </div>
                <div v-if="isExpanded || isEvaluationMode">
                    <div class="mt-5 has-text-centered">
                        <label class="title is-5">Arbeit mit...</label>
                    </div>
                    <VueApexCharts type="bar" :options="chartOptions" :series="series"></VueApexCharts>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.card {
  max-width: 500px;
  margin: 0 auto 0 auto;
}

.card-image {
  display: flex;
  flex-direction: row;
  align-content: end;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-image .card-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card-image .university-label {
  position: absolute;
  top: 20px;
  left: 20px;
}

.card-image .unlove-button {
  position: absolute;
  top: 20px;
  left: 20px;
}

.card-image .love-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.card-image .add-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.min-width-button {
  min-width: 50px;
}

.very-big-button {
  min-width: 80px;
  min-height: 80px;
}

.card-image div h2, .card-image div h3 {
  color: #fff;
}
</style>
