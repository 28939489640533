<template>
    <TopNavbar title="Inspirationen"/>
    <div class="app-container">
        <div class="app-content-container">
            <DescriptionCard title="Yoloa-Podcast">
                <p>
                    Kennst du schon unseren Yoloa-Podcast? Hier findest du spannende Interviews mit Menschen, die ihren
                    ganz eigenen Karriereweg gegangen sind. Lass dich inspirieren!
                </p>
                <div class="buttons mt-4">
                    <a class="button is-info" href="https://open.spotify.com/show/6gxCd7pEyrcCZZXEEhKVob?nd=1" target="_blank">
                        <font-awesome-icon :icon="['fab', 'spotify']" size="xl" />
                        &nbsp;&nbsp;Bei Spotify anhören
                    </a>
                    <a class="button is-info"
                       href="https://podcasts.apple.com/de/podcast/life-design-in-the-digital-age/id1491912920" target="_blank">
                        <font-awesome-icon :icon="['fa', 'podcast']" size="xl"/>
                        &nbsp;&nbsp;Bei Apple Podcasts anhören</a>
                </div>
            </DescriptionCard>
        </div>
    </div>
</template>

<script>
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'Explore',
    components: {FontAwesomeIcon, DescriptionCard, TopNavbar},
}
</script>
