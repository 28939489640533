<script>
export default {
    name: 'ImageCard',
    props: {
        url: String,
        id: Number,
        isHighlightable: Boolean,
        maxReached: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            isSelected: false
        }
    },
    computed: {
        backgroundCssProps() {
            return {
                'background-image': 'url(' + this.url + ')',
                'filter': this.isSelected ? 'brightness(70%)' : 'brightness(100%)',
            }
        }
    },
    methods: {
        selectImage() {
            if (this.isHighlightable) {
                if (this.isSelected) {
                    this.$emit('image-unselected')
                    this.isSelected = false
                } else {
                    if (!this.maxReached) {
                        this.$emit('image-selected')
                        this.isSelected = true
                    }
                }
            } else {
                this.$emit('image-selected')
            }
        },
    }
}
</script>

<template>
    <div class="card" @click="selectImage">
        <div class="card-content vision-image">
            <div :style="backgroundCssProps" class="background-image">
            </div>
            <img v-if="isSelected" class="checkmark" src="../assets/images/checkmark.svg" width="24"/>
        </div>
    </div>
</template>

<style scoped lang="scss">

.vision-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 0;
  padding-bottom: 85%;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.background-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.checkmark {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}
</style>
