<script>
export default {
    name: 'RatingButtonArray',
    props: {
        category: String
    },
    methods: {
        rate(number) {
            this.$emit('rated', {rating: number, category: this.category})
        }
    },
    emits: ['rated']
}
</script>

<template>
    <div class="buttons">
        <button class="button is-fullwidth" @click="rate(4)">Stimme voll und ganz zu</button>
        <button class="button is-fullwidth" @click="rate(3)">Stimme zu</button>
        <button class="button is-fullwidth" @click="rate(2)">Neutral</button>
        <button class="button is-fullwidth" @click="rate(1)">Stimme nicht zu</button>
        <button class="button is-fullwidth" @click="rate(0)">Stimme überhaupt nicht zu</button>
    </div>
</template>

<style scoped lang="scss">

</style>
