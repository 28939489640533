<script>
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import Session from "supertokens-web-js/recipe/session";
import {defineComponent} from "vue";
import EmailVerification from "supertokens-web-js/recipe/emailverification";

const websiteDomain = `https://app.yoloa.de`;
const apiDomain = `https://api.yoloa.de`;
import getAuthTranslation from "@/assets/translations";
import {getAuthorisationURLWithQueryParamsAndSetState} from "supertokens-web-js/lib/build/recipe/thirdparty";
import GoogleButton from "@/components/BasicUI/GoogleButton.vue";
import AppleButton from "@/components/BasicUI/AppleButton.vue";

export default defineComponent({
    components: {GoogleButton, AppleButton},
    data() {
        return {
            isStartView: true,

            acceptPrivacy: false,
            acceptMarketing: false,
            // we allow the user to switch between sign in and sign up view
            isSignIn: true,

            // this will store the email and password entered by the user.
            email: "",
            password: "",

            // any generic error states
            error: false,
            errorMessage: "Ein Fehler ist aufgetreten",

            // any error states specific to the input fields.
            emailError: "",
            passwordError: "",
            privacyError: "",
        };
    },

    mounted() {
        // if there is an "error" query param on this page, it means that
        // social login has failed for some reason. See the AuthCallbackView.vue file
        // for more context on this
        const params = new URLSearchParams(window.location.search);

        if (params.has("error")) {
            this.errorMessage = "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.";
            this.error = true;
        }

        // this redirects the user to the HomeView.vue component if a session
        // already exists.
        this.checkForSession();
    },

    methods: {
        goToSignUp() {
            this.deleteErrors();
            this.isSignIn = false;
            this.isStartView = false;
        },
        goToForgotPassword() {
            this.$router.push('/auth/reset-password')
        },
        goToSignIn() {
            this.isSignIn = true;
            this.isStartView = false;
        },
        signIn: async function (_) {
            const response = await ThirdPartyEmailPassword.emailPasswordSignIn({
                formFields: [
                    {
                        id: "email",
                        value: this.email,
                    },
                    {
                        id: "password",
                        value: this.password,
                    },
                ],
            });

            if (response.status === "WRONG_CREDENTIALS_ERROR") {
                // the input email / password combination did not match,
                // so we show an appropriate error message to the user
                this.errorMessage = "Die eingegebenen Nutzerdaten sind ungültig.";
                this.error = true;
                return;
            }

            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach((item) => {
                    if (item.id === "email") {
                        // this means that something was wrong with the entered email.
                        // probably that it's not a valid email (from a syntax point of view)
                        this.emailError = getAuthTranslation(item.error);
                    } else if (item.id === "password") {
                        this.passwordError = getAuthTranslation(item.error);
                    }
                });
                return;
            }

            // login is successful, and we redirect the user to the home page.
            // Note that session cookies are added automatically and nothing needs to be
            // done here about them.
            window.location.assign("/");
        },
        validateEmail(email) {
            return email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        signUp: async function (_) {
            if (this.acceptPrivacy === false) {
                this.privacyError = "Bitte bestätige die Datenschutzerklärung.*"
                return;
            }

            const response = await ThirdPartyEmailPassword.emailPasswordSignUp({
                formFields: [
                    {
                        id: "email",
                        value: this.email,
                    },
                    {
                        id: "password",
                        value: this.password,
                    },
                ],
            });

            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach((item) => {
                    if (item.id === "email") {
                        // this means that something was wrong with the entered email.
                        // probably that it's not a valid email (from a syntax point of view)
                        this.emailError = getAuthTranslation(item.error);
                    } else if (item.id === "password") {
                        // this means that something was wrong with the entered password.
                        // probably it doesn't meet the password validation criteria on the backend.
                        this.passwordError = getAuthTranslation(item.error);
                    }
                });
                return;
            }

            //redirect to email verification
            await EmailVerification.sendVerificationEmail().then()
            {
                this.$router.push('/auth/verify-email')
            }

        },
        deleteErrors() {
            this.error = false;
        },
        onSubmitPressed: function (e) {
            e.preventDefault();
            // we reset the error states in case the user has fixed the input errors
            this.error = false;
            this.emailError = "";
            this.passwordError = "";
            this.privacyError = "";
            this.usergroupError = "";

            if (this.isSignIn) {
                this.signIn(e);
            } else {
                this.signUp(e);
            }
        },
        onGooglePressed: async function () {
            const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "google",

                // This is where google should redirect the user back after login or error.
                // This URL goes on the google dashboard as well.
                frontendRedirectURI: `${websiteDomain}/auth/callback/google`,
            });

            window.location.assign(authUrl);
        },
        onApplePressed: async function () {
            try {
                const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
                    thirdPartyId: "apple",
                    frontendRedirectURI: "https://app.yoloa.de/auth/callback/apple", // This is an example callback URL on your frontend. You can use another path as well.
                    //frontendRedirectURI: "https://app.yoloa.de/auth/callback/apple", // This is an example callback URL on your frontend. You can use another path as well.
                    redirectURIOnProviderDashboard: "https://api.yoloa.de/auth/callback/apple", // This URL goes on the Apple's dashboard
                    //redirectURIOnProviderDashboard: "http://localhost:8000/auth/callback/apple", // This URL goes on the Apple's dashboard
                });

                // we redirect the user to apple for auth.
                window.location.assign(authUrl);
            } catch (err) {
                if (err.isSuperTokensGeneralError === true) {
                    // this may be a custom error message sent from the API by you.
                    window.alert(err.message);
                } else {
                    console.error(err)
                    window.alert("Entschuldige, ein Fehler ist aufgetreten. Bitte versuche es mit einer anderen Anmeldemethode.");
                }
            }

        },
        checkForSession: async function () {
            if (await Session.doesSessionExist()) {
                // since a session already exists, we redirect the user to the HomeView.vue component
                window.location.assign("/");
            }
        },
    },
});
</script>

<template>
    <div class="app-container-small">
        <div class="main-background">
            <div class="app-content-container-no-nav">
                <div class="is-text-centered">
                    <img src="../assets/images/yoloa-logo.svg" class="logo mb-2"/>
                </div>

                <div v-if="isStartView">
                    <div class="title is-text-centered">Willkommen bei Yoloa</div>
                    <p class="subtitle is-text-centered mt-2">Melde dich an, um fortzufahren.</p>

                    <GoogleButton @click="onGooglePressed"/>
                    <AppleButton @click="onApplePressed"/>

                    <div class="field mt-4">
                        <div class="control">
                            <button class="button is-primary is-fullwidth" @click="goToSignIn">
                                Mit E-Mail anmelden
                            </button>
                        </div>
                    </div>

                    <div class="field mt-4">
                        <div class="control">
                            <button class="button is-primary is-outlined is-fullwidth" @click="goToSignUp">
                                Account erstellen
                            </button>
                        </div>
                    </div>

                </div>
                <div v-else>

                    <div class="title is-text-centered" v-if="isSignIn">Einloggen</div>
                    <div class="title is-text-centered" v-else>Registrieren</div>
                    <p class="subtitle is-text-centered" v-if="!isSignIn">Werde Teil der Yoloa-Community!</p>

                    <div v-if="error" class="notification is-danger">
                        <button class="delete" @click="deleteErrors"></button>
                        {{ errorMessage }}
                    </div>

                    <form v-on:submit="onSubmitPressed" autocomplete="on" novalidate>
                        <div class="field" v-bind:class="emailError ? 'error' : ''">
                            <label class="label">Email</label>
                            <div class="control" v-bind:class="emailError ? 'error' : ''">
                                <input
                                        autocomplete="email"
                                        class="input is-fullwidth"
                                        type="email"
                                        name="email"
                                        placeholder="Deine Email-Adresse"
                                        v-model="email"
                                />
                            </div>
                            <p v-if="emailError" class="help is-danger">{{ `${emailError}` }}</p>
                        </div>

                        <div class="field">
                            <label class="label">Passwort</label>
                            <div class="control">
                                <input
                                        :class="passwordError ? 'is-danger' : ''"
                                        autocomplete="current-password"
                                        class="input is-fullwidth"
                                        type="password"
                                        name="password"
                                        placeholder="Dein Passwort"
                                        v-model="password"
                                />
                            </div>
                            <p v-if="passwordError" class="help is-danger">{{ `${passwordError}` }}</p>
                        </div>
                        <div v-if="isSignIn" class="clickable-text forgot-password" @click="goToForgotPassword">
                            Passwort vergessen?
                        </div>

                        <div v-if="!isSignIn" class="mb-5">
                            <div class="field">
                                <label class="checkbox" :class="privacyError? 'is-danger' : ''">
                                    <input type="checkbox" v-model="acceptPrivacy">
                                    Ich akzeptiere die <a target="_blank" href="/datenschutz">Datenschutzerklärung</a>.
                                </label>
                                <p v-if="privacyError" class="help is-danger">{{ privacyError }}</p>
                            </div>
                            <!--<div class="field">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="acceptMarketing">
                                    Ich bin damit einverstanden, E-Mails mit Neuigkeiten
                                    und Tipps von Yoloa zu erhalten. Ich kann mich jederzeit von den Emails abmelden.
                                </label>
                            </div>-->
                        </div>

                        <div class="field">
                            <div v-if="isSignIn">
                                <div class="control">
                                    <button type="submit" class="button is-primary is-fullwidth">Einloggen</button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="control">
                                    <button type="submit" class="button is-primary is-fullwidth">Registrieren</button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <GoogleButton @click="onGooglePressed"/>
                    <AppleButton @click="onApplePressed"/>

                    <div class="sign-in-up-container mt-5">
                    <span v-if="isSignIn">Du noch keinen Account?
                            <span class="clickable-text" v-on:click="goToSignUp">Account erstellen</span>
                        </span>
                        <span v-if="!isSignIn">Du hast schon einen Account?
                            <span class="clickable-text" v-on:click="goToSignIn">Einloggen</span>
                        </span>
                    </div>
                </div>

                <!--<div class="title is-text-centered" v-if="isSignIn">Einloggen</div>-->
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
