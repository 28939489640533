<script>
import axios from "axios";
import {Stories} from "vue-insta-stories";
import {
    compareRanks,
    get_base_value_file_from_id,
    get_base_value_name_from_id,
    getValueNameFromId
} from "@/assets/value-list";
import ValueSlider from "@/components/ValueSlider.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import HoveringSection from "@/components/BasicUI/HoveringSection.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    components: {DescriptionCard, ExerciseIntro, Modal, HoveringSection, TopNavbar, ValueSlider, Stories},
    name: 'LifeCockpitExercise',
    beforeCreate() {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    //Transfer user to page to fill in user data
                    this.$router.push('/dashboard/precourse')
                }
            })
    },
    mounted() {
        axios.get('/api/v1/valueratings/').then((response) => {
            let all_values = response.data.splice(-10);
            if (all_values.length > 0) {
                this.stage = 3
                this.revisited = true
                this.base_values = []
                all_values.forEach((value) => {
                    if (value.value_id <= -10) { // this marks a base value
                        this.base_values.push(value)
                    } else {
                        this.core_values.push(value)
                    }
                })
            } else {
                axios.get('/api/v1/corevalues/').then((response) => {
                    let core_values = response.data.sort(compareRanks).splice(0, 6);
                    core_values = core_values.map((value) => ({...value, fulfillment: 0}))
                    this.core_values = core_values
                })
            }
        })
    },
    data() {
        return {
            core_values: [],
            base_values: [
                {value_id: -10, fulfillment: 0},
                {value_id: -11, fulfillment: 0},
                {value_id: -12, fulfillment: 0},
                {value_id: -13, fulfillment: 0}
            ],
            stage: 0,
            revisited: false,
            min: -2,
            max: 2,
            step: 1,
            show_modal: false,
        }
    },
    methods: {
        get_base_value_file_from_id,
        getValueNameFromId,
        get_base_value_name_from_id,
        nextStage() {
            if (this.stage === 2) {
                this.submitFulfillment()
            }
            this.stage++;
        },
        submitFulfillment() {
            const all_values = this.base_values.concat(this.core_values)

            all_values.forEach((value) => {
                const data = {
                    value_id: value.value_id,
                    fulfillment: value.fulfillment
                }
                axios.post("/api/v1/valueratings/", data).then(result => {
                })
            });

        },
        restartExercise() {
            this.stage = 0
            this.selected_images = []
            this.favourite_images = []

            this.base_values.forEach((value) => value.fulfillment = 0)
            this.core_values.forEach((value) => value.fulfillment = 0)

            this.image = 0
            this.show_modal = false
        }
    },
    computed: {
        buttonClass() {
            return this.has_selected_all_favourites ? 'is-primary' : 'hidden';
        }
    }
}
</script>

<template>
    <TopNavbar title="Mein Life Cockpit"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage === 0">
                <ExerciseIntro module-name="cockpit" @intro-finished="nextStage"/>
                <button class="button is-primary is-fullwidth" @click="nextStage">Übung starten</button>
            </div>
            <div v-else-if="stage == 1">
                <DescriptionCard title="Die vier Lebensbereiche">
                    <p>Gib mit dem Regler jeweils an, wie zufrieden du aktuell mit diesem Lebensbereich bist.<br/><br/>
                    </p>
                    <p><b>Ganz links:</b> Der Bereich bereitet dir große Sorgen und du bist unzufrieden.<br/>
                        <b>Ganz rechts</b>: In diesem Bereich fühlst du dich völlig erfüllt.</p>
                </DescriptionCard>
                <div class="columns">
                    <div class="column">
                        <div v-for="(value, index) in base_values">
                            <ValueSlider
                                    :title="get_base_value_name_from_id(base_values[index].value_id)"
                                    :image="get_base_value_file_from_id(base_values[index].value_id)"
                                    v-model="base_values[index].fulfillment"
                                    :min="min"
                                    :max="max"
                                    :step="step"
                            />
                        </div>
                    </div>
                </div>
                <HoveringSection>
                    <button class="button is-fullwidth is-primary" @click="nextStage">Weiter</button>
                </HoveringSection>
            </div>
            <div v-else-if="stage == 2">
                <DescriptionCard title="Deine Core Values">
                    <p>Gib mit dem Regler jeweils an, wie gut deine Core Values aktuell in deinem Alltag erfüllt
                        sind.<br/><br/></p>
                    <p><b>Ganz links:</b> gar nicht erfüllt<br/>
                        <b>Ganz rechts</b>: voll und ganz erfüllt</p>
                </DescriptionCard>
                <div class="columns">
                    <div class="column">
                        <div v-for="(value, index) in core_values">
                            <ValueSlider
                                    :title="getValueNameFromId(value.value_id)"
                                    :image="value.value_id + '.png'"
                                    v-model="core_values[index].fulfillment"
                            />
                        </div>
                    </div>
                </div>
                <Modal title="Übung fertig!" subtitle="Sieh dir jetzt die Auswertung zu deinen Lebensbereichen an."
                       :is-active="show_modal"
                       @click-button="nextStage"/>
                <HoveringSection>
                    <button class="button is-primary is-fullwidth" @click="this.show_modal = true">Weiter</button>
                </HoveringSection>
            </div>
            <div v-else>
                <div v-for="(value, index) in base_values">
                    <div class="card mb-4">
                        <div class="card-content">
                            <ValueSlider
                                    :title="get_base_value_name_from_id(base_values[index].value_id)"
                                    :image="get_base_value_file_from_id(base_values[index].value_id)"
                                    :model-value="base_values[index].fulfillment"
                                    :id="base_values[index].value_id"
                                    :min="min"
                                    :max="max"
                                    :step="step"
                                    :is-evaluation-mode="true"
                            />
                        </div>

                    </div>
                </div>
                <div class="card mb-4">
                    <div class="card-content">
                        <div v-for="(value, index) in core_values">
                            <ValueSlider
                                    :title="getValueNameFromId(value.value_id)"
                                    v-model="core_values[index].fulfillment"
                                    :is-evaluation-mode="true"
                                    :show-text-legend="index!==core_values.length-1"
                                    left-legend-text=""
                                    right-legend-text=""
                                    middle-legend-text=""
                            />
                        </div>
                    </div>
                </div>
                <div class="card mb-5">
                    <div class="card-content">
                        <h2 class="title is-size-4">Fazit</h2>
                        <p class="is-size-6">Es ist unrealistisch, in allen Bereichen komplett zufrieden zu sein.
                            Manchmal
                            verwendest du einfach
                            mehr Zeit auf einen Bereich und dafür kommt ein anderer vielleicht zu kurz. <b>Dein Ziel
                                sollte
                                aber
                                langfristig eine Balance sein.</b></p>
                        <p class="is-size-6">Wenn du in einem bestimmten Bereich nicht so gut
                            abschneidest,
                            überleg dir, wie du ihn verbessern möchtest. Was kannst du verändern, damit du dieses Ziel
                            erreichen
                            kannst? Ein Tipp: Immer wenn du ein neues Projekt planst (z.B. neuer Job, neues Hobby),
                            überlege dir
                            zuerst, wie das deine Regler beeinflusst. Stelle sicher, dass du keinen Bereich hierdurch
                            für lange
                            Zeit ganz vernachlässigst.</p>
                        <p class="is-size-6">Schließlich hat jeder Tag nur 24 Stunden und jede Entscheidung für etwas Neues ist oftmals
                            auch eine Entscheidung gegen etwas Existierendes, für das du dann weniger Zeit hast.
                        </p>
                    </div>
                </div>
                <HoveringSection>
                    <a href="/futureme" class="button mt-4 is-primary center-block is-fullwidth">Zurück zur
                        Übersicht</a>
                    <button @click="restartExercise" class="button mt-4 center-block is-fullwidth" v-if="revisited">
                        Übung
                        neu
                        starten
                    </button>
                </HoveringSection>

            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
