<template>
    <div class="spinner-container">
        <div class="loader"></div>
    </div>
</template>

<script>
export default {
    props: {}
};
</script>

<style scoped>
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top-color: #FF41C0;
    animation: spin 1s infinite linear;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
