<script>
import JobCard from "@/components/JobCard.vue";
import {jobList} from "@/assets/job-list";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import {Swipeable} from "vue-swipy";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";
import axios from "axios";

export default {
    name: 'JobSwiper',
    async created() {
        try {
            this.jobList = jobList.map((job) => ({...job, selected: false}))
            await this.calculateFavourableJobs();

            const response = await axios.get('/api/v1/workquizresults/');
            if (response.data && response.data[0] && response.data[0].selected_jobs.length > 0) {
                this.revisited = true;
                this.currentStage = 2;
            } else {
                this.currentStage = 0;
            }
        } catch (error) {
            console.error('An error occurred in the created lifecycle hook:', error);
        }
    },
    computed: {
        currentJob() {
            return this.preselected_jobs[this.preselected_jobs.length - 1];
        }
    },
    methods: {
        async calculateFavourableJobs() {
            const euclideanDistance = (job, target) => {
                const dimensions = ['balance', 'income', 'stability', 'independence', 'data', 'creativity', 'people', 'craftsmanship', 'internationality'];

                return Math.sqrt(dimensions.reduce((acc, dim) => {
                    // Remove asterisks and convert to float
                    const jobValue = parseFloat(job[dim].toString().replace('*', ''));
                    const targetValue = parseFloat(target[dim].toString().replace('*', ''));

                    return acc + Math.pow(jobValue - targetValue, 2);
                }, 0));
            };

            try {
                const response = await axios.get('/api/v1/workquizresults/');
                if (response.data && response.data.length > 0) {
                    const target = response.data[0];
                    target.stability = 10;  // Make sure the object exists before this line
                    this.sortedJobList = jobList.sort((a, b) => euclideanDistance(a, target) - euclideanDistance(b, target));
                    this.preselected_jobs = this.sortedJobList.slice(0, 20);
                } else {
                    console.error('Data is not available');
                }
            } catch (error) {
                console.error('Failed to fetch workquizresults:', error);
            }
        },
        async nextStage() {
            if (this.currentStage === 1) {
                const response = await axios.post('/api/v1/workquizresults/', {
                    selected_jobs: this.selected_jobs.map((job) => job.id)
                });
            }
            this.currentStage++;
        },
        selectJob() {
            this.selected_jobs.push(this.preselected_jobs.pop())
            this.checkIfAllSwiped()

        },
        unselectJob() {
            setTimeout(() => {
                this.preselected_jobs.pop()
                this.checkIfAllSwiped()
            })
        },
        checkIfAllSwiped() {
            if (this.preselected_jobs.length === 0) {
                this.show_modal = true;
            }
        },
        popPreselectedJob() {
            return this.preselected_jobs.pop();
        },
        onSwipe(direction) {
            if (direction === 'swipe-right') {
                this.selected_jobs.push(this.popPreselectedJob());
            } else {
                this.popPreselectedJob();
            }
            this.checkIfAllSwiped();
        },
    },
    components: {DescriptionCard, Modal, ExerciseIntro, TopNavbar, JobCard, Swipeable},
    data() {
        return {
            stack: [],
            jobList: [],
            showWishlistRemove: false,
            showWishlistAdd: false,
            currentStage: -1,
            preselected_jobs: [],
            selected_jobs: [],
            show_modal: false,
        }
    },
}
</script>

<template>
    <TopNavbar title="Job Swiper"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="currentStage === 0">
                <ExerciseIntro module-name="jobswiper" @intro-finished="nextStage"/>
                <button class="button is-primary mt-4 is-fullwidth" @click="nextStage">Los geht's</button>
            </div>
            <div v-else-if="currentStage === 1">
                <div class="swipe-container mt-5">
                    <!--<Swipeable
                            class="swipe-card"
                            v-for="job in preselected_jobs"
                            :key="job.id"
                            v-on:swipe="onSwipe"
                    >-->
                    <JobCard
                            v-if="currentJob"
                            :is-swipe-mode="true"
                            :name="currentJob.name"
                            :description="currentJob.description"
                            :subgroups="currentJob.subgroups"
                            :image="currentJob.image"
                            :balance="currentJob.balance"
                            :income="currentJob.income"
                            :stability="currentJob.stability"
                            :independence="currentJob.independence"
                            :data="currentJob.data"
                            :creativity="currentJob.creativity"
                            :craftsmanship="currentJob.craftsmanship"
                            :people="currentJob.people"
                            :university="currentJob.university"
                            :internationality="currentJob.internationality"
                            :id="currentJob.id"
                            :selected="currentJob.selected"
                            @job-selected="selectJob"
                            @job-unselected="unselectJob"
                    />
                    <!--</Swipeable>-->
                    <Modal title="Übung fertig!" subtitle="Du hast über alle Jobs entschieden." :is-active="show_modal"
                           @click-button="nextStage"/>
                </div>
            </div>
            <div v-else-if="currentStage === 2">
                <DescriptionCard title="Einstieg geschafft!">
                    <p><b>Deine bisherigen Job-Matches findest du in der Wunschliste.</b> Du kannst sie
                        mithilfe der Bibliothek der Berufsfelder so weit verändern, wie du möchtest. Bitte denk dran,
                        dass
                        wir dir hier eine grobe Orientierung bieten und es wichtig ist, dich noch weiter mit deinen
                        Traumjobs auseinanderzusetzen. Aber: Nur, weil du dich jetzt für ein Berufsfeld entscheidest,
                        heißt
                        das nicht, dass du damit den Rest deines Lebens verbringen wirst. Es gibt Umschulungen,
                        Weiterbildungen, Fernstudiengänge und viele Möglichkeiten für Quereinsteiger und Berufswechsler.
                        Wichtig ist in erster Linie, dass du immer bereit bist, weiterzulernen und mit aktuellen
                        Entwicklungen mitzuhalten.</p>
                </DescriptionCard>
                <button class="button is-info mt-4 is-fullwidth" @click="this.$router.push('/futurework/wishlist')">
                    Direkt zur Wunschliste
                </button>
                <button class="button is-primary mt-4 is-fullwidth"
                        @click="this.$router.push('/futurework/overview')">In
                    der Job-Bibliothek stöbern
                </button>
                <button class="button is-white mt-4 is-fullwidth"
                        @click="this.$router.push('/futurework/')">Zurück zur Übersicht
                </button>
            </div>

        </div>
    </div>
</template>

<style>

.swipe-card {
    position: absolute;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
    margin-left: auto;
    left: 0;
    right: 0;
    margin-right: auto;
    position: absolute;

}

.swipe-container {
    position: relative;
    min-height: 100vh;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
</style>
