export const questions = [
    {
        text: "Welche Strategie ist am effektivsten, um in einem Beruf mit mittlerem Automatisierungsrisiko langfristig erfolgreich zu bleiben?",
        answers: [
            {text: "Sich ausschließlich auf die technischen Aspekte des Berufs konzentrieren", correct: false},
            {text: "Den Beruf wechseln, sobald erste Anzeichen von Automatisierung sichtbar werden", correct: false},
            {
                text: "Kreative und zwischenmenschliche Fähigkeiten stärken und technologische Entwicklungen in den Joballtag integrieren",
                correct: true
            },
        ],
        explanation: "In Jobs, die teilweise durch Maschinen ersetzt werden könnten, ist es am besten, wenn man beides kann: mit Technik umgehen und Dinge, die nur Menschen gut können. Wenn man kreativ ist, gut mit anderen zusammenarbeitet und gleichzeitig neue Technik in seine Arbeit einbaut, macht man sich wertvoller. So kann man Aufgaben machen, die Maschinen nicht können, und trotzdem die Vorteile von neuer Technik nutzen."
    },
    {
        text: "Welcher Ansatz zur Bewertung der Zukunftsfähigkeit eines Berufs ist am aussagekräftigsten?",
        answers: [
            {text: "Ausschließlich aktuelle Gehaltstrends analysieren", correct: false},
            {text: "Nur die Einschätzungen von KI-basierten Vorhersagetools berücksichtigen", correct: false},
            {text: "Verschiedene Datenquellen kombinieren und kritisch hinterfragen (inklusive diesem Kurs)", correct: true},
        ],
        explanation: "Um herauszufinden, ob ein Beruf zukunftssicher ist, ist es am besten, viele verschiedene Infos zu sammeln und darüber nachzudenken. Man kann sich anschauen, wie sehr der Job durch Maschinen bedroht ist, was gerade in dem Bereich passiert, was Experten sagen und was man selbst beobachtet. Wenn man all diese Dinge zusammen betrachtet und darüber nachdenkt, bekommt man ein besseres Bild davon, wie der Job in Zukunft aussehen könnte."
    },
    {
        text: "Wie kann man Tools wie willrobotstakemyjob.com sinnvoll nutzen?",
        answers: [
            {text: "Als absolute Vorhersage für die Zukunft eines Berufs", correct: false},
            {text: "Als Orientierungshilfe zur Einschätzung von Automatisierungsrisiken", correct: true},
            {text: "Um zu entscheiden, ob man seinen aktuellen Job sofort kündigen sollte", correct: false},
        ],
        explanation: "Webseiten wie willrobotstakemyjob.com sind gut, um eine erste Idee zu bekommen, wie gefährdet ein Job durch Automatisierung sein könnte. Sie zeigen, was in verschiedenen Berufen passieren könnte. Man sollte diese Infos aber nicht als sichere Vorhersage sehen oder nur darauf vertrauen, wenn man wichtige Entscheidungen über seinen Job trifft. Sie helfen eher dabei, über mögliche Veränderungen nachzudenken und die eigene Zukunft zu planen."
    },
    {
        text: "Welche Herangehensweise ist am sinnvollsten, um die Automatisierbarkeit des eigenen Jobs einzuschätzen?",
        answers: [
            {text: "Sich ausschließlich auf Online-Tools verlassen", correct: false},
            {text: "Die eigenen Aufgaben analysieren und mit technologischen Trends abgleichen", correct: true},
            {text: "Die Einschätzung vollständig dem Arbeitgeber überlassen", correct: false},
        ],
        explanation: "Um zu verstehen, ob der eigene Job durch Maschinen ersetzt werden könnte, ist es am besten, genau zu schauen, was man jeden Tag macht. Man überlegt, welche Aufgaben vielleicht von Computern oder Maschinen gemacht werden könnten und welche nur Menschen gut können. Gleichzeitig informiert man sich darüber, welche neuen Technologien es in seinem Arbeitsbereich gibt. So bekommt man ein realistisches Bild davon, wie sicher der eigene Job ist, ohne zu positiv oder zu negativ zu denken."
    }
];
