<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "SkillSpiderwebCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            link="/futureskills/kompetenzspinne"
            image-url="futureskills/kompetenzspinne.png"
            title="Kompetenzspinne"
            description="Teste, wie es aktuell um deine zukunftsrelevanten Skills steht."
    />
</template>
