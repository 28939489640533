import {createRouter, createWebHistory} from 'vue-router'
import MyAccount from "@/views/dashboard/MyAccount.vue";
import PreCourse from "@/views/dashboard/PreCourse.vue";
import ImageExercise from "@/views/futureme/ImageExercise.vue";
import ValueExercise from "@/views/futureme/ValueExercise.vue";
import LifeCockpitExercise from "@/views/futureme/LifeCockpitExercise.vue";
import Kompetenzspinne from "@/views/futureskills/FutureSkillsExercise.vue";
import AuthView from "@/views/AuthView.vue";
import Session from 'supertokens-web-js/recipe/session';
import FutureMe from "@/views/futureme/FutureMe.vue";
import FutureSkills from "@/views/futureskills/FutureSkills.vue";
import FutureWork from "@/views/futurework/FutureWork.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import WorkQuiz from "@/views/futurework/WorkQuiz.vue";
import KalenderKick from "@/views/futureme/CalendarExercise.vue";
import AuthResetPassword from "@/views/AuthResetPassword.vue";
import JobOverview from "@/views/futurework/JobOverview.vue";
import Imprint from "@/views/Imprint.vue";
import Privacy from "@/views/Privacy.vue";
import AuthVerifyEmail from "@/views/AuthVerifyEmail.vue";
import EmailVerification from "supertokens-web-js/recipe/emailverification";
import LifeDesignOverview from "@/views/dashboard/LifeDesignOverview.vue";
import JobSwiper from "@/views/futurework/JobSwiper.vue";
import Wishlist from "@/views/futurework/Wishlist.vue";
import FutureSkillsVideo from "@/views/futureskills/FutureSkillsVideo.vue";
import Explore from "@/views/dashboard/Explore.vue";
import GoogleCallbackView from "@/views/GoogleCallbackView.vue";
import AppleCallbackView from "@/views/AppleCallbackView.vue";
import FutureWorkVideo from "@/views/futurework/FutureWorkVideo.vue";
import Unsubscribe from "@/views/Unsubscribe.vue";
import DeleteUserPage from "@/views/DeleteUserPage.vue";
import CertificateGenerator from "@/views/CertificateGenerator.vue";
import AdminPanel from "@/views/admin/AdminPanel.vue";
import AssessmentVideo from "@/views/futurework/AssessmentVideo.vue";

const routes = [
    {
        path: "/auth",
        name: "auth",
        component: AuthView,
        meta: {
            hideNavbar: true,
            smallContainer: true,
        }
    },
    {
        path: "/auth/reset-password",
        name: "auth_password_reset",
        component: AuthResetPassword,
        meta: {
            hideNavbar: true,
            smallContainer: true,
        }
    },
    {
        path: "/auth/verify-email",
        name: "auth_verify_email",
        component: AuthVerifyEmail,
        meta: {
            hideNavbar: true,
            smallContainer: true,
            requireLogin: false,
        }
    },
    {
        path: "/impressum",
        name: "impressum",
        component: Imprint,
        meta: {
            hideNavbar: true,
            smallContainer: false,
        }
    },
    {
        path: "/datenschutz",
        name: "datenschutz",
        component: Privacy,
        meta: {
            hideNavbar: true,
            smallContainer: false,
        }
    },
    {
        path: "/auth/callback/google",
        name: "authcallback",
        component: GoogleCallbackView,
        meta: {
            hideNavbar: true,
            smallContainer: true,
        }
    },
    {
        path: "/auth/callback/apple",
        name: "authcallback-apple",
        component: AppleCallbackView,
        meta: {
            hideNavbar: true,
            smallContainer: true,
        }
    },
    {
        path: "/unsubscribe/:userId",
        name: "unsubscribe",
        component: Unsubscribe,
        meta: {
            hideNavbar: true,
            smallContainer: true,
        }
    },
    {
        path: "/delete-user",
        name: "delete-user",
        component: DeleteUserPage,
        meta: {
            hideNavbar: true,
            smallContainer: true,
            requireLogin: true,
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requireLogin: true,
            checkFillout: true,
        }
    },
    {
        path: '/lifedesign',
        name: 'LifeDesignOverview',
        component: LifeDesignOverview,
        meta: {
            requireLogin: true,
            checkFillout: true,
        }
    },
    {
        path: '/futureme',
        name: 'Future Me',
        component: FutureMe,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework',
        name: 'Future Work',
        component: FutureWork,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureskills',
        name: 'Future Skills',
        component: FutureSkills,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/explore',
        name: 'Explore',
        component: Explore,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/profile',
        name: 'MyAccount',
        component: MyAccount,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/precourse',
        name: 'PreCourse',
        component: PreCourse,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureme/vision',
        name: 'ImageExercise',
        component: ImageExercise,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureskills/video',
        name: 'FutureSkillsVideo',
        component: FutureSkillsVideo,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureme/values',
        name: 'ValueExercise',
        component: ValueExercise,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureme/calendar',
        name: 'KalenderKick',
        component: KalenderKick,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureme/cockpit',
        name: 'LifeCockpitExercise',
        component: LifeCockpitExercise,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/aiandme',
        name: 'AiAndMe',
        component: FutureWorkVideo,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/jobsecurity',
        name: 'JobSecurity',
        component: AssessmentVideo,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/workgoals',
        name: 'WorkQuiz',
        component: WorkQuiz,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/certificate',
        name: 'CertificateGenerator',
        component: CertificateGenerator,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/jobswiper',
        name: 'JobSwiper',
        component: JobSwiper,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/wishlist',
        name: 'Wishlist',
        component: Wishlist,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futurework/overview',
        name: 'JobOverview',
        component: JobOverview,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/futureskills/kompetenzspinne',
        name: 'Kompetenzspinne',
        component: Kompetenzspinne,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminPanel,
        meta: {
            requireLogin: true
        }
    }
]

const router = createRouter({
    // @ts-ignore
    history: createWebHistory(process.env.BASE_URL),
    routes
})

async function doesSessionExist() {
    if (await Session.doesSessionExist()) {
        return true;
    } else {
        return false;
    }
}

async function isUserVerified() {
    if ((await EmailVerification.isEmailVerified()).isVerified) {
        return true;
    } else {
        return false;
    }
}

/*async function hasFilledUserInformation() {
    await axios.get('/api/v1/yoloausers/has_filled_user_information/')
        .then(response => {
            if (response.data === 'unfilled') {
                //Transfer user to page to fill in user data
                return false
            } else {
                return true
            }
        })
}*/

router.beforeEach(async (to, from) => {
    const isAuthenticated = await doesSessionExist()
    if (to.matched.some(record => record.meta.requireLogin && !isAuthenticated)) {
        return '/auth'
    }

    if (to.matched.some(record => record.meta.requireLogin && isAuthenticated)) {
        const isVerified = await isUserVerified()
        if (!isVerified) {
            return '/auth/verify-email'
        }
    }

    /*if (to.matched.some(record => record.meta.checkFillout)) {

        const hasFilledOut = await hasFilledUserInformation()
        if (!hasFilledOut) {
            return '/dashboard/precourse'
        }
    }*/
})
export default router
