export const questions = [
    {
        text: "Was ist laut dem Video eine der wichtigsten Fähigkeiten im 21. Jahrhundert?",
        answers: [
            {text: "Schnell lernen können", correct: true},
            {text: "Viel Erfahrung ansammeln", correct: false},
            {text: "Mehrere Sprachen fließend sprechen", correct: false},
        ],
        explanation: "Das Video sagt, dass es heute super wichtig ist, schnell lernen zu können. Die Welt ändert sich so schnell, dass man immer Neues lernen muss, um mitzuhalten. Klar, Erfahrung und Sprachen sind auch gut, aber nicht so entscheidend wie schnelles Lernen im 21. Jahrhundert."
    },
    {
        text: "Was solltest du im Umgang mit KI im Alltag und Job beachten?",
        answers: [
            {text: "KI-Systeme vollständig vermeiden", correct: false},
            {text: "Alle KI-Ergebnisse als korrekt akzeptieren", correct: false},
            {text: "Die von KI gelieferten Inhalte kritisch hinterfragen", correct: true},

        ],
        explanation: "Bei KI ist es wichtig, nicht alles einfach zu glauben, was sie sagt. KI kann uns zwar super helfen, macht aber auch mal Fehler. Es wäre nicht gut, KI gar nicht zu benutzen, weil sie oft echt nützlich ist. Aber alles blind zu glauben, wäre auch falsch. Am besten nutzt du KI, denkst aber selbst darüber nach, ob das, was sie sagt, wirklich Sinn macht."
    },
    {
        text: "Was bedeutet es, KI-gestärkte Problemlöse-Fähigkeiten zu haben?",
        answers: [
            {text: "KI-Tools nutzen und deren Ergebnisse mit eigenem Fachwissen kombinieren", correct: true},
            {text: "Alle Entscheidungen weitestgehend der KI überlassen", correct: false},
            {text: "KI-Systeme weitestgehend ignorieren und auf menschliche Intuition vertrauen", correct: false},
            {text: "KI-Empfehlungen ohne eigene Überprüfung akzeptieren", correct: false}
        ],
        explanation: "KI-gestärkte Problemlöse-Fähigkeiten bedeuten, dass du KI-Tools clever nutzt, aber nicht alles der KI überlässt. Du benutzt KI als Hilfe, denkst aber selbst darüber nach, was sie dir sagt. Du mischst sozusagen das Beste aus beiden Welten: Die Ideen der KI und dein eigenes Wissen. So kannst du bessere Entscheidungen treffen, als wenn du nur die KI oder nur dein eigenes Wissen nutzen würdest."
    },
    {
        text: "Warum ist es wichtig, besondere menschliche Fähigkeiten zu verbessern, auch wenn es immer mehr KI gibt?",
        answers: [
            {
                text: "Um Dinge wie Mitgefühl und kreatives Denken zu stärken, die eine KI nicht so gut beherrscht",
                correct: true
            },
            {text: "Um in allen Aufgaben schneller als die KI zu sein", correct: false},
            {text: "Um bessere KI programmieren zu können", correct: false},
        ],
        explanation: "Es ist wichtig, unsere menschlichen Stärken auszubauen, weil wir damit Dinge können, die Computern und KI schwerfallen. Zum Beispiel können wir uns gut in andere einfühlen oder auf kreative Art Probleme lösen. Das macht uns besonders. Wir müssen nicht versuchen, in allem besser als KI zu sein. Stattdessen geht es darum, dass wir Menschen und KI gut zusammenarbeiten. So können wir gemeinsam die besten Ergebnisse erzielen."
    }
];
