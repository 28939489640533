<script>
import axios from "axios";
import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import FutureMeCard from "@/components/ModuleOverviewCards/lifedesign/FutureMeCard.vue";
import FutureSkillsCard from "@/components/ModuleOverviewCards/lifedesign/FutureSkillsCard.vue";
import FutureWork from "@/views/futurework/FutureWork.vue";
import FutureWorkCard from "@/components/ModuleOverviewCards/lifedesign/FutureWorkCard.vue";

export default {
    name: 'CourseOverview',
    components: {FutureWorkCard, FutureWork, FutureSkillsCard, FutureMeCard, TopNavbar, ModuleOverviewCard},
    beforeCreate() {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    //Transfer user to page to fill in user data
                    this.$router.push('/dashboard/precourse')
                }
            })
    },
    created() {
        axios.get('/api/v1/progress/').then(response => {
            this.user_group = response.data.user_group
            this.firstname = response.data.firstname
            this.future_me_progress = response.data.future_me.progress
            this.future_skills_progress = response.data.future_skills.progress
            this.future_work_progress = response.data.future_work.progress
        })
    },
    data() {
        return {
            future_me_progress: -1,
            future_skills_progress: -1,
            future_work_progress: -1,
            user_group: 0,
            firstname: '',
        }
    },
}
</script>

<template>
    <TopNavbar title="Life Design-Kurs" back-link="/"/>
    <div class="app-container">
        <div class="app-content-container">
            <FutureMeCard
                    :progress="future_me_progress"
                    :previous-module-progress="1"
            />
            <FutureSkillsCard
                    :progress="future_skills_progress"
                    :previous-module-progress="future_me_progress"
            />
            <FutureWorkCard
                    :progress="future_work_progress"
                    :previous-module-progress="future_skills_progress"
            />
        </div>
    </div>
</template>
