<script>
export default {
    name: 'ProgressBar',
    props: {
        progress: {
            default: 0,
            type: Number
        },
        classname: {
            default: "is-primary",
            type: String
        }
    }
}
</script>

<template>
    <progress :class="classname" class="progress is-small" :value="progress" max="100">
        {{ progress }}%
    </progress>
</template>

<style scoped lang="scss">
.progress .is-small {
}
</style>
