<script>
import ModuleStartButton from "@/components/ModuleStartButton.vue";
import CircularProgress from "@/components/BasicUI/CircularProgress.vue";

export default {
    name: 'ModuleOverviewCard',
    components: {CircularProgress, ModuleStartButton},
    props: {
        previousModuleProgress: Number,
        progress: Number,
        path: String,
        imageUrl: String,
        title: String,
        subtitle: String,
        description: String,
        link: String,
        disabled: Boolean,
        resultsButtonText: {
            type: String,
            default: "Ergebnisse ansehen"
        },
        isExerciseCard: {
            type: Boolean,
            default: true,
        }
    }
}
</script>

<template>
    <div class="card mb-5">
        <div class="card-content">
                <p class="title is-size-4 is-size-5-mobile mb-3">{{ title }}</p>

            <div class="content is-flex is-flex-direction-row is-justify-content-start is-align-content-start">

                <div class="flex-item course-image">
                    <img :src="'/illustrations/' + imageUrl" v-if="imageUrl"/>
                </div>
                <div class="is-flex is-flex-direction-column is-justify-content-center">
                    <div>
                        <p class="is-size-7-mobile mb-3">{{ description }}</p>
                    </div>
                    <div class="is-flex is-flex-direction-row is-justify-content-start">
                        <div class="mr-1" v-if="!isExerciseCard">
                            <CircularProgress :progress="progress * 100"/>
                        </div>
                        <div v-if="!disabled">
                            <ModuleStartButton :link="link"
                                               :results-button-text="resultsButtonText"
                                               :progress="progress"
                                               :is-exercise="isExerciseCard"
                                               :previous-module-progress="previousModuleProgress"
                            />
                        </div>
                        <div v-else>
                            <button class="button is-disabled is-grey" disabled>Coming soon</button>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media screen and (max-width: 768px) {
    .course-image {
        max-width: 50%;
        padding-right: 10px;
    }
}

@media screen and (min-width: 769px) {
    .course-image {
        max-width: 40%;
        padding-right: 40px;
    }
}

.flex-item {
    vertical-align: center;
}

</style>
