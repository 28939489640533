export const values_numbered = [
    {id: 0, name: 'Viel Abwechslung im Alltag (Hobbies, soziale Kontakte, Urlaub)'},
    {id: 1, name: 'Routine im Alltag haben'},
    {id: 2, name: 'Beliebt sein'},
    {id: 3, name: 'Zu einer Gruppe dazugehören'},
    {id: 4, name: 'Menschen anleiten und Teams anführen'},
    {id: 5, name: 'Strategien auf großer Ebene entwickeln'},
    {id: 6, name: 'Diszipliniert und fleißig sein'},
    {id: 7, name: 'Meinen Beitrag zur Gesellschaft leisten'},
    {id: 8, name: 'Mich durchsetzen'},
    {id: 9, name: 'Ehrlich sein und sagen, was ich denke'},
    {id: 10, name: 'Dankbar sein für das, was ich habe'},
    {id: 11, name: 'Freundlich zu anderen sein'},
    {id: 12, name: 'Der*die Beste sein'},
    {id: 13, name: 'Eigenständig entscheiden können, wie ich mein Leben führe'},
    {id: 14, name: 'Persönliche Freiheit'},
    {id: 15, name: 'Glaube ausleben'},
    {id: 16, name: 'Glaubwürdig und authentisch „Ich selber“ sein'},
    {id: 17, name: 'Persönlich wachsen / mich ständig herausfordern'},
    {id: 18, name: 'Wohlhabend sein'},
    {id: 19, name: 'Zuverlässigkeit'},
    {id: 20, name: 'Entscheidungen gründlich planen'},
    {id: 21, name: 'Teamwork'},
    {id: 22, name: 'Offenheit gegenüber Neuem'},
    {id: 23, name: 'Traditionen leben'},
    {id: 24, name: 'Großzügig sein'},
    {id: 25, name: 'Viel Spaß im Leben haben'},
    {id: 26, name: 'Beruflicher Erfolg'},
    {id: 27, name: 'Familie gründen'},
    {id: 28, name: 'Zeit für mich'},
    {id: 29, name: 'Heimatverbundenheit'},
    {id: 30, name: 'Soziale Kontakte pflegen'},
    {id: 31, name: 'Zeit für Partner*in'},
    {id: 32, name: 'Andere Personen aus meinem Umfeld zufrieden machen'},
    {id: 33, name: 'Ständig dazulernen und Wissen sammeln'},
    {id: 34, name: 'Sicher auftreten'},
    {id: 35, name: 'Gebraucht werden'},
    {id: 36, name: 'Mut zur Herausforderung'},
    {id: 37, name: 'Einzigartig sein, herausstechen'},
    {id: 38, name: 'Sportlichkeit und körperliches Wohlbefinden'},
    {id: 39, name: 'Expert*in auf meinem Gebiet werden'},
    {id: 40, name: 'Sparsam sein'},
    {id: 41, name: 'Umweltbewusst leben'},
    {id: 42, name: 'Mich kreativ ausleben'},
    {id: 43, name: 'Spontan sein'},
];

export const base_values_numbered = [
    {id: -10, name: 'Liebe & Beziehungen', file: 'love.png'},
    {id: -11, name: 'Gesundheit & Fitness', file: 'health.png'},
    {id: -12, name: 'Spaß & Entspannung', file: 'fun.png'},
    {id: -13, name: 'Arbeit & Lernen', file: 'work.png'},
]

export function get_base_value_name_from_id(id) {
    return base_values_numbered.filter((value) => value.id === id)[0].name
}

export function get_base_value_file_from_id(id) {
    return base_values_numbered.filter((value) => value.id === id)[0].file
}

export function compareRanks(a, b) {
    return parseInt(a.rank) - parseInt(b.rank)
}

export function getValueNameFromId(id) {
    return values_numbered.filter((value) => value.id === id)[0].name
}
