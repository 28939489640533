<template>
    <TopNavbar title="Abschlusszertifikat erstellen"/>
    <div class="app-container">
        <div class="app-content-container">
            <div class="container">
                <div v-if="eligible == -1">

                </div>
                <div v-else-if="eligible == 0">
                    <div v-if="!lastNameIsSaved">
                        <h2 class="title">Daten eingeben</h2>
                        <p class="mb-5">
                            Lass uns dein Zertifikat generieren! Bitte gib noch deinen Nachnamen ein, damit dein voller
                            Name
                            auf dem Zertifikat erscheint. <b>Überprüfe vor dem Absenden, ob alles richtig geschrieben
                            ist, da du den Namen
                            später nicht mehr ändern kannst.</b> Soll dein Nachname nicht mit auf das Zertifikat, lass
                            das Feld frei.</p>
                        <div class="field">
                            <label class="label">Vorname</label>
                            <div class="control">
                                <input class="input" type="text" v-model="user.firstname" placeholder="Dein Vorname">
                            </div>
                        </div>

                        <div class="field mb-4">
                            <label class="label">Nachname</label>
                            <div class="control">
                                <input class="input" type="text" v-model="user.lastname" placeholder="Dein Nachname">
                            </div>
                        </div>
                        <CertificateToPDF text="Zertifikat erstellen" :firstname="user.firstname"
                                          :lastname="user.lastname" @certificate-generated="this.eligible = 2"/>
                    </div>
                    <div v-else>
                        <h2 class="title">Zertifikat erfolgreich erstellt</h2>
                        <p class="mb-3">
                            Du hast bereits ein Zertifikat erstellt. Hier kannst du es erneut herunterladen.</p>
                        <CertificateToPDF text="Zertifikat erneut herunterladen" :firstname="user.firstname"
                                          :lastname="user.lastname"/>
                        <p class="mt-3">Du hast ein Problem mit der Zertifikationserstellung oder hast dich bei deinem Namen vertippt? Schreib uns an <a href="mailto:kontakt@yoloa.de">kontakt@yoloa.de</a>.</p>
                    </div>
                </div>
                <div v-else-if="eligible === 2">
                    <h2 class="title">Zertifikat erfolgreich erstellt</h2>
                    <p class="mb-3">Dein Zertifikat wurde erfolgreich erstellt! Der Download sollte gleich beginnen.</p>
                    <CertificateToPDF text="Zertifikat erneut herunterladen" :firstname="user.firstname"
                                      :lastname="user.lastname"/>
                </div>
                <div v-else>
                    <p>Du hast den Kurs noch nicht abgeschlossen. Bitte schaue wieder vorbei, sobald alle Module
                        vollständig sind.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import axios from "axios";
import CertificateToPDF from "@/components/CertificateToPDF.vue";

export default {
    components: {CertificateToPDF, TopNavbar},
    data() {
        return {
            user: {
                firstname: '',
                lastname: ''
            },
            lastNameIsSaved: false,
            certificateUrl: null,
            eligible: -1,
        };
    },
    mounted() {
        this.checkCertificate();
        this.fetchUserData();
    },
    methods: {
        fetchUserData() {
            axios.get('/api/v1/yoloausers/current_user/') // Adjust the endpoint as necessary
                .then(response => {
                    if (response.data) {
                        this.user = response.data;

                        if (this.user.lastname && this.user.lastname.trim() !== '') {
                            this.lastNameIsSaved = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    // Handle error, e.g., showing a message to the user
                });
        },
        async checkCertificate() {
            try {
                const response = await axios.get('/api/v1/check-certificate/');
                if (response.status === 'ok') {
                    this.eligible = 1;
                } else {
                    this.eligible = 0;
                }
            } catch (error) {
                console.error('Error generating certificate:', error);
            }
        },
    },
};
</script>

<style>
/* Add any additional styles if needed */
</style>
