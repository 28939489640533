<template>
    <div class="app-container">
        <div class="app-content-container">
            <div @click="this.$router.push('/')" style="cursor: pointer;" class="is-text-centered">
                <img src="../assets/images/yoloa-logo.svg" class="logo mb-4"/><br/>
                <br/><br/>
            </div>

            <div class="is-text-centered mb-4" v-if="error">
                <div class="notification is-danger">
                    <h4 class="title is-text-centered">Fehler bei der Abmeldung.</h4>
                    <p>Existiert dein Nutzeraccount? Bitte wende dich für Hilfe an kontakt@yoloa.de.</p>
                </div>
            </div>

            <div class="is-text-centered" v-if="!unsubscribeSuccessful">
                <h1 class="title is-text-centered">Von E-Mails abmelden</h1>
                <p>Schade, dass du gehst! Klicke hier, um deine Abmeldung zu bestätigen:</p>
                <button @click="unsubscribe" class="button is-primary mt-4">Von E-Mails abmelden</button>
                <br/><br/>
                <a class="is-clickable-text" @click="this.$router.push('/')" style="cursor: pointer;">Abbrechen</a>
            </div>
            <div class="is-text-centered" v-else>
                <h1 class="title is-text-centered">Du wurdest erfolgreich abgemeldet.</h1>
                <p>Du hast dich erfolgreich von der E-Mail-Liste abgemeldet.</p>
                <br/><br/>
                <a class="is-clickable-text" @click="this.$router.push('/')" style="cursor: pointer;">Zurück zur
                    Startseite</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'UnsubscribePage',
    data() {
        return {
            unsubscribeSuccessful: false,
            error: false,
        };
    },
    computed: {
        userId() {
            return this.$route.params.userId;
        },
    },
    methods: {
        async unsubscribe() {
            try {
                await axios.post('/api/v1/unsubscribe/', {userId: this.userId});
                this.unsubscribeSuccessful = true;
            } catch (error) {
                console.error("Error during unsubscribe:", error);
                this.error = true
            }
        }
    }
}
</script>
