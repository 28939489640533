<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "ValueCompassCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            link="/futureme/values"
            image-url="/futureme/compass.png"
            title="Mein Wertekompass"
            subtitle="Welche Werte sind für dich im Leben wichtig?"
            description="Finde heraus, was deinen Charakter ausmacht – damit du in Einklang mit deinen Werten leben kannst."
    />
</template>
