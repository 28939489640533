<script>
export default {
    name: 'Modal',
    props: {
        title: {
            default: 0,
            type: String
        },
        subtitle: {
            default: 0,
            type: String
        },
        classname: {
            default: "is-primary",
            type: String
        },
        isActive: {
            default: false,
            type: Boolean,
        },
        buttonText: {
            default: "Ergebnisse ansehen",
            type: String
        }
    },
    emits: ['click-button']
}
</script>

<template>
    <div class="modal" :class="isActive? 'is-active' : ''">
        <div class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body">
                <img src="../../assets/images/rocketonly.webp" width="300" alt="Rakete"/>
                <p class="title is-4 mt-3">{{ title }}</p>
                <p class="subtitle is-6">{{ subtitle }}</p>
                <slot></slot>
                <button class="button is-fullwidth" :class="classname" @click="this.$emit('click-button')">
                    {{ buttonText }}
                </button>
            </section>
        </div>
        <!--<button class="modal-close is-large" aria-label="close"></button>-->
    </div>
</template>

<style scoped lang="scss">
.modal-card {
  padding-left: 20px;
  padding-right: 20px;
}

.modal-card-body {
  border-radius: 20px;
  text-align: center;
}
</style>
