<template>
    <TopNavbar title="Mein Account"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="!editMode">
                <div class="panel">
                    <p class="panel-heading">Deine Angaben</p>
                    <div class="panel-block">
                        <p>Halte deine Angaben aktuell, damit wir dir für deine Situation passende Informationen senden
                            können (z.B.
                            Jobangebote, Event-Einladungen, Online-Kurse).</p>
                    </div>
                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Benutzergruppe</label>
                            <div class="control">
                                <p>{{ getUserGroupText(user.user_group) }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- Age -->
                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Alter</label>
                            <div class="control">
                                <p>{{ getAgeText(user.age) }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- Study Status (only for "Student:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 1">
                        <div class="field">
                            <label class="label">Dein nächster angestrebter Abschluss</label>
                            <div class="control">
                                <p>{{ getStudyStatusText(user.study_status) }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- Background (for "Student:in" and "Doktorand:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 1 || user.user_group === 2">
                        <div class="field">
                            <label class="label">Hintergrund</label>
                            <div class="control">
                                <p>{{ getBackgroundText(user.background) }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- School Type (only for "Schüler:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 0">
                        <div class="field">
                            <label class="label">Schultyp</label>
                            <div class="control">
                                <p>{{ getSchoolTypeText(user.school_type) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Email-Einstellung</label>
                            <div class="control">
                                <p>{{ user.email_opt_in ? 'Yoloa darf mir E-Mails senden.' : 'Yoloa darf mir keine E-Mails senden.' }}</p>
                            </div>
                        </div>
                    </div>

                    <footer class="card-footer">
                        <a class="card-footer-item clickable-text" @click="toggleEditMode">
                            Angaben bearbeiten
                        </a>
                    </footer>
                </div>
            </div>
            <div v-else>
                <div class="panel">
                    <p class="panel-heading">Deine Angaben</p>
                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Benutzergruppe</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="user.user_group">
                                        <option v-for="group in userGroups" :key="group.value" :value="group.value">
                                            {{ group.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Age Select -->
                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Alter</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="user.age">
                                        <option v-for="age in ageOptions" :key="age.value" :value="age.value">
                                            {{ age.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Study Status Select (only for "Student:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 1">
                        <div class="field">
                            <label class="label">Dein nächster angestrebter Abschluss</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="user.study_status">
                                        <option v-for="status in studyStatusOptions" :key="status.value"
                                                :value="status.value">{{ status.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Background Input (for "Student:in" and "Doktorand:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 1 || user.user_group === 2">
                        <div class="field">
                            <label class="label">Hintergrund</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="user.background">
                                        <option v-for="background in backgroundOptions" :key="background.value"
                                                :value="background.value">{{ background.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- School Type Select (only for "Schüler:in" users) -->
                    <div class="panel-block" v-if="user.user_group === 0">
                        <div class="field">
                            <label class="label">Schultyp</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="user.school_type">
                                        <option v-for="schoolType in schoolTypeOptions" :key="schoolType.value"
                                                :value="schoolType.value">{{ schoolType.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="panel-block">
                        <div class="field">
                            <label class="label">Email-Einstellungen</label>
                            <div class="control">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" v-model="user.email_opt_in">
                                        Yoloa darf mir E-Mails senden.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5"></div>
                    <div class="mb-5"></div>

                    <footer class="card-footer">
                        <a class="card-footer-item">
                            <button @click="saveChanges" class="button is-primary">Änderungen speichern</button>

                        </a>
                        <a class="card-footer-item clickable-text" @click="toggleEditMode">
                            Abbrechen

                        </a>
                    </footer>
                </div>
            </div>
            <p class="mt-6">Du möchtest dich ausloggen? Klicke <a @click="signOut"
                                                                  class="clickable-text">hier</a>.
            </p><br />
            <!--<p>Du möchtest deinen Account löschen? Schreibe eine formlose Mail an: <a href="mailto:kontakt@yoloa.de">kontakt@yoloa.de</a></p>-->
        </div>
    </div>
</template>

<script>
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import axios from "axios";
import {mapState} from 'vuex';

export default {
    name: 'MyAccount',
    components: {TopNavbar},
    created() {
        this.fetchUserData(); // Fetch user data when the component is created
    },
    methods: {
        toggleEditMode() {
            // If not in edit mode, switch to edit mode
            if (!this.editMode) {
                this.editMode = true;
            } else {
                // If already in edit mode, cancel the edit
                this.cancelEdit();
            }
        },

        // Function to save changes
        saveChanges() {
            this.editMode = false;

            axios.patch(`/api/v1/yoloausers/update_current_user/`, {
                user_group: this.user.user_group,
                age: this.user.age,
                study_status: this.user.study_status,
                background: this.user.background,
                school_type: this.user.school_type,
                email_opt_in: this.user.email_opt_in,
                // Include any other fields you wish to update
            })
                .then(response => {
                    // Handle success, perhaps showing a message to the user or redirecting
                    console.log('User information updated successfully', response.data);
                    // Optionally redirect the user or fetch the updated data
                    // this.$router.push('/dashboard');
                })
                .catch(error => {
                    // Handle error, perhaps showing an error message to the user
                    console.error('There was an error updating the user information', error);
                });
        },
        cancelEdit() {
            // Simply exit the edit mode without modifying user data
            this.editMode = false;
            // Retrieve user data again to populate the fields
            this.fetchUserData();
        },
        fetchUserData() {
            axios.get('/api/v1/yoloausers/current_user/') // Adjust the endpoint as necessary
                .then(response => {
                    if (response.data) {
                        this.user = response.data; // Assuming the response data structure matches your user data structure
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    // Handle error, e.g., showing a message to the user
                });
        },
        getUserGroupText(userGroupValue) {
            const group = this.userGroups.find((group) => group.value === userGroupValue);
            return group ? group.text : "";
        },
        getAgeText(ageValue) {
            const age = this.ageOptions.find((age) => age.value === ageValue);
            return age ? age.text : "";
        },
        getStudyStatusText(studyStatusValue) {
            const status = this.studyStatusOptions.find((status) => status.value === studyStatusValue);
            return status ? status.text : "";
        },
        getBackgroundText(backgroundValue) {
            const background = this.backgroundOptions.find((background) => background.value === backgroundValue);
            return background ? background.text : "keine Angabe";
        },
        getSchoolTypeText(schoolTypeValue) {
            const schoolType = this.schoolTypeOptions.find((schoolType) => schoolType.value === schoolTypeValue);
            return schoolType ? schoolType.text : "";
        },
        signOut: async function () {
            await ThirdPartyEmailPassword.signOut();
            window.location.assign("/auth");
        },
    },
    computed: {
        ...mapState([
            'userGroups',
            'ageOptions',
            'studyStatusOptions',
            'backgroundOptions',
            'schoolTypeOptions'
        ]),
    },
    data() {
        return {
            editMode: false, // Initially, not in edit mode
            user: {
                user_group: '', // Initialize with current user's values
                age: '',
                study_status: '',
                background: '',
                school_type: '',
                email_opt_in: false,
            },
        };
    },
};
</script>
