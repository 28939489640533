<script>

export default {
    name: "DescriptionCard",
    props: {
        title: String,
        description: String,
        showButton: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['rewatch-intro']
}
</script>

<template>
    <div class="card mb-5">
        <div class="card-content">
            <p class="title is-size-4 is-size-5-mobile mb-3">{{ title }}</p>

            <p class="mb-4 is-size-6">
                <slot></slot>
            </p>
            <button v-if="showButton" class="button is-light"
                    @click="this.$emit('rewatch-intro')">
                Einführungsvideo erneut ansehen
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
