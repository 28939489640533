<script>
export default {
    name: 'ModuleStartButton',
    props: {
        previousModuleProgress: Number,
        progress: Number,
        path: String,
        link: String,
        isExercise: Boolean,
        resultsButtonText: {
            type: String,
            default: "Ergebnisse ansehen"
        },
    }
}
</script>

<template>
    <div v-if="previousModuleProgress < 1">
        <button class="button is-light is-mobile-small" disabled>Nicht freigeschaltet</button>
    </div>
    <div v-else>
        <div v-if="progress === -1">
            <a class="button is-loading is-mobile-small">Ergebnisse ansehen</a>
        </div>
        <div v-if="progress === 0">
            <a class="button is-info is-mobile-small" :href="link"><span v-if="isExercise">Übung&nbsp;</span><span v-else>Modul&nbsp;</span>starten</a>
        </div>
        <div v-else-if="progress < 1 && progress > 0">
            <a class="button is-mobile-small" :href="link"><span v-if="isExercise">Übung&nbsp;</span><span v-else>Modul&nbsp;</span>fortführen</a>
        </div>
        <div v-else-if="progress === 1">
            <a class="button is-primary is-outlined is-mobile-small" :href="link">{{ resultsButtonText }}</a>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
