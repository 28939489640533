<script>
import JobCard from "@/components/JobCard.vue";
import {jobList} from "@/assets/job-list";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import axios from "axios";
import HoveringSection from "@/components/BasicUI/HoveringSection.vue";
import LoadingSpinner from "@/components/BasicUI/LoadingSpinner.vue";

export default {
    name: 'Wishlist',
    mounted() {
        axios.get('/api/v1/workquizresults/').then((response) => {
            this.selected_jobs = response.data[0].selected_jobs
            this.generateJobList()
        });
    },
    computed: {},
    methods: {
        generateJobList() {
            let job_list = jobList.filter((job) => this.selected_jobs.includes(job.id))
            this.job_list = job_list.map((job) => ({...job, selected: true}))
            this.loading = false
        },
        unselectJob(id) {
            this.selected_jobs = this.selected_jobs.filter((job) => job !== id)
            axios.post('/api/v1/workquizresults/', {
                selected_jobs: this.selected_jobs
            }).then((response) => {
                console.log(response)
            })
            this.generateJobList()
        }
    },
    components: {LoadingSpinner, HoveringSection, ExerciseIntro, TopNavbar, JobCard},
    data() {
        return {
            loading: true,
            selected_jobs: [],
            job_list: [],
            showWishlistRemove: false,
        }
    },
}
</script>

<template>
    <TopNavbar title="Wunschliste" back-link="/futurework"/>
    <div class="app-container">
        <div class="app-content-container">
            <div class="notification is-primary is-light" v-if="showWishlistRemove">
                <button class="delete" @click="showWishlistRemove = false"></button>
                Von der Wunschliste entfernt
            </div>
            <LoadingSpinner v-if="loading" />
            <div v-else>
                <div v-if="job_list.length === 0">
                    <p>Gerade gibt es keine Jobs auf deiner Liste. Füge welche über die Bibliothek der Berufsfelder
                        hinzu!</p>
                    <button class="button is-info mt-4 is-fullwidth" @click="this.$router.push('/futurework/overview')">
                        Zur
                        Bibliothek
                    </button>
                </div>

                <div class="mb-3" v-for="job in job_list" :key="job.id">
                    <JobCard
                            :name="job.name"
                            :description="job.description"
                            :subgroups="job.subgroups"
                            :image="job.image"
                            :balance="job.balance"
                            :income="job.income"
                            :stability="job.stability"
                            :independence="job.independence"
                            :data="job.data"
                            :creativity="job.creativity"
                            :craftsmanship="job.craftsmanship"
                            :people="job.people"
                            :university="job.university"
                            :internationality="job.internationality"
                            :id="job.id"
                            :selected="job.selected"
                            @job-unselected="unselectJob($event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.notification {
    position: fixed;
    top: 200px;
}

.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}


.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
</style>
