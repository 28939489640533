<script>
import {Stories} from "vue-insta-stories";
import {max, shuffle} from "lodash";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import HoveringSection from "@/components/BasicUI/HoveringSection.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import CalendarExerciseCard from "@/components/ModuleOverviewCards/futureme/CalendarExerciseCard.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";
import VueApexCharts from "vue3-apexcharts";
import axios from 'axios'


export default {
    components: {
        DescriptionCard,
        ExerciseIntro,
        CalendarExerciseCard,
        Modal,
        HoveringSection,
        TopNavbar,
        Stories,
        VueApexCharts
    },
    name: 'KalenderKick',
    mounted() {
        this.fetchActivities();
    },
    data() {
        return {
            stage: -1,
            revisited: false,
            activity_count: 2,
            error_msg: '',
            all_activities: [],
            activities: [
                {id: 0, name: '', rating: -1, time: 0},
                {id: 1, name: '', rating: -1, time: 0},
                {id: 2, name: '', rating: -1, time: 0},
                {id: 3, name: '', rating: -1, time: 0},
                {id: 4, name: '', rating: -1, time: 0},
            ],
            show_modal: false,
            green_activities: [],
            yellow_activities: [],
            red_activities: []
        }
    },
    methods: {
        async fetchActivities() {
            const apiBaseUrl = '/api/v1/';
            try {
                const response = await axios.get(`${apiBaseUrl}activities/`);
                const activities = response.data;

                this.green_activities = activities.filter(activity => activity.category === 'green');
                this.yellow_activities = activities.filter(activity => activity.category === 'yellow');
                this.red_activities = activities.filter(activity => activity.category === 'red');

                if (this.green_activities.length || this.yellow_activities.length || this.red_activities.length) {
                    this.stage = 3;
                } else {
                    this.stage = 0;
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async sendDataToAPI(data, category) {
            const apiBaseUrl = '/api/v1/';
            for (const activity of data) {
                const payload = {
                    ...activity,
                    category: category,
                };
                delete payload.id; // Remove the id as it's not needed

                try {
                    const response = await axios.post(`${apiBaseUrl}activities/`, payload);
                    console.log('Data sent successfully:', response.data);
                } catch (error) {
                    console.error('Error sending data:', error);
                }
            }
        },
        sendAllData() {
            this.sendDataToAPI(this.green_activities, 'green');
            this.sendDataToAPI(this.yellow_activities, 'yellow');
            this.sendDataToAPI(this.red_activities, 'red');
        },
        nextStage() {
            if (this.stage === 1) {
                let filtered_activities = this.activities.filter((activity) => activity.name !== '')

                if (filtered_activities.length < 3) {
                    this.error_msg = 'Bitte füge mindestens 3 Aktivitäten hinzu.'
                    return;
                }

                let no_time_selected_activities = filtered_activities.filter((activity) => activity.time == 0)
                if (no_time_selected_activities.length > 0) {
                    this.error_msg = 'Bitte gib für alle Aktivitäten eine Zeitschätzung ab (rechts).'
                    return;
                }

                this.activities = filtered_activities
            } else if (this.stage === 2) {
                this.sendAllData();
            }
            this.stage++;
        },
        restartExercise() {
            this.stage = 1
            this.selected_images = []
            this.favourite_images = []
            this.image = 0
        },
        removeActivity(id) {
            this.activities = this.activities.filter((activity) => activity.id !== id)
        },
        addActivity() {
            this.activities.push({id: Math.floor(Math.random() * Date.now()), name: '', rating: -1, time: 0})
        },
        rateActivity(id, rating) {
            let activity = this.activities.filter((activity) => activity.id === id)[0]
            activity.rating = parseInt(rating)

            switch (activity.rating) {
                case 2:
                    this.green_activities.push(activity)
                    break;
                case 1:
                    this.yellow_activities.push(activity)
                    break;
                case 0:
                    this.red_activities.push(activity)
                    break;
            }

        }
    }
    ,
    computed: {
        chartData() {
            let fillColors = [];

            this.green_activities.forEach((activity) => {
                this.all_activities.push({name: activity.name, data: [activity.time, 0, 0]});
                // Add a green color for each green activity
                fillColors.push('#6DC291');
            });

            this.yellow_activities.forEach((activity) => {
                this.all_activities.push({name: activity.name, data: [0, activity.time, 0]});

                // Add a yellow color for each yellow activity
                fillColors.push('#fff472');
            });

            this.red_activities.forEach((activity) => {
                this.all_activities.push({name: activity.name, data: [0, 0, activity.time]});

                // Add a red color for each red activity
                fillColors.push('#ff3860');
            });

            return {
                series: this.all_activities,
                fillColors: fillColors
            };
        }
        ,
        chartOptions() {
            return {
                yaxis: {
                    title: {
                        text: 'Stunden',  // Add your desired title here
                        style: {
                            color: '#000',  // Optional: Set the title color to black
                            fontSize: '16px'  // Optional: Set the font size for the title
                        }
                    },
                    // ...any other y-axis options you want to include
                },
                dataLabels: {
                    enabled: true,  // This will enable the data labels on the bars
                    style: {
                        colors: ['#000']  // This will set the color to black
                    },
                    formatter: function (val, opts) {
                        // Assuming all_activities is accessible here
                        const activity = this.all_activities[opts.seriesIndex];
                        if (activity) {
                            const name = activity.name;
                            return name.length > 8 ? name.substring(0, 8) + '...' : name;
                        }
                        return val;
                    }.bind(this)
                },
                chart: {
                    height: 400,
                    width: 400,
                    id: "kalenderauswertung",
                    toolbar: {
                        show: false  // This will remove the download option
                    },
                    foreColor: '#000',
                    stacked: true,
                },
                xaxis: {
                    categories: ['Wie im Flug', 'weder noch', 'Hoffentlich bald vorbei'],
                    labels: {
                        style: {
                            colors: ['#000'],
                            fontSize: '12px',  // Adjust this value as desired
                        }
                    }
                },
                fill: {
                    colors: this.chartData.fillColors,  // Use the dynamically generated fill colors
                },
                legend: {
                    show: false  // This will hide the legend
                },
            }
        }
        ,
        compareTime(a, b) {
            return parseInt(a.time) - parseInt(b.time)
        }
        ,
        series() {
            return this.chartData.series
        }
        ,
        buttonClass() {
            return this.has_selected_all_favourites ? 'is-primary' : 'hidden';
        }
        ,
        currentActivity() {
            let current = shuffle(this.activities.filter((activity) => activity.rating === -1)).slice(-1)[0]
            if (current) {
                return current
            } else {
                this.show_modal = true
                return {id: -2, name: '', rating: -1, time: 0}
            }
        }
        ,
    }
}
</script>

<template>
    <TopNavbar title="Mein perfekter Tag"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage === 0">
                <ExerciseIntro module-name="calendar" @intro-finished="nextStage"/>
                <button class="button is-fullwidth is-primary" @click="nextStage">Übung starten</button>
            </div>
            <div v-else-if="stage == 1">
                <DescriptionCard>
                    <b>Wirf einen Blick auf deinen Kalender der letzten vier Wochen.</b> Welche Aktivitäten
                    findest du dort und wieviel Zeit hast du pro Woche insgesamt (ungefähr) mit ihnen verbracht? Du
                    kannst so viele Aktivitäten hinzufügen, wie du möchtest.
                </DescriptionCard>

                <div v-if="error_msg" class="notification is-danger">
                    <button class="delete" @click="this.error_msg = ''"></button>
                    {{ error_msg }}
                </div>

                <div class="activity-container">
                    <div v-for="(activity, index) in activities">
                        <div class="field is-grouped mb-2">
                            <p class="control">
                                <button class="button delete-button mb-5" @click="removeActivity(activity.id)">
                                    <font-awesome-icon :icon="['fas', 'minus']"/>
                                </button>
                            </p>
                            <p class="control is-expanded">
                                <input v-model="activity.name" class="input" type="text"
                                       placeholder="Aktivität eintragen">
                            </p>
                            <p class="control">
                      <span class="select">
                        <select v-model="activity.time">
                            <option value="0">...</option>
                            <option value="4">0-4 h</option>
                            <option value="10">5-10 h</option>
                            <option value="15">11-15 h</option>
                            <option value="20">16-20 h</option>
                            <option value="30">21-30 h</option>
                            <option value="35">31+ h</option>
                        </select>
                      </span>
                            </p>
                        </div>
                    </div>
                    <button class="button is-info is-small" @click="addActivity">
                        <font-awesome-icon :icon="['fas', 'plus']"/>
                        &nbsp;Aktivität hinzufügen
                    </button>
                </div>
                <div class="buttons mt-4">
                    <button class="button is-primary is-fullwidth" @click="nextStage">Nächster Schritt</button>
                </div>
            </div>
            <div v-else-if="stage == 2">
                <DescriptionCard>
                    <b>Erinner dich jetzt daran, wie sich die Aktivität die letzten Male angefühlt hat.</b> Ist die Zeit
                    wie im
                    Flug vergangen oder hast du dir gewünscht, dass die Sache möglichst bald vorüber ist?
                    Falls du keine starken Gefühle mit der Aktivität verbindest, gibt es noch die "etwas dazwischen"
                    Option.
                </DescriptionCard>
                <div class="has-text-centered">
                    <p class="is-size-3">{{ currentActivity.name }}</p>
                    <p class="is-size-4 mb-4">... fühlt sich an wie:</p>
                    <div class="buttons is-centered">
                        <button class="button is-success is-fullwidth" @click="rateActivity(currentActivity.id, 2)">
                            „Zeit vergeht wie im Flug“
                        </button>
                        <button class="button is-yellow is-fullwidth" @click="rateActivity(currentActivity.id, 1)">
                            Etwas dazwischen
                        </button>
                        <button class="button is-danger is-fullwidth" @click="rateActivity(currentActivity.id, 0)">
                            „Hoffentlich ist das bald vorbei“
                        </button>

                    </div>
                </div>
                <Modal title="Alle Aktivitäten bewertet!" subtitle="Sieh dir jetzt die Auswertung an."
                       :is-active="show_modal"
                       @click-button="nextStage"/>
            </div>
            <div v-else-if="stage === 3">
                <DescriptionCard>
                    <p><b>Der Job, die Uni, andere Verpflichtungen: nicht alle täglichen Aufgaben sind
                        unbedingt immer total
                        erfüllend und spannend, aber oft trotzdem notwendig.</b> Es ist unrealistisch, davon auszugehen,
                        dass
                        dir alles in deinem Leben immer Spaß macht. Trotzdem kannst du viel an deiner persönlichen
                        Zufriedenheit und sogar an deinem Erfolg verbessern, indem du dir klar vor Augen führst, welche
                        Aktivitäten dir Freude machen und welche nicht. Nämlich, indem du kluge Entscheidungen triffst,
                        um
                        deinen Anteil an positiven Aktivitäten möglichst hoch zu halten und unnötige negative
                        Aktivitäten
                        auf ein Minimum zu begrenzen. Einfache Faustregel: <strong>Der Anteil an positiven Aktivitäten
                            sollte
                            deutlich höher sein als der Anteil an Aktivitäten, die sich lähmend, langweilig oder
                            unangenehm
                            anfühlen.</strong></p>
                </DescriptionCard>

                <p class="has-text-centered">
                    <h2 class="is-size-4">Übersicht der Aktivitäten</h2>
                    <VueApexCharts :series="series" :options="chartOptions" width="100%" type="bar"/>
                </p>

                <a href="/futureme" class="button mt-4 is-primary center-block is-fullwidth">Zurück zur Übersicht</a>
                <button @click="restartExercise" class="button mt-4 center-block is-fullwidth" v-if="revisited">Übung
                    neu
                    starten
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.delete-button {
    height: 25px;
    width: 25px;
    margin-top: 12px;
}

.activity-container {
    max-height: 35vh;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 30px 30px 30px 30px;
    border-radius: 20px;
}

.activites-rater {
    margin-left: auto;
    margin-right: auto;
}
</style>
