export const questions = [
    {
        text: "Was ist laut OpenAI-Gründer Sam Altman das Problem an der aktuellen Diskussion zum Thema Künstliche Intelligenz (KI)?",
        answers: [
            {
                text: "Für die etwas weitere Zukunft (10 Jahre) wird KI weitreichendere Folgen haben, als wir das momentan absehen können.",
                correct: true
            },
            {text: "Die Auswirkungen von KI auf die nächsten 2 Jahre werden aktuell unterschätzt.", correct: false},
            {
                text: "Weniger Leute sollten KI benutzen, da es nicht besonders nützlich für die Menschheit ist.",
                correct: false
            },
            {
                text: "Wenn wir über KI sprechen, reden wir aktuell fast nur über ChatGPT und vernachlässigen damit andere Programme.",
                correct: false
            }
        ],
        explanation: "Sam Altman weist darauf hin, dass die langfristigen Auswirkungen von KI, besonders in einem Zeitraum von etwa 10 Jahren, weitreichender sein werden, als wir momentan annehmen."
    },
    {
        text: "Wenn du nicht selbst KI-Entwickler:in bist, was ist dann für dich der wichtigste Skill bei der Arbeit mit KI?",
        answers: [
            {text: "Ergebnisse von KI kritisch hinterfragen können.", correct: true},
            {text: "Alle deine Tätigkeiten an KI auslagern.", correct: false},
            {text: "Sehr gut programmieren können.", correct: false},
            {
                text: "Bis ins kleinste technische Detail verstehen, wie der Algorithmus hinter der KI funktioniert.",
                correct: false
            }
        ],
        explanation: "Für die effektive Arbeit mit KI ist es wichtig, die Ergebnisse kritisch zu hinterfragen, da sie falsch oder erfunden sein können. Nicht alle Tätigkeiten sollten daher ohne weiteres an KI ausgelagert werden."
    },
    {
        text: "Welche Zuordnung stimmt?",
        answers: [
            {text: "Wissens- und Innovationsarbeit – Briefträger*in", correct: false},
            {text: "Interaktionsarbeit – Psychotherapeut*in", correct: true},
            {text: "Routine-Arbeit – momentan nicht durch KI ersetzbar", correct: false},
            {text: "Interaktionsarbeit – kann durch KI übernommen werden", correct: false}
        ],
        explanation: "Psychotherapeut*innen führen komplexe Interaktionsarbeit durch, die empathisches Verständnis und tiefgehende menschliche Interaktion erfordert, was aktuell nicht vollständig durch KI ersetzt werden kann. Briefträger*innen verrichten zwar auch Interaktionsarbeit, jedoch gehört ihre Haupttätigkeit eher zur Routinearbeit. Die Behauptung, dass Routinearbeit aktuell nicht durch KI ersetzbar ist, ist unzutreffend, da gerade Routineaufgaben oft automatisierbar sind."
    },/*
    {
        text: "Stell dir eine „neugeborene“ Künstliche Intelligenz vor, die digitale Bilder „malen“ soll. Was stimmt dazu NICHT?",
        answers: [
            {
                text: "Die KI muss als erstes viele Bilder „gezeigt bekommen“, um zu lernen, was Kunst und Bilder überhaupt ausmacht.",
                correct: false
            },
            {text: "Die KI sucht innerhalb dieser Bilder nach Mustern und wiederkehrenden Elementen.", correct: false},
            {
                text: "Die KI erstellt dann ein neues Bild, das aber insgesamt eine Kombination der Muster und Elemente aus ihrer „Datenbank“ ist.",
                correct: false
            },
            {
                text: "Die KI kann Gefühle und Emotionen in ihre Bilder einfließen lassen, um sie noch menschlicher wirken zu lassen.",
                correct: true
            }
        ],
        explanation: "Generative KI kann keine eigenen Gefühle oder Emotionen besitzen oder diese in ihre Kunstwerke einbringen; sie simuliert vielmehr menschliche Kunstfertigkeit durch das Erlernen und Nachahmen bestehender Muster."
    },
    {
        text: "Was stimmt zu analytischer und generativer KI?",
        answers: [
            {text: "Analytische KI ist schon länger breit verfügbar als generative KI.", correct: true},
            {text: "Die Google-Maps-Verkehrsvorhersage ist ein Beispiel für generative KI.", correct: false},
            {text: "ChatGPT ist ein Beispiel für analytische KI.", correct: false},
            {
                text: "Um ein realistisches Video zu generieren, wird hauptsächlich analytische KI eingesetzt.",
                correct: false
            }
        ],
        explanation: "Analytische KI, die sich auf die Analyse und Verarbeitung bestehender Informationen konzentriert, ist länger bekannt und verbreitet als generative KI, die neue Inhalte erzeugt."
    },
    {
        text: "Welche Art von Arbeit kann eine KI am leichtesten übernehmen?",
        answers: [
            {text: "Wissens- & Innovationsarbeit (z.B. Neues Medikament erforschen)", correct: false},
            {text: "Interaktionsarbeit (z.B. Kunden beraten)", correct: false},
            {text: "Routine-Arbeit (z.B. Daten in Excel übertragen)", correct: true},
        ],
        explanation: "Routine-Arbeit folgt festen Regeln und ist dabei für KI am leichtesten zu durchschauen."
    },
    {
        text: "Welche Fähigkeiten von Menschen sind für KI schwer zu ersetzen?",
        answers: [
            {text: "In andere Menschen hineinversetzen", correct: true},
            {text: "Texte zusammenfassen", correct: false},
            {text: "Teamwork", correct: true},
            {text: "Mathematische Probleme lösen", correct: false}
        ],
        explanation: "Fähigkeiten wie Empathie und Teamwork, die das tiefe Verständnis menschlicher Emotionen und sozialer Interaktionen erfordern, sind für KIs schwer zu ersetzen. Diese Fähigkeiten bauen auf schwer zu erkennenden, oft unbewussten menschlichen Verhaltensweisen auf, die für KIs schwer nachzuvollziehen sind."
    },
    {
        text: "Welche Vorteile haben Menschen, die bei ihrer Arbeit KI verwenden?",
        answers: [
            {text: "Sie können mehr Informationen in kürzerer Zeit verstehen.", correct: true},
            {text: "Sie brauchen kein Studium oder eine Ausbildung mehr für ihren Job.", correct: false},
            {text: "Sie können auf Zusammenarbeit mit Menschen verzichten.", correct: false},
            {text: "Sie sind mit ihrer Arbeit schneller fertig.", correct: true}
        ],
        explanation: "Durch die Nutzung von KI können Menschen große Mengen an Informationen schneller verarbeiten und sind oft effizienter in ihrer Arbeit. Dies führt zu einer deutlichen Zeitersparnis und einer Steigerung der Produktivität, wobei fundierte Ausbildung und menschliche Zusammenarbeit weiterhin unverzichtbar bleiben."
    },
    {
        text: "Welche dieser Aufgaben können derzeit schon gut von KI gelöst werden?",
        answers: [
            {text: "Einen Aufsatz zu einem Thema schreiben", correct: true},
            {text: "Realistisch aussehende Bilder zeichnen", correct: true},
            {text: "Untertitel zu einem Video erstellen", correct: true},
            {text: "Einem Patienten erklären, dass er eine schwere Krankheit hat", correct: false}
        ],
        explanation: "Aktuelle KI-Systeme sind in der Lage, kreative und analytische Aufgaben wie das Schreiben von Aufsätzen, das Zeichnen realistischer Bilder und das Erstellen von Untertiteln effizient zu bewältigen. Jedoch fehlt es ihnen an der notwendigen Empathie und den Feinheiten menschlicher Kommunikation, die für sensible Gespräche, wie die Übermittlung schwerwiegender medizinischer Nachrichten an Patienten, erforderlich sind."
    }*/

];
