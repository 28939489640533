<script>
import axios, {all} from "axios";
import {Stories} from "vue-insta-stories";
import ValueSlider from "@/components/ValueSlider.vue";
import {slider_values, company_values, balance_values} from "@/assets/workvalue-list";
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";
import RatingButtonArray from "@/components/RatingButtonArray.vue";
import {shuffle} from "lodash";
import JobCard from "@/components/JobCard.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    components: {
        DescriptionCard,
        TopNavbar, ExerciseIntro, JobCard, RatingButtonArray, ProgressBar, ValueSlider, Stories
    },
    name: 'WorkQuiz',
    mounted() {
        axios.get('/api/v1/workquizresults/').then(result => {
            if (result.data.length > 0) {
                this.revisited = true
                this.stage = 6
                this.balance = result.data[0].balance
                this.craftsmanship = result.data[0].craftsmanship
                this.creativity = result.data[0].creativity
                this.internationality = result.data[0].internationality
                this.income = result.data[0].income
                this.data = result.data[0].data
                this.independence = result.data[0].independence
                this.people = result.data[0].people
            }
        })

        this.slider_values = slider_values.map((statement, index) => ({
            id: index,
            name: statement.name,
            rating: 5,
            category: statement.value
        }))
        let all_company_values = company_values.map((statement, index) => ({id: index, name: statement, rating: 5}))
        this.company_values = shuffle(all_company_values).slice(0, this.max_company_values)
        this.balance_values = balance_values.map((statement, index) => ({
            id: index,
            left: statement.name.split(' | ')[0],
            right: statement.name.split(' | ')[1],
            category: statement.value,
            rating: 5
        }))
    },
    computed: {
        totalQuestions() {
            return this.slider_values.length + this.company_values.length + this.balance_values.length
        },
        currentQuestion() {
            return this.company_values[this.current_question_index]
        },
        currentSliderQuestion() {
            return this.balance_values[this.current_slider_index]
        }
    },
    data() {
        return {
            slider_values: [],
            allValues: [],
            company_values: [],
            max_company_values: 8,
            balance_values: [],
            stage: 0,
            revisited: false,
            min: 0,
            max: 10,
            step: 0.1,
            current_progress: 0,
            current_question_index: 0,
            current_slider_index: 0,
            balance: 0,
            craftsmanship: 0,
            internationality: 0,
            independence: 0,
            income: 0,
            creativity: 0,
            data: 0,
            people: 0,
        }
    },
    methods: {
        getCategoryValues(array, category) {
            return array.filter((value) => value.category === category)
        },
        getRating(array) {
            return array.reduce((sum, item) => sum + parseFloat(item.rating), 0) / array.length
        },
        roundToDecimals(value, decimals) {
            const factor = Math.pow(10, decimals);
            return Math.round(value * factor) / factor;
        },
        calculateResult() {
            const all_values = this.slider_values.concat(this.company_values).concat(this.balance_values)
            this.all_values = all_values
            this.balance = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'balance')), 2)
            this.income = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'income')), 2)
            this.internationality = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'internationality')), 2)
            this.creativity = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'creativity')), 2) / 2
            this.data = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'data')) / 2, 2)
            this.craftsmanship = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'craftsmanship')), 2) / 2
            this.people = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'people')), 2) / 2
            this.independence = this.roundToDecimals(this.getRating(this.getCategoryValues(all_values, 'independence')), 2)

            axios.post('/api/v1/workquizresults/', {
                balance: this.balance,
                income: this.income,
                internationality: this.internationality,
                creativity: this.creativity,
                data: this.data,
                craftsmanship: this.craftsmanship,
                people: this.people,
                independence: this.independence,
            }).then(result => {
                console.log(result)
            })
        },
        nextStage() {
            if (this.stage === 1) {
                this.current_progress += 4
            } else if (this.stage === 2) {
                this.current_progress += 3
            } else if (this.stage === 3) {
                this.current_progress++
            } else if (this.stage === 5) {
                this.calculateResult();
            }
            this.stage++;
        },
        updateRating({rating, category}) {
            this.company_values.filter((entry) => entry.id === category)[0].rating = rating

            if (this.current_question_index < this.company_values.length - 1) {
                this.current_question_index++;
            } else {
                this.nextStage()
            }

            this.current_progress++;
        },
        nextSliderValue() {
            if (this.current_slider_index < this.balance_values.length - 1) {
                this.current_slider_index++;
            } else {
                this.nextStage()
            }
            this.current_progress++;
        },
        submitFulfillment() {
            const all_values = this.base_values.concat(this.core_values)

            all_values.forEach((value) => {
                const data = {
                    value_id: value.value_id,
                    fulfillment: value.fulfillment
                }
                axios.post("/api/v1/valueratings/", data).then(result => {
                    console.log(result)
                })
            });

        },
        restartExercise() {
            this.stage = 1
            this.selected_images = []
            this.favourite_images = []
            this.image = 0
        }
    },
}
</script>

<template>
    <TopNavbar title="Work Quiz"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage !== 0 && stage !== 6">
                <ProgressBar classname="is-info mb-5" :progress="current_progress*100/totalQuestions"/>
            </div>
            <div v-if="stage === 0">
                <ExerciseIntro module-name="workquiz" @intro-finished="nextStage"/>
                <button class="button is-primary is-fullwidth" @click="nextStage">Los geht's</button>
            </div>
            <div v-else-if="stage == 1">
                <p class="has-text-centered title">So stelle ich mir meine Arbeit vor</p>
                <div class="mb-5" v-for="(value, index) in slider_values.slice(0,4)">
                    <ValueSlider
                            :is-solid-background="true"
                            :title="slider_values[index].name"
                            v-model="slider_values[index].rating"
                            :min="min"
                            :max="max"
                            :step="step"
                            :show-text-legend="true"
                    />
                </div>
                <button class="button is-fullwidth is-primary" @click="nextStage">Weiter</button>
            </div>
            <div v-else-if="stage == 2">
                <p class="has-text-centered title">So stelle ich mir meine Arbeit vor</p>
                <div class="mb-5" v-for="(value, index) in slider_values.slice(4,7)">
                    <ValueSlider
                            :is-solid-background="true"

                            :title="slider_values[index+4].name"
                            v-model="slider_values[index+4].rating"
                            :min="min"
                            :max="max"
                            :step="step"
                            :show-text-legend="true"
                    />
                </div>
                <button class="button is-fullwidth is-primary" @click="nextStage">Weiter</button>
            </div>
            <div v-else-if="stage == 3">
                <p class="has-text-centered title">Wie wichtig ist dir Geld?</p>
                <ValueSlider
                        :is-solid-background="true"

                        :title="slider_values[7].name"
                        v-model="slider_values[7].rating"
                        :min="min"
                        :max="max"
                        :step="step"
                        middle-legend-text=""
                        :show-text-legend="true"
                />
                <DescriptionCard>
                    <ul>
                        <li><b>Ganz links</b>: Gerade genug Geld, um über die Runden zu kommen und alltägliche Bedürfnisse
                            (wie
                            z.B. Essen, Miete, normale Kleidung) zu befriedigen.
                        </li>
                        <li><b>Ganz rechts:</b> Genug Geld, dass du dir regelmäßig teure Luxusgegenstände leisten (z.B.
                            Autos,
                            Uhren), täglich Essen gehen kannst, ...
                        </li>
                    </ul>
                </DescriptionCard>
                <button class="button is-fullwidth is-primary" @click="nextStage">Weiter</button>
            </div>
            <div v-else-if="stage == 4">
                <div class="question-wrapper">
                    <p class="title is-5 has-text-weight-semibold">{{ currentQuestion.name }}</p>
                </div>
                <RatingButtonArray :category="currentQuestion.id"
                                   @rated="updateRating"
                />
            </div>
            <div v-else-if="stage == 5">
                <p class="has-text-centered title">Entweder – oder?</p>
                <div class="flexbox mb-5">
                    <div class="flex-left">{{ currentSliderQuestion.left }}</div>
                    <div class="flex-right">{{ currentSliderQuestion.right }}</div>
                </div>
                <ValueSlider
                        :is-solid-background="true"
                        title=""
                        v-model="currentSliderQuestion.rating"
                        :min="min"
                        :max="max"
                        :step="step"
                        left-legend-text=""
                        right-legend-text=""
                        middle-legend-text=""
                        :show-text-legend="true"
                />
                <button @click="nextSliderValue" class="button is-primary is-fullwidth">Weiter</button>
            </div>
            <div v-else-if="stage == 6">
                <h4 class="title has-text-centered">Auswertung</h4>
                <DescriptionCard title="Auswertung">
                    Hier siehst du, welche Ergebnisse du in unserem Fragebogen für einige wichtige Merkmale von Jobs
                    bekommen hast. Du kannst sie dir immer wieder anschauen und anhand dessen in unserer Datenbank nach
                    geeigneten Berufsfeldern suchen. Denk bitte dran: Es ist quasi unmöglich, den perfekten Job zu
                    finden, der in allen Bereichen maximal zu deinen Vorstellungen passt. Wichtig für dich ist, dass du
                    dir überlegst, was dir wirklich wichtig ist und wo du auch Kompromisse machen würdest.
                </DescriptionCard>
                <JobCard
                        :is-evaluation-mode="true"
                        :internationality="internationality"
                        :balance="balance"
                        :craftsmanship="craftsmanship"
                        :income="income"
                        :creativity="creativity"
                        :independence="independence"
                        :people="people"
                        :data="data"
                />

                <a href="/futurework" class="button mt-4 is-primary center-block is-fullwidth">Zurück zur Übersicht</a>
                <button @click="restartExercise" class="button mt-4 center-block is-fullwidth" v-if="revisited">Übung
                    neu
                    starten
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.question-wrapper {
    min-height: 80px;
}

.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-left, .flex-right {
    max-width: 35%;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
}

</style>
