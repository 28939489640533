<script>
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import Session from "supertokens-web-js/recipe/session";
import {defineComponent} from "vue";
import {sendPasswordResetEmail} from "supertokens-web-js/lib/build/recipe/emailpassword";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import HeaderLogo from "@/components/BasicUI/HeaderLogo.vue";

export default defineComponent({
    components: {HeaderLogo, TopNavbar},
    data() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        return {
            // the email property is used for the enter email screen
            email: "",
            error: false,
            errorMessage: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
            didSubmit: false,
            // if tokenPresent is true, it means that the user has clicked on the
            // reset password link.
            tokenPresent: token !== null,
            password: "",
        };
    },
    mounted() {
        // if there is an "error" query param on this page, it means that
        // social login has failed for some reason. See the AuthCallbackView.vue file
        // for more context on this
        const params = new URLSearchParams(window.location.search);

        if (params.has("error")) {
            this.errorMessage = "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.";
            this.error = true;
        }

        // this redirects the user to the HomeView.vue component if a session
        // already exists.
        this.checkForSession();
    },

    methods: {
        onSubmitClicked: async function () {
            if (this.tokenPresent) {
                // we try and change the user's password now by consuming the token
                try {
                    const response = await ThirdPartyEmailPassword.submitNewPassword({
                        formFields: [
                            {
                                id: "password",
                                value: this.password,
                            },
                        ],
                    });

                    if (response.status === "FIELD_ERROR") {
                        // this indicates that the password entered by the user
                        // did not match the backend password validation logic.
                        throw new Error(response.formFields[0].error);
                    } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
                        // the password reset token was consumed already, or has expired.
                        // in this case, the user should go back to the login screen or the
                        // enter email screen
                        this.tokenPresent = null
                        throw new Error("Dein Zurücksetzungs-Link ist abgelaufen. Bitte fordere einen neuen Link an, indem du deine Email eingibst.");
                    }


                    // password reset successful.
                    window.location.assign("/auth");
                } catch (e) {
                    this.errorMessage = e.message;
                    this.error = true;
                }
            } else {
                // the user has entered an email for whom the password reset link
                // will be sent.
                try {
                    const response = await ThirdPartyEmailPassword.sendPasswordResetEmail({
                        formFields: [
                            {
                                id: "email",
                                value: this.email,
                            },
                        ],
                    });

                    if (response.status !== "OK") {
                        // this means that the email validation logic failed.
                        throw new Error(response.formFields[0].error);
                    }

                    // a password reset email was sent successfully.

                    if (this.didSubmit !== true) {
                        // we change the UI to show that the email has been sent
                        this.didSubmit = true;
                    }
                } catch (e) {
                    this.errorMessage = e.message;
                    this.error = true;
                }
            }
        },
        resetPassword: async function sendEmailClicked() {
            try {
                let response = await sendPasswordResetEmail({
                    formFields: [{
                        id: "email",
                        value: this.email
                    }]
                });

                if (response.status === "FIELD_ERROR") {
                    // one of the input formFields failed validaiton
                    response.formFields.forEach(formField => {
                        if (formField.id === "email") {
                            // Email validation failed (for example incorrect email syntax).
                            console.error(formField.error)
                        }
                    })
                } else {
                    // reset password email sent.
                    this.password_reset_requested = true
                }
            } catch (err) {
                if (err.isSuperTokensGeneralError === true) {
                    // this may be a custom error message sent from the API by you.
                } else {
                    console.error(err)
                }
            }
        },
        validateEmail(email) {
            return email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        deleteErrors() {
            this.error = false;
        },
        goToSignIn() {
            this.$router.push('/auth')
        },
        onSubmitPressed: function (e) {
            e.preventDefault();
            // we reset the error states in case the user has fixed the input errors
            this.error = false;
            this.emailError = "";
            this.passwordError = "";
            this.privacyError = "";

            this.resetPassword()

        },
        checkForSession: async function () {
            if (await Session.doesSessionExist()) {
                // since a session already exists, we redirect the user to the HomeView.vue component
                window.location.assign("/");
            }
        },
    },
});
</script>

<template>
    <div class="app-container-small">
        <div class="app-content-container">
            <HeaderLogo/>
            <div v-if="tokenPresent" class="form-content-container">
                <div class="title is-text-centered">Neues Passwort festlegen</div>

                <div v-if="error" class="notification is-danger">
                    <button class="delete" @click="deleteErrors"></button>
                    {{ errorMessage }}
                </div>

                <div v-if="!didSubmit">
                    <p class="mb-4">Bitte lege ein neues Passwort für deinen Account fest.</p>

                    <div class="field" v-bind:class="passwordError ? 'error' : ''">
                        <label class="label">Neues Passwort</label>
                        <div class="control" v-bind:class="passwordError ? 'error' : ''">
                            <input
                                    autocomplete="current-password"
                                    class="input is-fullwidth"
                                    type="password"
                                    name="password"
                                    placeholder="Passwort"
                                    v-model="password"
                            />
                        </div>
                    </div>

                    <div class="button-container">
                        <button class="button is-primary is-fullwidth" v-on:click="onSubmitClicked">Passwort ändern
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="form-content-container">
                <div class="title is-text-centered">Passwort zurücksetzen</div>

                <div v-if="error" class="notification is-danger">
                    <button class="delete" @click="deleteErrors"></button>
                    {{ errorMessage }}
                </div>

                <div v-if="!didSubmit">
                    <p class="mb-4">Gib die Email-Adresse ein, mit der du dich registriert hast.</p>

                    <div class="field" v-bind:class="emailError ? 'error' : ''">
                        <label class="label">Email</label>
                        <div class="control" v-bind:class="emailError ? 'error' : ''">
                            <input
                                    autocomplete="email"
                                    class="input is-fullwidth"
                                    type="email"
                                    name="email"
                                    placeholder="Deine Email-Adresse"
                                    v-model="email"
                            />
                        </div>
                    </div>

                    <div class="button-container">
                        <button v-on:click="onSubmitClicked" class="form-button button is-primary is-fullwidth">Passwort
                            zurücksetzen
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div class="confirmation">
                        <p>Sofern diese Email-Adresse mit einem Account verknüpft ist, haben wir dir gerade einen Zurücksetzungslink geschickt.</p>
                        <span class="resend-button button is-fullwidth mt-2"
                              v-on:click="onSubmitClicked">Link erneut senden</span>
                    </div>
                </div>
                <div class="sign-in-up-container mt-5">
                    Passwort doch wieder eingefallen? <span class="clickable-text"
                                                            v-on:click="goToSignIn">Einloggen</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
