<template>
    <div>
        <button class="button is-primary" @click="modifyPdf" :disabled="generating">
            {{ generating ? "Wird erstellt..." : text }}
        </button>
    </div>
</template>

<script>
import {PDFDocument, rgb} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import axios from "axios";

export default {
    props: {
        firstname: String,
        lastname: String,
        text: String,
    },
    data() {
        return {
            existingPdfUrl: '/zertifikatsvorlage.pdf',
            generating: false,
        };
    },
    methods: {
        async fetchPdf() {
            const response = await fetch(this.existingPdfUrl);
            if (response.ok) {
                return response.arrayBuffer();
            }
            throw new Error('PDF could not be loaded');
        },
        async fetchFont(url) {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Could not fetch the font: ${response.statusText}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            return arrayBuffer;
        },
        async modifyPdf() {

            if (this.firstname == null || this.firstname === '') {
                alert('Kein Vorname eingegeben!')
                return;
            }

            this.generating = true;

            try {
                const existingPdfBytes = await this.fetchPdf();
                const pdfDoc = await PDFDocument.load(existingPdfBytes);
                pdfDoc.registerFontkit(fontkit);
                const firstPage = pdfDoc.getPages()[0];
                const {width, height} = firstPage.getSize();

                let userLastname = ''
                if (this.lastname == null) {
                    userLastname = '';
                } else {
                    userLastname = this.lastname;
                    axios.patch(`/api/v1/yoloausers/update_current_user/`, {
                        lastname: this.lastname,
                        firstname: this.firstname,
                    })
                }

                const name = (this.firstname + ' ' + userLastname).toUpperCase();
                const today = new Date();
                const dateString = today.toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric'});

                const fontBytesLatoBlack = await this.fetchFont('/Lato/Lato-Black.ttf');
                const fontBytesLatoBold = await this.fetchFont('/Lato/Lato-Bold.ttf');
                const latoBlack = await pdfDoc.embedFont(fontBytesLatoBlack);
                const latoBold = await pdfDoc.embedFont(fontBytesLatoBold);

                let nameFontSize = 30;
                if (name.length > 20 && name.length <= 40) {
                    nameFontSize = 20;
                } else if (name.length > 40) {
                    nameFontSize = 12;
                }

                const dateFontSize = 16;

                // Calculate Y positions from the top in points (DIN A4 dimensions considered)
                const nameYPosition = 510;
                const dateYPosition = 285;

                // For centering, we can use the width of the page and estimate the width of the text
                // For a more precise approach, especially with proportional fonts, measure text width using pdf-lib's font.widthOfTextAtSize(text, fontSize)
                let text = name;
                let textSize = nameFontSize;
                let textWidth = latoBlack.widthOfTextAtSize(text, textSize);

                // Add name centered on the page
                firstPage.drawText(name, {
                    x: width / 2 - textWidth / 2,
                    y: nameYPosition,
                    size: nameFontSize,
                    color: rgb(0, 0, 0),
                    font: latoBlack,
                    maxWidth: width - 100, // Adjust as necessary
                });

                text = dateString;
                textSize = dateFontSize;
                textWidth = latoBold.widthOfTextAtSize(text, textSize);

                // Add date centered on the page
                firstPage.drawText(dateString, {
                    x: width / 2 - textWidth / 2,
                    y: dateYPosition,
                    size: dateFontSize,
                    font: latoBold,
                    color: rgb(0, 0, 0),
                    maxWidth: width - 100, // Adjust as necessary
                });

                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes], {type: 'application/pdf'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'yoloa-zertifikat.pdf';
                link.click();
                // Proceed with creating a Blob and downloading the file as before
            } catch (error) {
                console.error('Error modifying PDF:', error);
            } finally {
                this.generating = false;
                this.$emit('certificate-generated');
            }
        }
    },
    emits: ['certificate-generated'],
};
</script>
