<script>
import CircularProgress from "@/components/BasicUI/CircularProgress.vue";

export default {
    name: "CourseOverviewCard",
    components: {CircularProgress},
    props: {
        title: String,
        progress: Number,
        imageUrl: String,
        link: String,
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<template>
    <div class="card mb-5">
        <div class="card-content">
            <div class="is-flex is-flex-direction-column">
                <div>
                    <img :src="'illustrations/' + imageUrl" v-if="imageUrl" class="rounded-image mb-3"/>
                </div>
                <div>
                    <p class="title is-size-4 is-size-5-mobile mb-3">{{ title }}</p>
                </div>
                <div class="is-flex is-flex-direction-row">
                    <div v-if="!disabled">
                        <div class="mr-1">
                            <CircularProgress :progress="progress * 100"/>
                        </div>
                    </div>
                    <div v-if="!disabled">
                        <button @click="this.$router.push(link)" class="button is-fullwidth is-primary">
                            Zur Kursübersicht
                        </button>
                    </div>
                    <div v-else>
                        <button class="button is-disabled is-grey" disabled>Coming soon</button>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.rounded-image {
    border-radius: 20px;
}
.card {
    max-width: 350px;
}
</style>
