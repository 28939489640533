<template>
    <div class="app-container">
        <div class="app-content-container">
            <div @click="this.$router.push('/')" style="cursor: pointer;" class="is-text-centered">
                <img src="../assets/images/yoloa-logo.svg" class="logo mb-4"/><br/>
                <br/><br/>
            </div>

            <div class="is-text-centered mb-4" v-if="error">
                <div class="notification is-danger">
                    <h4 class="title is-text-centered">Fehler beim Löschen des Kontos.</h4>
                    <p>Existiert dein Nutzeraccount? Bitte wende dich für Hilfe an kontakt@yoloa.de.</p>
                </div>
            </div>

            <div class="is-text-centered" v-if="!deleteSuccessful">
                <h1 class="title is-text-centered">Konto löschen</h1>
                <p>Wir sind traurig, dich gehen zu sehen! Klicke hier, um das Löschen deines Kontos zu bestätigen:</p>
                <button @click="deleteUser" class="button is-danger mt-4">Konto löschen</button>
                <br/><br/>
                <a class="is-clickable-text" @click="this.$router.push('/')" style="cursor: pointer;">Abbrechen</a>
            </div>
            <div class="is-text-centered" v-else>
                <h1 class="title is-text-centered">Dein Konto wurde erfolgreich gelöscht.</h1>
                <p>Dein Konto wurde erfolgreich gelöscht.</p>
                <br/><br/>
                <a class="is-clickable-text" @click="this.$router.push('/')" style="cursor: pointer;">Zurück zur
                    Startseite</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'DeleteUserPage',
    data() {
        return {
            deleteSuccessful: false,
            error: false,
        };
    },
    methods: {
        async deleteUser() {
            try {
                await axios.delete('/api/v1/delete-user/'); // Assuming DELETE method is used for the API
                this.deleteSuccessful = true;
            } catch (error) {
                console.error("Error during account deletion:", error);
                this.error = true;
            }
        }
    }
}
</script>

