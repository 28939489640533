<script>
import {Stories} from "vue-insta-stories";

export default {
    name: 'ExerciseIntro',
    components: {Stories},
    emits: ['intro-finished'],
    props: {
        moduleName: String
    },
    methods: {
        checkFinishedIntro() {
            if (this.currentIndex === this.introduction_slides.length - 1) {
                this.$emit('intro-finished')

            }
        },
    },
    data() {
        return {
            currentIndex: 0
        }
    },
    computed: {
        introduction_slides() {
            switch (this.moduleName) {
                case 'life-vision':
                    return [...Array(8).keys()].map((number) => '/intros/futureme/life-vision/' + (number + 1) + '.webp')
                case 'compass':
                    return [...Array(8).keys()].map((number) => '/intros/futureme/compass/' + (number + 1) + '.webp')
                case 'cockpit':
                    return [...Array(14).keys()].map((number) => '/intros/futureme/cockpit/' + (number + 1) + '.webp')
                case 'calendar':
                    return [...Array(8).keys()].map((number) => '/intros/futureme/calendar/' + (number + 1) + '.webp')
                case 'spider':
                    return [...Array(7).keys()].map((number) => '/intros/futureskills/spider/' + (number + 1) + '.webp')
                case 'library':
                    return [...Array(3).keys()].map((number) => '/intros/futurework/library/' + (number + 1) + '.webp')
                case 'jobswiper':
                    return [...Array(7).keys()].map((number) => '/intros/futurework/jobswiper/' + (number + 1) + '.webp')
                case 'wishlist':
                    return [...Array(5).keys()].map((number) => '/intros/futurework/wishlist/' + (number + 1) + '.webp')
                case 'workquiz':
                    return [...Array(6).keys()].map((number) => '/intros/futurework/workquiz/' + (number + 1) + '.webp')
            }
        }
    }
}
</script>

<template>
    <div class="mb-4">
        <Stories
                :stories="introduction_slides"
                :interval="8000"
                class="ig-stories"
                v-model:currentIndex="currentIndex"
                @next="checkFinishedIntro"

        />
    </div>
</template>

<style scoped lang="scss">
.ig-stories {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 50;
}

@media (min-width: 768px) {
  .ig-stories {
    position: relative;
    height: 598px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;

  }
    .ig-stories img {
            //border-radius: 20px !important;
    }
}
</style>
