<script>
export default {
    name: 'ValueSlider',
    data() {
        return {
            iconsize: 24,
        }
    },
    props: {
        title: {
            default: "",
            type: String
        },
        id: {
            default: 999,
            type: Number,
        },
        leftLegendText: {
            default: "Sehr wenig",
            type: String
        },
        middleLegendText: {
            default: "Neutral",
            type: String
        },
        rightLegendText: {
            default: "Sehr viel",
            type: String
        },
        showTextLegend: {
            default: false,
            type: Boolean
        },
        modelValue: {
            default: 0,
        },
        min: {
            default: -2,
            type: Number
        },
        max: {
            default: 2,
            type: Number
        },
        step: {
            default: 1,
            type: Number
        },
        image: {
            default: '',
            type: String,
        },
        isEvaluationMode: {
            default: false,
            type: Boolean
        },
        isSolidBackground: {
            default: false,
            type: Boolean,
        }

    },
    computed: {
        sliderProps() {
            let background_color = 'rgba(94, 159, 247, 0.15)'

            let gradient_content;
            let blue = '#5E9FF8';

            if(this.isSolidBackground) {
                return {'background': background_color}
            }

            if (this.isEvaluationMode) {
                const dark_red = '#E86864';
                const light_red = '#EDADAB';
                const yellow = '#EDE5C2';
                const light_green = '#A7DABD';
                const dark_green = '#6DC291';
                switch (parseInt(this.modelValue)) {
                    case -2:
                        background_color = dark_red
                        break;
                    case -1:
                        background_color = light_red
                        break;
                    case 0:
                        background_color = yellow
                        break;
                    case 1:
                        background_color = light_green
                        break;
                    case 2:
                        background_color = dark_green
                        break;
                }
                return {'background': background_color}
            } else {
                switch (parseInt(this.modelValue)) {
                    case -2:
                        gradient_content = 'to right, ' + blue + ', ' + blue + ' 50%, transparent 50%, transparent';
                        break;
                    case -1:
                        gradient_content = 'to right, transparent, transparent 26%, ' + blue + ' 26%, ' + blue + ' 50%, transparent 50%, transparent'
                        break;
                    case 0:
                        gradient_content = 'to right, transparent, transparent'
                        break;
                    case 1:
                        gradient_content = 'to right, transparent, transparent 50%, ' + blue + ' 50%, ' + blue + ' 74%, transparent 74%, transparent'
                        break;
                    case 2:
                        gradient_content = 'to right, transparent, transparent 50%, ' + blue + ' 50%, ' + blue + ' 100%'
                }
            }

            return {'background': 'linear-gradient(' + gradient_content + '), ' + background_color}

        },
    }
}
</script>

<template>
    <div class="field mb-6">
        <div class="control mt-2">
            <div class="is-flex is-flex-direction-row">
                <div v-if="image">
                    <img :src="'/lifecockpit/' + image" width="28"/>&nbsp;&nbsp;
                </div>
                <div>
                    <label class="subtitle is-5">{{ title }}</label>
                </div>
            </div>
            <input :disabled="isEvaluationMode" type="range" :value="modelValue"
                   @input="$emit('update:modelValue', $event.target.value)" :min="min"
                   :max="max" :step="step" :style="sliderProps"
                   class="slider mt-2">
            <div class="legend" v-if="!showTextLegend">
                <div class="legend-item">
                    <img src="/lifecockpit/emotions/crying.png" :width="iconsize"/>
                </div>
                <div class="legend-item" style="margin-left: 0px;">
                    <img src="/lifecockpit/emotions/sad.png" :width="iconsize"/>
                </div>
                <div class="legend-item" style="margin-left: 0px;">
                    <img src="/lifecockpit/emotions/neutral.png" :width="iconsize"/>
                </div>
                <div class="legend-item">
                    <img src="/lifecockpit/emotions/happy.png" :width="iconsize"/>
                </div>
                <div class="legend-item">
                    <img src="/lifecockpit/emotions/very-happy.png" :width="iconsize"/>
                </div>
            </div>
            <div class="legend" v-else>
                <div class="legend-item">
                    {{ leftLegendText }}
                </div>
                <div class="legend-item" style="margin-left: -5px;">
                    {{ middleLegendText }}
                </div>
                <div class="legend-item">
                    {{ rightLegendText }}
                </div>
            </div>
            <div v-if="isEvaluationMode" class="mt-4" style="margin-bottom: -40px">
                <div v-if="id == -10">
                    <div v-if="modelValue == -2">Vorsicht, das sieht überhaupt nicht gut aus! Es ist sehr wichtig, als
                        Ausgleich Beziehungen zu Menschen zu pflegen, die dir viel bedeuten. Natürlich sollte man dafür
                        nicht seine anderen Lebensbereiche komplett vernachlässigen. Aber hier solltest du definitiv
                        etwas ändern, da dieser Lebensbereich bei dir momentan außer Kontrolle geraten ist! Überlege dir
                        als nächstes, wie du in diesem Bereich wieder besser und zufriedener werden kannst.
                    </div>
                    <div v-if="modelValue == -1">Hier solltest du aufpassen – du bist eher unzufrieden damit, wie viel
                        Zeit du mit anderen Menschen aus deinem Umfeld verbringen kannst und kannst momentan nicht
                        wirklich viel Kraft daraus ziehen. Dabei ist das als Ausgleich total wichtig! Versuche daher auf
                        jeden Fall, hier anzusetzen und diesen Lebensbereich zu verbessern, z.B. indem du deinen Zeitplan
                        entsprechend umstrukturierst.
                    </div>
                    <div v-if="modelValue == 0">Eher so mittel… Du bist zwar nicht total unzufrieden, würdest aber gern
                        mehr Zeit zur Verfügung haben, die du mit anderen Menschen in deinem Umfeld verbringen kannst.
                        Beziehungen zu anderen Personen sind wichtig für dich – überleg dir, wie du deinen Zeitplan
                        umstrukturieren könntest, um mehr Zeit dafür zu haben.
                    </div>
                    <div v-if="modelValue == 1">Noch nicht perfekt, aber du bist auf einem guten Weg! Du verbringst
                        schon jetzt einige Zeit mit Menschen, die dir wichtig sind und kannst daraus Kraft ziehen. Wenn
                        du es zeitlich hinbekommst, versuch doch, das noch weiter auszubauen!
                    </div>
                    <div v-if="modelValue == 2">Das sieht doch perfekt aus! Du hast genug Zeit, um mit für dich
                        wichtigen Menschen Quality Time zu verbringen und daraus viel Kraft zu schöpfen. Schau, dass du
                        andere Bereiche nicht komplett dafür vernachlässigst, aber ansonsten: Weiter so!
                    </div>
                </div>
                <div v-if="id == -11">
                    <div v-if="modelValue == 2">
                        Wow, du fühlst dich total gesund und fit – super! Sich wohl in seinem Körper zu fühlen und
                        möglichst wenige gesundheitliche Probleme zu haben, ist sehr wichtig für die eigene
                        Lebenszufriedenheit. Sehr schön, dass es dir in diesem Bereich so gut geht!
                    </div>
                    <div v-if="modelValue == 1">
                        Das klingt doch gut! Du bist zwar nicht hundertprozentig zufrieden mit deiner Gesundheit und
                        Fitness, aber oft machen der Körper oder die Umstände einem auch einfach einen Strich durch die
                        Rechnung. Falls es für dich machbar ist, versuch doch, hier noch besser zu werden. Ansonsten:
                        mach
                        weiter so!
                    </div>
                    <div v-if="modelValue == 0">
                        Nicht super und nicht furchtbar – Gesundheit, Fitness und ein gutes Gefühl im eigenen Körper
                        sind
                        sehr wichtig für die Lebenszufriedenheit. Leider kann man nicht alles davon immer perfekt
                        beeinflussen. Falls es für dich aktuell möglich ist, überleg dir doch mal, ob du ein oder zwei
                        Stellschrauben hättest, mit denen du dich hier noch besser fühlen würdest.
                    </div>
                    <div v-if="modelValue == -1">
                        Hmm, aktuell scheint es dir in Bezug auf Gesundheit und Fitness nicht ganz so gut zu gehen. In
                        diesem Lebensbereich ist es oft schwierig, hundertprozentig zufrieden zu sein. Trotzdem ist ein
                        gesundes Körpergefühl sehr wichtig für deine Lebenszufriedenheit! Versuche daher jetzt,
                        Möglichkeiten zu finden, wie du trotz deiner anderen Verpflichtungen und Umstände gesünder und
                        zufriedener werden kannst.
                    </div>
                    <div v-if="modelValue == -2">
                        Oh je, das sieht ja gar nicht gut aus. Gesundheit und Fitness gehören zu den wichtigsten
                        Lebensbereichen, da sie sich auf alles andere auswirken – dass es dir hier so schlecht geht, ist
                        überhaupt nicht gut! Du solltest dich daher dringend damit beschäftigen, wie du möglichst
                        schnell
                        einige Dinge verändern kannst, um hier zufriedener zu werden und wieder zu einer gesünderen
                        Balance
                        zu finden.
                    </div>
                </div>
                <div v-if="id == -12">
                    <div v-if="modelValue == 2">
                        Alle Achtung, du hast deine Work-Life-Balance komplett unter Kontrolle und bist sehr zufrieden
                        mit deinen Möglichkeiten, dich zu erholen und deinen Hobbys nachzugehen! Das ist unheimlich
                        wichtig, um Stress und eventuelle Unzufriedenheit aus anderen Lebensbereichen abzufangen und
                        gesund und glücklich zu bleiben. Super!
                    </div>
                    <div v-if="modelValue == 1">
                        Schön, dass du es so gut hinbekommst, trotz deiner anderen Verpflichtungen noch so viel Zeit mit
                        Spaß und Entspannung zu verbringen! Hobbys oder einfach nur Zeit für dich sind eine sehr gute
                        Möglichkeit, Stress und Unzufriedenheit abzubauen und insgesamt glücklicher und zufriedener zu
                        werden. Schaffst du es, für diesen wichtigen Bereich noch etwas mehr zu tun? Ansonsten mach so
                        weiter wie jetzt gerade!
                    </div>
                    <div v-if="modelValue == 0">
                        So lala? Du bist zwar nicht total unzufrieden, aber auch nicht wirklich happy mit diesem
                        Lebensbereich. Leider sind Spaß und Entspannung meistens das erste, was hintenüber fällt, wenn
                        man viel Stress hat. Dabei sind deine Lieblingsaktivitäten und -hobbys der beste Weg, um Stress
                        und Anspannung abzubauen. Versuche, wenn möglich, hier anzusetzen und dir mehr Zeit für dich
                        selber einzuräumen.
                    </div>
                    <div v-if="modelValue == -1">
                        Schade, im Bereich Spaß und Entspannung läuft es bei dir aktuell nicht wirklich gut, oder? Das
                        kann immer mal passieren, wenn es in anderen Lebensbereichen gerade eher hektisch ist. Der
                        Ausgleich über deine Hobbys und einfach nur Zeit für dich ist aber extrem wichtig für deine
                        Lebenszufriedenheit! Arbeite deswegen aktiv daran, dir hierfür Zeit zu nehmen, so viel du es
                        irgendwie hinbekommst!
                    </div>
                    <div v-if="modelValue == -2">
                        Vorsicht, hier musst du wirklich schnell ansetzen! Wenn andere Lebensbereiche eine Zeit lang
                        mehr Aufmerksamkeit und Zeit brauchen, werden Spaß und Entspannung leider oft vernachlässigt.
                        Aber deine Hobbys und einfach mal Zeit für dich sind extrem wichtig, um Stress und
                        Unzufriedenheit abzubauen und dich wieder leistungsfähiger und glücklicher zu machen – das hilft
                        dir auch in anderen Bereichen!
                    </div>
                </div>
                <div v-if="id == -13">
                    <div v-if="modelValue == 2">
                        Perfekt, du bist auf der Überholspur, was Arbeit und Lernen angeht! Gut in dem zu sein, was man
                        beruflich (oder auf dem Weg dorthin) macht, ist sehr erfüllend, wichtig und macht stolz und
                        zufrieden. Achte darauf, andere Lebensbereiche nicht dauerhaft dafür zu vernachlässigen, aber
                        solange das nicht der Fall ist, mach weiter so!
                    </div>
                    <div v-if="modelValue == 1">
                        Super, du kannst zufrieden sein mit deinem aktuellen Stand in Beruf oder Ausbildung! Auch wenn
                        mal nicht alles genauso klappt, wie du es dir vielleicht vorstellst: Das wichtigste ist, dass du
                        dein Bestes gibst und ständig dazulernst und dich weiterentwickelst. Achte auch darauf, andere
                        Lebensbereiche nicht zu vernachlässigen, um eine Balance zu finden. Gibt es etwas, was dich
                        aktuell in diesem Bereich glücklicher und zufriedener machen würde?
                    </div>
                    <div v-if="modelValue == 0">
                        Naja, Beruf und Lernen sind nicht immer der persönliche Lieblingsbereich – manchmal liegt das an
                        anderen Personen oder den Umständen, manchmal auch an einem selber. Versuch doch mal, für dich
                        selber herauszufinden, woran es liegt, dass du gerade nicht ganz zufrieden bist. Gibt es etwas,
                        was du machen könntest, um das zu ändern? Schließlich nehmen Beruf, Schule, Uni, Ausbildung und
                        Co. sehr viel Zeit in unserem Leben ein, da ist es schon wichtig, glücklich damit zu sein.
                    </div>
                    <div v-if="modelValue == -1">
                        Das klingt nicht besonders gut! Beruf und Lernen nehmen normalerweise den größten Anteil unseres
                        Tages ein, deswegen ist es sehr wichtig, hier erfüllt und zufrieden zu sein. Natürlich kann das
                        nicht immer perfekt funktionieren und manchmal liegen die Gründe auch nicht bei uns selber. Du
                        solltest dir trotzdem überlegen, ob du gerade etwas verändern könntest, um glücklicher in diesem
                        Lebensbereich zu werden.
                    </div>
                    <div v-if="modelValue == -2">
                        Achtung, hier ist dein Leben gerade ganz schön aus der Balance geraten! So viel Zeit, wie wir
                        jeden Tag mit Beruf und Lernen verbringen, ist es wirklich wichtig, hier zumindest einigermaßen
                        erfüllt zu sein. Überlege dir auf jeden Fall, woran es liegt, dass du hier gerade so unzufrieden
                        bist. Was sind konkrete Schritte, die du selber machen kannst, um die Situation zu verbessern?
                        Wer kann dir dabei helfen?
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../assets/color-overrides';

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slidecontainer {
  width: 50%;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */

  outline: none; /* Remove outline */
  //opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 25px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  border: 3px solid $primary;
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #5199FC; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
</style>
