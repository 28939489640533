<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "FutureMeCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
        link: {
            type: String,
            default: '/futureme',
        }
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            image-url="futureme.png"
            :link="link"
            title="Future Me"
            :is-exercise-card="false"
            description="Finde heraus, welche Zukunft du dir wünschst und wo du aktuell stehst."
    />
</template>
