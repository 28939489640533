<script>
import axios from "axios";
import shuffled_questions, {categories, categories_with_descriptions} from "@/assets/futureskills-list";
import RatingButtonArray from "@/components/RatingButtonArray.vue";
import VueApexCharts from "vue3-apexcharts";
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    name: 'FutureSkillsExercise',
    components: {
        DescriptionCard,
        ExerciseIntro,
        Modal,
        TopNavbar,
        ProgressBar,
        RatingButtonArray,
        apexchart: VueApexCharts
    },
    mounted() {
        this.question_array = shuffled_questions()
        this.category_array = categories.map(category => ({category, rating: 0}))

        axios.get('/api/v1/communityaverage/').then(response => {
            let averages = [
                response.data.adaptability__avg,
                response.data.learning_motivation__avg,
                response.data.critical_thinking__avg,
                response.data.interdisciplinarity__avg,
                response.data.team_ability__avg,
                response.data.communication__avg,
                response.data.perseverance__avg,
                response.data.empathy__avg,
                response.data.networking__avg,
                response.data.ai_application__avg,
            ]
            this.community_average = averages.map((value) => this.round(value))
        });

        axios.get('/api/v1/progress/').then(response => {
            if (response.data.future_skills.future_skills_quiz === 1) {
                this.stage = 3
                this.revisited = true

                axios.get('/api/v1/futureskillsresults/').then(response => {
                    const entry = response.data[0] //get latest result
                    this.category_array = [
                        {category: categories[0], rating: entry.adaptability},
                        {category: categories[1], rating: entry.learning_motivation},
                        {category: categories[2], rating: entry.critical_thinking},
                        {category: categories[3], rating: entry.interdisciplinarity},
                        {category: categories[4], rating: entry.team_ability},
                        {category: categories[5], rating: entry.communication},
                        {category: categories[6], rating: entry.perseverance},
                        {category: categories[7], rating: entry.empathy},
                        {category: categories[8], rating: entry.networking},
                        {category: categories[9], rating: entry.ai_application},
                    ]
                })
            }
        })
    },
    data() {
        return {
            stage: 0,
            revisited: false,
            show_modal: false,
            show_explanations: false,
            question_array: [],
            category_array: [],
            current_question: 0,
            questions_per_category: 4,
            community_average: [],
            chartOptions: {
                chart: {
                    height: 400,
                    width: 400,
                    id: "kompetenzspinne",
                    toolbar: {
                        show: false  // This will remove the download option
                    },
                    foreColor: '#000'
                },

                xaxis: {
                    categories: categories,
                    labels: {
                        style: {
                            colors: Array(10).fill('#040404'),
                            fontSize: '12px',  // Adjust this value as desired
                        }
                    }
                },
                colors: [
                    '#FF41C0', '#AAAAAA'
                ]
            },
        }
    },
    methods: {
        nextStage() {
            if (this.stage === 1) {
                //Divide ratings by the number of questions per category
                this.category_array = this.category_array.map((entry) => ({
                    category: entry.category, rating: this.round(entry.rating / this.questions_per_category)
                }))

                let data = {
                    adaptability: this.category_array[0].rating,
                    learning_motivation: this.category_array[1].rating,
                    critical_thinking: this.category_array[2].rating,
                    interdisciplinarity: this.category_array[3].rating,
                    team_ability: this.category_array[4].rating,
                    communication: this.category_array[5].rating,
                    perseverance: this.category_array[6].rating,
                    empathy: this.category_array[7].rating,
                    networking: this.category_array[8].rating,
                    ai_application: this.category_array[9].rating,
                }

                axios.post('/api/v1/futureskillsresults/', data).then((result) => {
                    //console.log(result)
                })

            }
            this.stage++;
        },
        round(number) {
            return Math.round(number * 10) / 10
        },
        restartExercise() {
            this.stage = 0
            this.show_modal = false
            this.current_question = 0
            this.question_array = shuffled_questions()
            this.category_array = categories.map(category => ({category, rating: 0}))
        },
        updateRating({rating, category}) {
            let category_entry = this.category_array.filter((entry) => entry.category === category)[0]
            category_entry.rating += rating
            if (this.current_question < this.question_array.length - 1) {
                this.current_question++;
            } else {
                this.show_modal = true
            }
        },
    },
    computed: {
        categories_with_descriptions() {
            return categories_with_descriptions()
        },
        buttonClass() {
            return this.has_selected_all_favourites ? 'is-primary' : 'hidden';
        },
        series() {
            return [
                {
                    name: "Du",
                    data: this.category_array.map((value) => value.rating),
                },
                {
                    name: "Community-Durchschnitt",
                    data: this.community_average
                },
            ]
        },
    }
}
</script>

<template>
    <TopNavbar title="Kompetenzspinne"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage === 0">
                <ExerciseIntro module-name="spider" @intro-finished="nextStage"/>
                <button class="button is-fullwidth is-primary" @click="nextStage">Übung starten</button>
            </div>
            <div v-else-if="stage == 1">
                <ProgressBar classname="is-primary" :progress="current_question*100/question_array.length"/>

                <div class="question-wrapper">
                    <p class="title is-5 has-text-weight-semibold">{{ question_array[current_question].text }}</p>
                </div>
                <RatingButtonArray class="mt-2" :category="question_array[current_question].category"
                                   @rated="updateRating"
                />
                <Modal title="Alle Fragen beantwortet!"
                       subtitle="Sieh dir jetzt die Auswertung zu deinen Future Skills an." :is-active="show_modal"
                       @click-button="nextStage"/>
            </div>
            <div v-else>
                <DescriptionCard title="Deine Kompetenzen">
                    <p>Hier siehst du deine persönliche Kompetenzspinne im Vergleich zum Community-Durchschnitt in
                        deiner
                        Ausbildungsgruppe. Du siehst immer den jeweiligen Skills-Bereich und wie du darin aktuell
                        abschneidest. Es ist unrealistisch, in jedem Bereich perfekt abzuschneiden – das ist nicht der
                        Sinn
                        der Sache und auch nicht notwendig, um später einen guten Job zu kriegen. Du sollst einen groben
                        Überblick bekommen, welche Fähigkeiten du jetzt schon mitbringst und bei welchen du ansetzen
                        könntest, um dich noch zu verbessern.</p>
                </DescriptionCard>
                <p class="has-text-centered" style="overflow-x: scroll">
                    <apexchart :series="series" :options="chartOptions" width="120%" style="margin-left: -20%; margin-right: 0; padding: 0;" type="radar"></apexchart>
                </p>
                <DescriptionCard title="Erklärungen zu den Kategorien">
                    <p>Du bist dir nicht sicher, was eine bestimmte Kategorie genau bedeutet?</p>
                    <button v-if="!show_explanations" @click="show_explanations = true" class="button is-light mt-3">
                        Erklärungen ausklappen&nbsp;<font-awesome-icon :icon="['fas', 'caret-down']"/>
                    </button>
                    <button v-else class="button is-light mt-3" @click="show_explanations = false">Erklärungen
                        einklappen&nbsp;<font-awesome-icon :icon="['fas', 'caret-up']"/>
                    </button>
                    <ul v-show="show_explanations" class="mt-3">
                        <li v-for="category in categories_with_descriptions" :key="category.name">
                            <strong>{{ category.name }}</strong>: {{ category.description }}
                        </li>
                    </ul>
                </DescriptionCard>
                <a href="/futureskills" class="button mt-4 is-primary is-fullwidth center-block">Zurück zur
                    Übersicht</a>
                <button @click="restartExercise" class="button mt-4 is-fullwidth center-block" v-if="revisited">Übung
                    neu
                    starten
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>

.question-wrapper {
    min-height: 80px;
}

.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

.three-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.hidden {
    display: none;
}
</style>
