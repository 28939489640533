<script>
import InfoTooltip from "@/components/BasicUI/InfoTooltip.vue";

export default {
    name: 'JobSlider',
    components: {InfoTooltip},
    data() {
        return {}
    },
    props: {
        title: {
            default: "",
            type: String
        },
        leftLegendText: {
            default: "Sehr wenig",
            type: String
        },
        middleLegendText: {
            default: "Neutral",
            type: String
        },
        rightLegendText: {
            default: "Sehr viel",
            type: String
        },
        showTextLegend: {
            default: false,
            type: Boolean
        },
        value: {
            default: 0,
        },
        max: {
            default: 10,
            type: Number
        },
        step: {
            default: 1,
            type: Number
        },
        type: {
            default: 'primary',
            type: String
        }
    },
    computed: {
        sliderProps() {
            const background_color = 'rgba(94, 159, 247, 0.15)';
            const percentage = this.value / this.max * 100;
            const blue = '#5E9FF8';

            // Start the blue at 0% and end at the calculated percentage
            let gradient_content = 'to right, ' + blue + ' 0%, ' + blue + ' ' + percentage + '%, transparent ' + percentage + '%, transparent 100%';
            return {'background': 'linear-gradient(' + gradient_content + '), ' + background_color};
        },
        percentage() {
            return this.value / (this.max) * 100
        }
    }
}
</script>

<template>
    <div class="field mb-0">
        <div class="control mt-2">
            <div class="is-flex is-flex-direction-row">
                <div class="mb-2">
                    <label class="subtitle is-6">{{ title }} <InfoTooltip text="Test"/></label>
                </div>
            </div>
            <progress class="progress" :class="'is-' + type" :value="value" :max="max">{{ value / max * 100 }}%</progress>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../assets/color-overrides';

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slidecontainer {
  width: 50%;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */

  outline: none; /* Remove outline */
  //opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 25px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  border: 3px solid $primary;
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #5199FC; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
</style>
