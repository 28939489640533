<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "LifeCockpitCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            link="/futureme/cockpit"
            image-url="futureme/cockpit.png"
            title="Mein Life Cockpit"
            description="In welchen Lebensbereichen läuft es momentan gut und welche brauchen mehr Aufmerksamkeit?"
    />
</template>
