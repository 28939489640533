<script>
import axios from "axios";
import ImageCard from "@/components/ImageCard.vue";
import {images_numbered, shuffle} from '@/assets/helper-functions'
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import {Stories} from "vue-insta-stories";
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import HoveringButtonSection from "@/components/BasicUI/HoveringSection.vue";
import HoveringSection from "@/components/BasicUI/HoveringSection.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";

export default {
    name: 'ImageExercise',
    components: {
        TopNavbar,
        HoveringSection, HoveringButtonSection, Modal, ProgressBar, ExerciseIntro, ImageCard, Stories
    },
    created() {
        axios.get('/api/v1/progress/').then(response => {
            let image_paths = [...Array(263).keys()].map((number) => ({
                url: '/lifevision/lifevision-' + this.padNumber(number + 1, 3) + '.webp',
                id: number + 1
            }))
            this.image_array = shuffle(image_paths, 32)
            if (response.data.future_me.vision_board == 1) {
                this.stage = 3
                this.revisited = true
                axios.get('/api/v1/visionboards/').then(response => {
                    const favourite_image_ids = response.data[0].favourite_images
                    this.favourite_images = favourite_image_ids.map((id) => ({
                        id: id,
                        url: this.image_array.filter((img) => img.id === id)[0].url
                    }))

                })

            } else {
                this.stage = 0
            }
        })
    },
    data() {
        return {
            stage: -1,
            image: 0,
            maxImages: 60,
            maxFavourites: 12,
            selected_images: [],
            favourite_images: [],
            revisited: false,
            image_array: [],
            show_modal: false,
            show_hovering_button: true,
        }
    },
    methods: {
        nextStage() {
            this.stage++;
        },
        receiveSelection(id, url) {
            this.selected_images.push({id: id, url: url})
            if (this.selected_images.length === this.maxImages) {
                this.nextStage()
            } else {
                this.image = this.image + 1
            }
        },
        selectFavourite(id, url) {
            this.favourite_images.push({id: id, url: url})
        },
        unselectFavourite(id, url) {
            this.favourite_images = this.favourite_images.filter((element) => element.id !== id)
        },
        submitImages() {
            const favourite_ids = this.favourite_images.map((image) => image.id)
            const selected_ids = this.selected_images.map((image) => image.id)
            const data = {
                favourite_images: favourite_ids,
                selected_images: selected_ids,
            }
            axios.post('/api/v1/visionboards/', data).then((response) => {
                this.show_hovering_button = false
                this.show_modal = true
            });
        },
        restartExercise() {
            this.stage = 0
            this.selected_images = []
            this.favourite_images = []
            this.image = 0
        },
        padNumber(num, size) {
            return ('000000000' + num).substr(-size);
        },
    },
    computed: {
        selection_button_info_text() {
            return '<b>' + this.favourite_images.length + ' ausgewählt</b> von ' + this.maxFavourites
        },
        has_selected_all_favourites() {
            return this.favourite_images.length >= this.maxFavourites
        },
        current_images() {
            return this.image_array.slice(this.image * 4, (this.image + 1) * 4)
        },
        buttonClass() {
            return this.has_selected_all_favourites ? 'is-primary' : 'hidden';
        }
    }
}
</script>

<template>
    <TopNavbar title="Meine Life Vision"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage === -1">

            </div>
            <div v-else-if="stage === 0">
                <ExerciseIntro module-name="life-vision" @intro-finished="nextStage"/>
                <button class="button is-primary mt-4 is-fullwidth" @click="nextStage">Los geht's</button>
            </div>
            <div v-else-if="stage == 1">
                <ProgressBar classname="is-primary" :progress="image*100/maxImages"/>

                <div class="columns">
                    <div class="column is-12">
                        <div class="grid">
                            <div v-for="(image, i) in current_images">
                                <ImageCard :is-highlightable="false" :url="image.url"
                                           @image-selected="receiveSelection(image.id, image.url)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="stage == 2">
                <p class="title is-4 is-text-centered">Wähle nun die {{ maxFavourites }} Bilder aus, die deine Vision am
                    besten repräsentieren.</p>
                <div class="three-grid">
                    <div v-for="(image, i) in selected_images">
                        <ImageCard :max-reached="has_selected_all_favourites" :is-highlightable="true" :url="image.url"
                                   @image-selected="selectFavourite(image.id, image.url)"
                                   @image-unselected="unselectFavourite(image.id, image.url)"/>
                    </div>
                </div>
                <Modal title="Übung fertig!" subtitle="Du hast alle Bilder ausgewählt." :is-active="show_modal"
                       @click-button="nextStage"/>
                <HoveringSection v-show="show_hovering_button" :is-clickable="has_selected_all_favourites"
                                 @click-button="submitImages" :info-text="selection_button_info_text"/>
            </div>
            <div v-else>
                <div class="three-grid">
                    <div v-for="(image, i) in favourite_images">
                        <ImageCard :is-highlightable="false" :url="image.url"/>
                    </div>
                </div>
                <HoveringSection>
                    <a href="/futureme" class="button mt-4 is-primary center-block is-fullwidth">Zurück zur
                        Übersicht</a>
                    <button @click="restartExercise" class="button mt-4 center-block is-fullwidth" v-if="revisited">
                        Übung
                        wiederholen
                    </button>
                </HoveringSection>

            </div>
        </div>
    </div>
</template>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

.three-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}
</style>
