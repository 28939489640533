<script>
import axios from "axios";
import ModuleStartButton from "@/components/ModuleStartButton.vue";
import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";
import IntroVideoModal from "@/components/BasicUI/IntroVideoModal.vue";
import JobCard from "@/components/JobCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue"
import Modal from "@/components/BasicUI/Modal.vue";

export default {
    name: 'FutureWork',
    components: {Modal, TopNavbar, JobCard, IntroVideoModal, DescriptionCard, ModuleOverviewCard, ModuleStartButton},
    beforeCreate() {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    //Transfer user to page to fill in user data
                    this.$router.push('/dashboard/precourse')
                }
            })
    },
    created() {
        axios.get('/api/v1/progress/').then(response => {
            this.future_work_intro = response.data.intros.future_work_intro
            this.future_work_video = response.data.videos.future_work_video? 1 : 0
            this.job_security_video = response.data.videos.job_security_video? 1 : 0
            this.user_group = response.data.user_group
        })
    },
    methods: {
        finishCourse() {
            this.show_finish_course_modal = true
        }
    },
    data() {
        return {
            show_finish_course_modal: false,
            user_group: -1,
            intro_modal_active: false,
            future_work_intro: true,
            future_work_video: -1,
            job_security_video: -1,
        }
    }
}
</script>

<template>
    <TopNavbar title="Future Work" back-link="/"/>
    <div class="app-container">
        <div class="app-content-container">
            <div>
                <Modal title="Glückwunsch!" subtitle="Life Design Kurs abgeschlossen."
                       button-text="Zum Zertifikats-Download"
                       :is-active="show_finish_course_modal"
                       @click-button="this.$router.push('/certificate')"
                >
                    <p class="has-text-left"><b>Du hast es geschafft und dir einen sehr guten Überblick darüber
                        verschafft, wo du aktuell im Leben stehst und wo du hin willst!</b>
                        Schau immer wieder mal vorbei, wenn sich etwas neues in
                        deinem Leben ergeben hat und du nochmal eine Bestandsaufnahme machen möchtest. Das Life Cockpit
                        eignet sich zum regelmäßigen Ausfüllen. Wir arbeiten gerade mit Hochdruck an weiteren Kursen und
                        sagen dir Bescheid, sobald sie online sind! Bis bald, dein Yoloa-Team<br/><br></p>
                </Modal>
                <!--<IntroVideoModal module="future_work"
                                 :slide-count="2"
                                 :is-active="intro_modal_active"
                                 @close-modal="this.intro_modal_active = false"
                                 v-if="!future_work_intro"
                >
                    <template v-slot:slide1>
                        <h2 class="subtitle"><b>Wichtige Infos für dich</b></h2>
                        <p class="has-text-left">Unsere Datenbank zu den Berufsfeldern wird stetig ausgebaut und <b>erhebt
                            keinen Anspruch auf
                            Vollständigkeit</b>. Es handelt sich lediglich um ein Angebot zur groben Orientierung. Wenn
                            du
                            genauere
                            Informationen suchst, empfehlen wir dir weitere Websites und Datenbanken, von denen wir
                            dir
                            einige als Ressourcen verlinkt haben (klicke hierzu in der Navigationsleiste auf den
                            Kompass).</p><br/>
                        <p class="has-text-left">Wir haben uns bewusst entschieden, <b>dir nicht jeden einzelnen Job zu
                            zeigen, sondern übergreifende Berufsfelder und Jobfamilien</b>. Deswegen kann es sein, dass
                            manche Aussagen nicht auf jede
                            Untergruppe des Berufsfeldes perfekt
                            zutreffen. Wenn du dich für Berufsfelder und Jobs interessierst, informiere dich bitte im
                            Anschluss an diesen Kurs selbst über die genauen Voraussetzungen.</p>
                    </template>
                </IntroVideoModal>-->
                <!--<DescriptionCard
                        title="Einführung"
                        :show-button="true"
                        @rewatch-intro="() => { this.intro_modal_active = true; this.future_work_intro = false}"
                >
                    In diesem Modul findest du heraus, welchen Einfluss Künstliche Intelligenz auf die Arbeitswelt der
                    Zukunft hat. Du lernst außerdem, wie du Jobs erkennst, die sich leichter oder weniger leicht von KI
                    automatisieren lassen.
                </DescriptionCard>-->

                <ModuleOverviewCard
                        :progress="future_work_video"
                        :previous-module-progress="1"
                        image-url="futurework/handshake.webp"
                        link="/futurework/aiandme"
                        title="KI und Ich"
                        description="Finde heraus, wie KI die Arbeitswelt verändert und welche Jobs sich leichter oder
                            weniger leicht von KI automatisieren lassen."
                />
                <ModuleOverviewCard
                        :progress="job_security_video"
                        :previous-module-progress="future_work_video"
                        link="/futurework/jobsecurity"
                        image-url="/futurework/scales.webp"
                        title="Wird mein Traumjob automatisiert?"
                        description="Lerne zu erkennen, welche Tätigkeiten leicht von KI ersetzt werden können!"
                />
                <!--<ModuleOverviewCard
                        :progress="0"
                        :previous-module-progress="0"
                        link="/futurework/howtoai"
                        image-url="/futurework/ai.webp"
                        title="Wie funktioniert KI?"
                        description="Sammle Ideen für mögliche Karrierepfade bei einem Speed-Dating!"
                />-->
                <!-- only show this to students -->
                <!--<ModuleOverviewCard
                        v-if="user_group === 1"
                        :progress="0"
                        :previous-module-progress="job_security_video"
                        link="/futurework/joboverview"
                        image-url="/futurework/joboverview.png"
                        title="Job-Datenbank"
                        description="Verschaffe dir einen Überblick über eine Vielzahl von Jobs und schätze die Zukunftsfähigkeit mit deinem neuen Wissen ein."
                />-->
                <button v-if="job_security_video" class="button is-primary is-fullwidth"
                        @click="finishCourse">Kurs abschließen
                </button>
            </div>
        </div>
    </div>
</template>
