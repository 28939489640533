import {createStore} from 'vuex'

export default createStore({
    state: {
        //where the home button redirects to
        home_destination: '/',
        is_home_active: true,
        is_profile_active: false,
        is_explore_active: false,
        userGroups: [
            {value: 0, text: "Schüler:in"},
            {value: 4, text: "Auszubildende:r"},
            {value: 1, text: "Student:in"},
            {value: 2, text: "Doktorand:in"},
            {value: 3, text: "Berufsanfänger:in"},
            {value: 5, text: "Berufstätige:r"},
        ],
        ageOptions: [
            {value: -2, text: "Unter 14"},
            {value: 14, text: "14"},
            {value: 15, text: "15"},
            {value: 16, text: "16"},
            {value: 17, text: "17"},
            {value: 18, text: "18"},
            {value: 19, text: "19"},
            {value: 20, text: "20"},
            {value: 21, text: "21"},
            {value: 22, text: "22"},
            {value: 23, text: "23"},
            {value: 24, text: "24"},
            {value: 25, text: "25"},
            {value: 26, text: "26"},
            {value: 27, text: "27"},
            {value: 28, text: "28"},
            {value: 29, text: "29"},
            {value: 30, text: "30"},
            {value: 31, text: "31"},
            {value: 32, text: "32"},
            {value: 33, text: "33"},
            {value: 34, text: "34"},
            {value: 35, text: "35"},
            {value: -3, text: "über 35"},
        ],
        genderOptions: [
            {value: 0, text: "Weiblich"},
            {value: 1, text: "Männlich"},
            {value: 2, text: "Divers"},
            {value: -2, text: "keine Angabe"},
        ],
        schoolTypeOptions: [
            {value: 0, text: "Gymnasium"},
            {value: 1, text: "Realschule"},
            {value: 2, text: "Mittelschule"},
            {value: 3, text: "Berufsschule"},
            {value: 4, text: "FOS / BOS"},
            {value: 5, text: "Sonstiges"},
        ],
        studyStatusOptions: [
            {value: 0, text: "Bachelor"},
            {value: 1, text: "Master"},
            {value: 2, text: "Staatsexamen"},
            {value: 3, text: "Promotion"},
        ],
        ausbildungTypeOptions: [
            {value: 0, text: "Gesundheitswesen"},
            {value: 1, text: "Handwerk"},
            {value: 2, text: "Kaufmännische Berufe"},
            {value: 3, text: "Landwirtschaft und Umwelt"},
            {value: 4, text: "Medien und Kreativwirtschaft"},
            {value: 5, text: "Öffentlicher Dienst"},
            {value: 6, text: "Soziale Berufe"},
            {value: 8, text: "Technische Berufe"},
            {value: 9, text: "Tourismus und Gastronomie"},
            {value: 10, text: "Wissenschaft und Forschung"},
            {value: -2, text: "Sonstiges"},
        ],
        professionalExperienceOptions: [
            {value: 0, text: "< 1 Jahr"},
            {value: 1, text: "1-2 Jahre"},
            {value: 2, text: "3-4 Jahre"},
            {value: 3, text: "Länger als 4 Jahre"},
        ],
        backgroundOptions: [
            {value: 0, text: "Architektur & Design"},
            {value: 1, text: "Biologie & Umweltwissenschaften"},
            {value: 2, text: "BWL / Management"},
            {value: 3, text: "Chemie / Pharmazie"},
            {value: 4, text: "Data Science / Informatik"},
            {value: 5, text: "Erziehung / Sozialwissenschaften"},
            {value: 6, text: "Geistes- / Kulturwissenschaften"},
            {value: 7, text: "Ingenieur- / Maschinenbau"},
            {value: 8, text: "Jura / Politikwissenschaften"},
            {value: 9, text: "Kunst / Musik"},
            {value: 10, text: "Land- & Agrarwissenschaften"},
            {value: 11, text: "Mathematik / Statistik"},
            {value: 12, text: "Medien / Kommunikation"},
            {value: 13, text: "Medizin / Gesundheitswissenschaften"},
            {value: 14, text: "Philosophie / Sprachwissenschaften"},
            {value: 15, text: "Physik / Astronomie"},
            {value: 16, text: "Sportwissenschaften"},
            {value: 17, text: "Tourismus / Hotelmanagement"},
            {value: -2, text: "Sonstiges"},

        ]
    },
    getters: {}
    ,
    mutations: {
        initializeStore(state) {
        }
        ,
        setHomeDestination(state, destination) {
            state.home_destination = destination
        }
        ,
        setProfileActive(state) {
            state.is_profile_active = true
            state.is_explore_active = false
            state.is_home_active = false
        }
        ,
        setHomeActive(state) {
            state.is_profile_active = false
            state.is_explore_active = false
            state.is_home_active = true
        }
        ,
        setExploreActive(state) {
            state.is_profile_active = false
            state.is_explore_active = true
            state.is_home_active = false
        }
        ,
    }
    ,
    actions: {}
    ,
    modules: {}
})
