<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "LifeVisionCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            image-url="futureme/lifevision.png"
            link="/futureme/vision"
            title="Meine Life Vision"
            description="Hör auf dein Bauchgefühl und finde heraus, wie du dir dein zukünftiges Leben vorstellst."
    />
</template>
