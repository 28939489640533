import {flatMap} from "lodash/collection";
import {shuffle} from "lodash";

export const categories = [
    "Anpassungsfähigkeit",
    "Lernmotivation",
    "Kritisches Denken",
    "Offenheit für Vielfalt",
    "Teamfähigkeit",
    "Kommunikationsstärke",
    "Durchhaltevermögen",
    "Empathie",
    "Netzwerk-Fähigkeit",
    "Anwendung von KI",
]

export const category_descriptions = [
    "Deine Fähigkeit, dich gut an neue Situationen oder Herausforderungen anzupassen. Du bist flexibel und kannst dich schnell auf Veränderungen einstellen.",
    "Das beschreibt, wie motiviert du bist, neues Wissen oder neue Fähigkeiten zu erwerben. Du bist neugierig und interessiert daran, dich weiterzuentwickeln.",
    "Das bedeutet, dass du Informationen und Situationen hinterfragst, anstatt sie einfach hinzunehmen. Du denkst selbstständig und triffst fundierte Entscheidungen.",
    "Das heißt, dass du offen und respektvoll gegenüber Menschen mit unterschiedlichen Hintergründen, Meinungen und Lebensweisen bist.",
    "Deine Fähigkeit, effektiv und harmonisch mit anderen zusammenzuarbeiten. Du kannst deine eigenen Fähigkeiten einbringen und die der anderen anerkennen.",
    "Das bedeutet, dass du dich klar und verständlich ausdrücken kannst. Du bist in der Lage, deine Gedanken und Ideen effektiv zu kommunizieren.",
    "Deine Fähigkeit, bei einer Aufgabe oder einem Ziel dranzubleiben, auch wenn es schwierig wird. Du gibst nicht auf und findest Wege, Herausforderungen zu meistern.",
    "Deine Fähigkeit, die Gefühle und Bedürfnisse anderer Menschen zu verstehen und darauf einzugehen. Du kannst dich in andere hineinversetzen.",
    "Das beschreibt, wie gut du darin bist, Kontakte zu knüpfen und Beziehungen zu pflegen. Du verstehst, wie wichtig ein gutes Netzwerk für persönlichen und beruflichen Erfolg sein kann.",
    "Das bedeutet, dass du Grundkenntnisse im Umgang mit künstlicher Intelligenz hast. Du verstehst, wie man KI-Tools nutzen kann und bist dir der Chancen und Risiken bewusst."
];
export function categories_with_descriptions() {
    return categories.map((cat, i) => ({
        name: cat,
        description: category_descriptions[i]
    }));
}

const raw_questions = [
    {
        category: categories[0],
        questions: [
            "In meinem Freundeskreis bin ich dafür bekannt, mit neuen Aufgaben extrem gut umgehen zu können und mich rasch einzuarbeiten.",
            "Ich finde es super spannend, regelmäßig ganz neue Herausforderungen angehen zu können. Ich traue mir zu, auch Aufgaben lösen zu können, die ich zuvor noch nie gemacht habe, das ist nur eine Frage der Übung.",
            "Ich probiere sowohl privat wie beruflich sehr gern und regelmäßig neue Dinge aus.",
            "Ich habe in der Vergangenheit mehrfach bewiesen, dass ich überhaupt kein Problem damit habe, ins kalte Wasser geworfen zu werden",
            "Mit den bisherigen Veränderungen in meinem Leben wie Schulwechsel, Auslandsaufenthalt, Umzüge oder neue Freundeskreise bin ich immer gut zurecht gekommen. Ich habe das immer auch als Chance gesehen, etwas Neues kennenzulernen und mich neu erfinden zu können."
        ]
    },
    {
        category: categories[1],
        questions: [
            "Wenn ich neuen Menschen begegne, dann überlege ich immer, was ich von ihnen lernen kann.",
            "Ich habe mich in letzter Zeit gezielt damit auseinandergesetzt, welches Wissen ich mir eigenverantwortlich aneignen kann, damit ich mich am Puls der Zeit bewege.",
            "Ich nehme mir mehrmals pro Woche die Zeit, gute Artikel oder Bücher zu lesen oder höre/schaue mir inspirierende Podcasts und Videos an mit dem Ziel, mich weiterzuentwickeln und etwas Neues zu lernen.",
            "Ich trage ein hohes Maß an Eigenverantwortung, dass ich das Richtige zum richtigen Zeitpunkt lerne und anwenden kann, das ist nicht nur Aufgabe der Lehrer*innen, Professor*innen oder Arbeitgeber*innen.",
            "Ich finde es ein total schönes Gefühl, wenn ich mich weiterentwickeln und etwas Neues lernen kann. Ist das zu lange nicht der Fall, dann werde ich unruhig und suche mir Gelgenheiten, Neues zu lernen."
        ]
    },
    {
        category: categories[2],
        questions: [
            "Wenn ich einen Artikel in der Zeitung oder Inhalte auf Social Media lese, überlege ich regelmäßig, ob ich den Standpunkt so vertrete oder man auch eine ganz andere Meinung als der Autor vertreten könnte.",
            "Wenn ich über Social Media Inhalte angezeigt bekomme, bin ich mir vollkommen bewusst, wie Algorithmen die mir angezeigten Inhalte steuern und dass ich nicht alles glauben sollte, was mir da angezeigt wird.",
            "Ich widerspreche meinen Freunden, Bekannten oder Kollegen, wenn ich eine andere Meinung habe. Nur weil man befreundet ist oder zusammen arbeitet, heißt das ja nicht, dass man immer der gleichen Meinung sein muss.",
            "Ich war schon in der Schule oder Hochschule dafür bekannt, meinen Lehrer*innen und Mitschüler*innen in Diskussionen durchaus kritische Fragen zu stellen.",
            "Wenn ich mir eine Meinung zu einem bestimmten Thema bilde, ist es meine Angewohnheit, das Thema stets aus unterschiedlichen Blickwinkeln zu betrachten und erst dann zu einer eigenen Haltung zu kommen."
        ]
    },
    {
        category: categories[3],
        questions: [
            "Ich habe gute Freunde, die aus einem völlig anderen Umfeld kommen als ich und andere Blickwinkel auf die Welt besitzen als ich.",
            "Ich bin mir bewusst, dass jeder Mensch Vorbehalte und Vorurteile in sich trägt; ich weiß, dass ich selbst auch nicht frei davon bin.",
            "Meine Freunde bewundern an mir, wie offen und unvoreingenommen ich mit Menschen umgehe, die anders sind als ich selbst.",
            "Ich habe persönlich schon mehrfach die Erfahrung gemacht, wie bereichernd es sein kann, mit Menschen zu sprechen, die älter, jünger, erfahrener, klüger oder anders denkend sind als ich. Ich habe das Gefühl, von jedem kann ich etwas lernen.",
            "Ich bin in meinem Leben schon oft in neue soziale Umgebungen eingetaucht und mit gut mit den Menschen klar gekommen."
        ]
    },
    {
        category: categories[4],
        questions: [
            "Es fällt auf, dass ich sowohl beruflich als auch privat gerne von anderen um Rat und Unterstützung gefragt werde.",
            "Wenn ich vor einem Problem stehe, dann weiß ich, dass ich das Problem besser lösen kann, wenn ich mir von anderen ihre Meinung oder auch Unterstützung einhole.",
            "Ich bin dafür bekannt, dass ich gut teilen kann.",
            "Andere würden zustimmen, dass ich sehr rücksichtsvoll und empathisch mit anderen Menschen umgehe.",
            "Ich bin dafür bekannt, dass ich mich gut in ein Team einfügen kann, auch wenn das bedeutet, mich selbst nicht immer an die erste Stelle zu setzen."
        ]
    },
    {
        category: categories[5],
        questions: [
            "Ich habe schon mehrfach das positive Feedback erhalten, mich richtig gut ausdrücken zu können – sowohl schriftlich als auch mündlich.",
            "In einem Team bin ich oft die Person, die ausgewählt wird, um die Ideen vor anderen zu präsentieren, weil ich das sehr gut kann.",
            "Ich genieße es, mich kontinuierlich darin zu verbessern, wie man Dinge sowohl schriftlich als auch mündlich extrem gut ausdrückt.",
            "Ich habe bemerkt, dass ich auch Menschen, die mit meinen Themen bislang keine Berührungspunkte hatten, so gut erklären kann, dass sie diese verstehen.",
            "Ich sage offen, was Sache ist. Das ist mir lieber, als Dinge nicht anzusprechen."
        ]
    },
    {
        category: categories[6],
        questions: [
            "Ich lebe nach dem Leitsatz: „Vor dem Erfolg, kommt die Anstrengung“ und ich hatte bereits viele Situationen, wo ich dies erfolgreich umgesetzt habe.",
            "Ich bin bei meinen Freunden bekannt dafür, mich mächtig ins Zeug zu legen, wenn ich etwas wirklich erreichen will.",
            "Mir fallen sofort mehrere Beispiele aus meinem bisherigen Leben ein, bei denen ich mal aufgeben wollte und dann doch weitergemacht habe, um meine Ziele zu erreichen.",
            "Ich habe bislang immer einen Weg gefunden, Widerstände zu überwinden.",
            "Ich mag es nicht, aufzugeben und kann eine ausgeprägte Sturheit entwickeln, meinen Weg weiter zu verfolgen."
        ]
    },
    {
        category: categories[7],
        questions: [
            "Ich erkenne schon oft an anderen Menschen, wie es ihnen geht, bevor diese es ansprechen.",
            "Ich bin im Freundes- und Bekanntenkreis dafür bekannt, mich um die Bedürfnisse anderer besonders gut zu kümmern.",
            "Ich frage proaktiv meine Mitmenschen, wie es ihnen geht, gerade wenn ich ihnen ansehe, dass etwas nicht stimmt.",
            "Ich bin total an anderen Menschen interessiert.",
            "Es bewegt mich sehr, wenn es anderen Menschen nicht gut geht; auch solchen, die nicht zu meinem direkten Umfeld zählen."
        ]
    },
    {
        category: categories[8],
        questions: [
            "In jeder Lebensphase habe ich Menschen kennen- und schätzen gelernt, mit denen ich heute noch verbunden bin.",
            "Ich weiß, wie hoch die Bedeutung von persönlichen Netzwerken ist. Deswegen investiere ich jede Woche bewusst Zeit, diese Netzwerke zu pflegen.",
            "Ich liebe es, neue Menschen kennenzulernen und auf unbekannte Gesichter offen zuzugehen. Das ist eine echte Stärke von mir.",
            "Ich bin dafür bekannt, dass meine Freunde und Bekannten sich absolut auf mich verlassen können.",
            "Ich gebe sehr gerne. Ich gebe auch dann, wenn ich nicht direkt eine Gegenleistung dafür bekomme."
        ]
    },
    {
        category: categories[9],
        questions: [
            "Ich liebe es, mit neuen KI-Tools „herumzuspielen.“",
            "Ich bin im Freundes- und Bekanntenkreis dafür bekannt, bei KI-Themen gut im Bilde zu sein und werde von ihnen um Rat gefragt.",
            "Ich wende mehrere Stunden pro Woche dafür auf, mit den neuen KI-Tools wie ChatGPT, MidJourney, Dall-E herumzuspielen und weiß, was ich damit anfangen kann.",
            "Ich weiß, dass künstliche Intelligenz große Chancen, aber auch Risiken wie Datenschutz und fehlerhafte Ergebnisse mit sich bringt.",
            "Ich werde permanent besser darin, mit KI-Tools meine Aufgaben zu erledigen."
        ]
    }
];

const questions = flatMap(raw_questions, cat => {
    return cat.questions.map(q => ({
        text: q,
        category: cat.category
    }));
});

export default function shuffled_questions() {
    return shuffle(questions);
}
