import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import axios from 'axios'

import * as SuperTokens from "supertokens-web-js";

import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import EmailVerification from "supertokens-web-js/recipe/emailverification"
import Session from "supertokens-web-js/recipe/session";

const apiDomain = process.env.VUE_APP_API_BASE_URL;

SuperTokens.init({
    appInfo: {
        appName: "Yoloa",
        apiDomain: apiDomain,
        defaultLanguage: 'de',
    },
    recipeList: [
        ThirdPartyEmailPassword.init(),
        Session.init(),
        EmailVerification.init({
            mode: "REQUIRED", // or "OPTIONAL"
        }),
    ],
});

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faArrowUp,
    faHeart,
    faTimes,
    faArrowDown,
    faGripLines,
    faPlus,
    faMinus,
    faHome,
    faCompass,
    faUser,
    faCircleInfo,
    faCaretDown,
    faCaretUp,
    faArrowLeft,
    faPodcast,
} from '@fortawesome/free-solid-svg-icons'
import {faApple, faGoogle, faSpotify} from "@fortawesome/free-brands-svg-icons";
/* add icons to the library */
library.add(faArrowUp, faArrowDown, faArrowLeft, faApple, faPodcast, faGoogle, faSpotify, faGripLines, faPlus, faMinus, faHome, faCompass, faUser, faTimes, faHeart, faCircleInfo, faCaretDown, faCaretUp)

axios.defaults.baseURL = apiDomain

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router, axios)
    .mount('#app')
