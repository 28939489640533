<script>
import axios from "axios";
import {getValueNameFromId, values_numbered} from "@/assets/value-list";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ImageCard from "@/components/ImageCard.vue";
import ValueCard from "@/components/ValueCard.vue";
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";
import Draggable from "vuedraggable";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import HoveringSection from "@/components/BasicUI/HoveringSection.vue";
import Modal from "@/components/BasicUI/Modal.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    name: 'ValueExercise',
    components: {
        DescriptionCard,
        ExerciseIntro,
        Modal, HoveringSection, TopNavbar, ProgressBar, ValueCard, ImageCard, FontAwesomeIcon, Draggable
    },
    data() {
        return {
            isDragging: false,
            stage: 0,
            max_reps: 30,
            group_size: 4,
            top_value_count: 6,
            current_rep: -1,
            value_list: [],
            top_value_list: [],
            current_values: [],
            previous_values: [],
            saved_values: [],
            show_modal: false,
        }
    },
    created() {
        // Add initial elo ratings to cockpit_values
        this.value_list = this.value_array.map(
            (value) => ({...value, elo: 1000, times_shown: 0, image_source: '/values/' + value.id + '.webp'})
        )
        this.getNextValues()
    },
    mounted() {
        axios.get('/api/v1/progress/').then(response => {
            if (response.data.future_me.core_values === 1) {
                this.stage = 3
                this.revisited = true
                axios.get('/api/v1/corevalues/').then((response) => {
                    console.log(response.data)
                    this.saved_values = response.data.sort(this.compareRanks).map((value) => ({
                        ...value,
                        image_source: '/values/' + value.value_id + '.webp'
                    }))
                })
            }
        })
    },
    methods: {
        getValueNameFromId,
        compareRanks(a, b) {
            return parseInt(a.rank) - parseInt(b.rank)
        },
        updateElo(winner_id, all_competitors) {
            let index = 0
            const K = 32

            all_competitors.forEach((value) => {
                const old_elo = value.elo
                let all_indexes = [...Array(this.group_size).keys()]
                let expected_score = 0
                all_indexes.filter(i => i !== index).forEach(i => {
                    expected_score += this.singleExpectedScore(all_competitors[i].elo, old_elo)
                })
                expected_score /= 6

                // Weighting function
                let S = 0
                if (value.id === winner_id) {
                    S = 1
                }
                value.elo = old_elo + K * (S - expected_score)
                if (value.id === winner_id) {
                }
                index++
            })

            this.getNextValues()
        },
        singleExpectedScore(opponent_elo, current_elo) {
            const D = 400
            return (1 / (1 + Math.pow(10, opponent_elo - current_elo) / D))
        },
        restartExercise() {
            this.stage = 0
            this.revisited = false
            this.saved_values = []
            this.top_value_list = []
        },
        nextStage() {
            if (this.stage === 1) {
                this.value_list = this.value_list.sort(this.sortByElo).reverse()
                this.top_value_list = this.value_list.slice(0, this.top_value_count)
            }
            if (this.stage === 2) {
                this.submitCoreValues()
            }
            this.stage++;
        },
        sortByElo(a, b) {
            return a.elo - b.elo
        },
        getNextValues() {
            if (this.current_rep === this.max_reps - 1) {
                this.nextStage()
            } else {
                this.previous_values = this.current_values
                const matched_values = this.createMatchup()
                matched_values.forEach(value => {
                    value.times_shown++
                })
                this.current_rep++
                this.current_values = matched_values
            }
        },
        createMatchup() {
            const previous_four_ids = this.previous_values.map((value) => value.id)
            const filtered_list = this.value_list.filter((value) => !previous_four_ids.includes(value.id))
            const weights = filtered_list.map((item) => (item.times_shown === 0 ? 200 : Math.floor(100 / item.times_shown)))
            const indices = this.weightedRandomNElements(filtered_list, weights, this.group_size)
            return filtered_list.filter((item) => indices.includes(item.id))
        },
        weightedRandomNElements(items, weights, number) {
            if (items.length !== weights.length) {
                throw new Error('Items and weights must be of the same size');
            }

            if (!items.length) {
                throw new Error('Items must not be empty');
            }

            let indices = []
            for (let j = 0; j < number; j += 1) {
                // Preparing the cumulative weights array.
                // For example:
                // - weights = [1, 4, 3]
                // - cumulativeWeights = [1, 5, 8]
                const cumulativeWeights = [];
                for (let i = 0; i < weights.length; i += 1) {
                    cumulativeWeights[i] = weights[i] + (cumulativeWeights[i - 1] || 0);
                }

                // Getting the random number in a range of [0...sum(weights)]
                // For example:
                // - weights = [1, 4, 3]
                // - maxCumulativeWeight = 8
                // - range for the random number is [0...8]
                const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1];
                const randomNumber = maxCumulativeWeight * Math.random();

                let pickedItemId = 0;
                // Picking the random item based on its weight.
                // The items with higher weight will be picked more often.
                for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
                    if (cumulativeWeights[itemIndex] >= randomNumber) {
                        pickedItemId = items[itemIndex].id
                        break;
                    }
                }

                if (indices.includes(pickedItemId)) {
                    number += 1
                } else {
                    indices.push(pickedItemId)
                }
            }
            return indices
        },
        submitCoreValues() {

            //Clear old core cockpit_values from database
            axios.get('/api/v1/clearcorevalues/').then((response) => {
                let index = 0;
                this.top_value_list.forEach((value) => {
                    const data = {
                        value_id: value.id,
                        rank: index,
                    }
                    // Last request triggers update of the core cockpit_values
                    axios.post('/api/v1/corevalues/', data).then((response) => {
                        if (data.rank === this.top_value_list.length - 1) {
                            axios.get('/api/v1/corevalues/').then((response) => {
                                this.saved_values = this.top_value_list.sort(this.compareRanks).map((value) => ({
                                    ...value,
                                    value_id: value.id
                                }));
                            })
                        }
                    })
                    index++;
                })
            })

        },
    },
    computed: {
        value_array() {
            return values_numbered
        },
        buttonClass() {
            return this.has_selected_all_favourites ? 'is-primary' : 'hidden';
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    }
}
</script>

<template>
    <TopNavbar title="Mein Wertekompass"/>
    <div class="app-container">
        <div class="app-content-container">
            <div v-if="stage === 0">
                <ExerciseIntro module-name="compass" @intro-finished="nextStage"/>
                <button class="button is-fullwidth is-primary mt-4" @click="nextStage">Los geht's</button>
            </div>
            <div v-else-if="stage == 1">
                <ProgressBar classname="is-info" :progress="current_rep*100/max_reps"/>
                <p class="mb-4 has-text-centered is-size-6">Egal ob es sympathisch klingt oder nicht: Überleg, was dich
                    wirklich antreibt.</p>
                <div class="grid">
                    <div v-for="(value, i) in current_values">
                        <ValueCard :url="value.image_source"
                                   :name="value.name"
                                   @value-selected="updateElo(value.id, current_values)"/>
                    </div>
                </div>
            </div>
            <div v-else-if="stage == 2">
                <DescriptionCard title="Wichtigste Werte">
                    Hier findest du deine {{ top_value_list.length }} wichtigsten Werte. Du kannst sie durch
                    Klicken und
                    Ziehen noch in der Reihenfolge ändern, falls dir ein bestimmter Wert wichtiger oder unwichtiger
                    vorkommt.
                </DescriptionCard>
                <Draggable
                        class="list-group"
                        tag="transition-group"
                        :component-data="{
                    tag: 'ul',
                    type: 'transition-group',
                     name: !drag ? 'flip-list' : null
                     }"
                        v-model="top_value_list"
                        v-bind="dragOptions"
                        @start="isDragging = true"
                        @end="isDragging = false">
                    <template #item="{element}">
                        <div class="card cursor-pointer mb-4">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-left">
                                        <img src="../../assets/images/dragdots.svg" width="30"
                                             style="margin-left: -10px; margin-top: 1px;"/>
                                    </div>
                                    <div class="media-content">
                                        <p>{{ element.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Draggable>

                <Modal title="Übung fertig!" subtitle="Du hast deine persönlichen Werte bestimmt."
                       :is-active="show_modal"
                       @click-button="nextStage"/>

                <button class="button is-primary is-fullwidth" @click="this.show_modal = true">Weiter</button>
            </div>
            <div v-else>
                <DescriptionCard title="Die Bedeutung von Werten">
                    <p>Die Menschen, die in Übereinstimmung mit ihren persönlichen Werten leben, sind am glücklichsten.
                        Dauerhaft gegen deine Werte handeln zu müssen, kann unzufrieden und sogar krank machen.
                        Überprüfe also regelmäßig mithilfe des Life Cockpit (nächste Übung), ob du deine Werte im Alltag
                        leben kannst.</p>
                </DescriptionCard>
                <div class="grid">
                    <div v-for="(core_value, index) in saved_values">
                        <ValueCard :url="core_value.image_source"
                                   :name="getValueNameFromId(core_value.value_id)"/>
                    </div>
                </div>
                <HoveringSection>
                    <a href="/futureme" class="button mt-4 is-primary center-block is-fullwidth">Zurück zur
                        Übersicht</a>
                    <button @click="restartExercise" class="button mt-4 center-block is-fullwidth" v-if="revisited">
                        Übung
                        neu
                        starten
                    </button>
                </HoveringSection>
            </div>
        </div>
    </div>
</template>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

.three-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.hidden {
    display: none;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 15px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}
</style>
