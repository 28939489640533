<script>
import JobCard from "@/components/JobCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import ExerciseIntro from "@/components/ExerciseIntro.vue";
import axios from "axios";
import Modal from "@/components/BasicUI/Modal.vue";
import {questions} from "@/assets/aiandme_questions";
import MCQuiz from "@/components/MCQuiz.vue";

export default {
  name: 'FutureWorkVideo',
  mounted() {
    setTimeout(() => {
      this.video_disabled = false
    }, 3000, this)
  },
  computed: {},
  methods: {
    markVideoCompleted() {
      const data = {
        future_work_video: true
      }

      axios.post('/api/v1/introprogress/', data).then((result) => {
        console.log(result.data)
      })

      this.show_modal = true
    },
    resetStage() {
      this.stage = 0
    },
    nextStage() {
      if (this.stage === 1) {
        this.markVideoCompleted()
      }

      this.stage++
    }
  },
  components: {MCQuiz, Modal, ExerciseIntro, TopNavbar, JobCard},
  data() {
    return {
      playbackRate: 1.0,
      video_disabled: true,
      show_modal: false,
      stage: 0,
      questions: questions
    }
  },
  directives: {
    playbackRate(el, binding) {
      el.playbackRate = binding.value;
    }
  }
}
</script>

<template>
  <TopNavbar title="KI und Ich"/>
  <div class="app-container">
    <div class="app-content-container">
      <div v-if="stage === 0">
        <p>In diesem Video erklären wir dir, welche Fähigkeiten du brauchst, um in der Zukunft erfolgreich im
          Beruf zu sein.</p>
        <div class="is-flex is-justify-content-center mb-3 mt-3">
          <div class="buttons has-addons">
            <button class="button is-light" :class="playbackRate === 1.0? 'is-active' : ''"
                    @click="playbackRate = 1.0">x 1.0
            </button>
            <button class="button is-light" :class="playbackRate === 1.25? 'is-active' : ''"
                    @click="playbackRate = 1.25">x 1.25
            </button>
            <button class="button is-light" :class="playbackRate === 1.5? 'is-active' : ''"
                    @click="playbackRate = 1.5">x 1.5
            </button>
            <button class="button is-light" :class="playbackRate === 1.75? 'is-active' : ''"
                    @click="playbackRate = 1.75">x 1.75
            </button>
            <button class="button is-light" :class="playbackRate === 2.0? 'is-active' : ''"
                    @click="playbackRate = 2.0">x 2.0
            </button>
          </div>
        </div>
        <div class="columns is-mobile is-centered">
          <div class="column">
            <video type="video/quicktime" controls v-playback-rate="playbackRate">
              <source :src="'/media/ki-auf-dem-arbeitsmarkt.webm'" type="video/mp4">
              <source :src="'/media/ki-auf-dem-arbeitsmarkt.mp4'" type="video/mp4">
              Dein Browser unterstützt keine Videos. Bitte nutze einen anderen Browser.
            </video>
          </div>
        </div>
        <button class="button is-primary is-fullwidth mt-3" :disabled="video_disabled" @click="nextStage">
          Weiter zum Quiz
        </button>
      </div>
      <div v-else-if="stage === 1">
        <MCQuiz :questions="questions" @quiz-success="nextStage" @video-restart="resetStage"/>
      </div>

      <Modal title="Übung fertig!" subtitle="Im nächsten Modul lernst du, KI-sichere Jobs zu erkennen."
             :is-active="show_modal"
             button-text="Zur Übersicht"
             @click-button="this.$router.push('/futurework/')"/>

    </div>
  </div>
</template>

<style scoped>
.notification {
  position: fixed;
  top: 200px;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
}


.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>
