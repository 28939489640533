<script>
import axios from "axios";
import ModuleStartButton from "@/components/ModuleStartButton.vue";
import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import LifeCockpitCard from "@/components/ModuleOverviewCards/futureme/LifeCockpitCard.vue";
import CalendarExerciseCard from "@/components/ModuleOverviewCards/futureme/CalendarExerciseCard.vue";
import SkillSpiderwebCard from "@/components/ModuleOverviewCards/futureskills/SkillSpiderwebCard.vue";
import FutureMeCard from "@/components/ModuleOverviewCards/lifedesign/FutureMeCard.vue";
import FutureSkillsExercise from "@/views/futureskills/FutureSkillsExercise.vue";
import FutureWorkCard from "@/components/ModuleOverviewCards/lifedesign/FutureWorkCard.vue";
import FutureSkillsCard from "@/components/ModuleOverviewCards/lifedesign/FutureSkillsCard.vue";
import CourseOverview from "@/views/dashboard/LifeDesignOverview.vue";
import CourseOverviewCard from "@/components/CourseOverviewCards/CourseOverviewCard.vue";
import IntroVideoModal from "@/components/BasicUI/IntroVideoModal.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    name: 'Dashboard',
    components: {
        DescriptionCard,
        IntroVideoModal,
        CourseOverviewCard,
        CourseOverview,
        FutureSkillsCard,
        FutureWorkCard,
        FutureSkillsExercise,
        FutureMeCard,
        SkillSpiderwebCard,
        CalendarExerciseCard, LifeCockpitCard, TopNavbar, ModuleOverviewCard, ModuleStartButton
    },
    beforeRouteEnter(to, from, next) {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    next('/dashboard/precourse')
                } else {
                    next()
                }
            })
            .catch(error => {
                console.error('Error checking user information:', error)
                next(error)
            })
    },
    async created() {
        try {
            const response = await axios.get('/api/v1/progress/')

            this.user_group = response.data.user_group
            this.firstname = response.data.firstname
            this.future_me = response.data.future_me
            this.future_skills = response.data.future_skills
            this.future_work = response.data.future_work
            this.intros = response.data.intros
            this.videos = response.data.videos
            this.total_progress = response.data.total_progress

            // Show the general intro if it hasn't been shown yet
            if (!this.intros.general_intro) {
                this.intro_modal_active = true
            }

            console.log(this.intros)
        } catch (error) {
            console.error('Error fetching progress data:', error)
            // Handle the error appropriately
        }
    },
    data() {
        return {
            intro_modal_active: false,
            user_group: 0,
            firstname: '',
            future_me: {
                progress: -1,
                vision_board: 0,
                core_values: 0,
                life_cockpit: 0,
                calendar: 0,
            },
            future_skills: {
                progress: -1,
                future_skills_quiz: 0,
            },
            future_work: {
                progress: -1,
            },
            intros: {
                general_intro: false,
                future_me_intro: false,
                future_skills_intro: false,
                future_work_intro: false,
            },
            videos: {
                future_me_video: false,
                future_skills_video: false,
                future_work_video: false,
            },
            total_progress: 0,
        }
    },
    methods: {
        redirectToCertificate() {
            this.$router.push('/certificate')
        },
        redirectToModuleOverview() {
            if (this.future_me.progress < 1) {
                this.$router.push("/futureme")
            } else if (this.future_skills.progress < 1) {
                this.$router.push("/futureskills")
            } else if (this.future_work.progress < 1) {
                this.$router.push("/futurework")
            } else {
                this.$router.push("/lifedesign")
            }
        }
    },
    computed: {
        quoteOfTheDay() {
            // generate a random number to decide what quote to show
            const random = Math.floor(Math.random() * 6)
            switch (random) {
                case 0:
                    return '„Erfolg bedeutet nicht, die Erwartungen anderer zu erfüllen. Es geht darum, deinen eigenen gerecht zu werden.“ – Adam Grant'
                case 1:
                    return '„Oft bist Du nicht müde, weil Du zu viel getan hast – sondern weil Du zu wenig von dem getan hast, was Dich innerlich zum Funkeln bringt.“ – Alexander den Heijer'
                case 2:
                    return '„Wenn jemand dir sagt, dass es nicht machbar ist, zeigt das eher seine Grenzen – nicht deine.“'
                case 3:
                    return '„Der einzige Weg, deine Grenzen zu finden, ist, über sie hinauszugehen.“ – Arthur C. Clarke'
                case 4:
                    return '„Deine Zukunft wird durch das bestimmt, was du heute tust, nicht morgen.“'
                case 5:
                    return '„Hindernisse sind diese furchterregenden Sachen, die du dann siehst, wenn du dein Ziel aus den Augen verlierst.“ – Henry Ford'
                case 6:
                    return '„Erfolg ist nicht endgültig, Misserfolg ist nicht fatal: Es ist der Mut zum Weitermachen, der zählt.“ – Winston Churchill'
            }
        },
        nextModuleLink() {
            if (this.future_me.progress < 1) {
                const prefix = '/futureme/'
                if (!this.intros.future_me_intro) {
                    return '/futureme'
                } else if (this.future_me.vision_board < 1) {
                    return prefix + 'vision'
                } else if (this.future_me.core_values < 1) {
                    return prefix + 'values'
                } else if (this.future_me.life_cockpit < 1) {
                    return prefix + 'cockpit'
                } else {
                    return prefix + 'calendar'
                }
            } else if (this.future_skills.progress < 1) {
                const prefix = '/futureskills/'
                if (!this.intros.future_skills_intro) {
                    return prefix
                } else if (!this.videos.future_skills_video) {
                    return prefix + 'video'
                } else if (this.future_skills.future_skills_quiz < 1) {
                    return prefix + 'kompetenzspinne'
                }
            } else if (this.future_work.progress < 1) {
                const prefix = '/futurework/'
                if (!this.intros.future_work_intro) {
                    return prefix
                }
            } else {
                return '/certificate'
            }
        },
    }
}
</script>

<template>
  <!--<TopNavbar title="Dashboard" />-->
    <div class="app-container dashboard-container grey-container-box">
        <div class="app-content-container-no-nav">
            <h2 class="title" v-show="firstname">Hi, {{ firstname }}!</h2>
            <p class="subtitle is-6">
                Du hast nur ein Leben – mach deins draus.
            </p>
            <IntroVideoModal module="general" :is-active="intro_modal_active"
                             @close-modal="this.intro_modal_active = false"
                             slide-count="2">
                <template v-slot:slide1>
                    <h2 class="subtitle"><b>Disclaimer</b></h2>
                    <p class="has-text-left">Der Life Design Kurs beruht auf einer bewährten Methodik. Es handelt sich
                        dabei aber nur um ein Hilfsmittel, das du nutzen kannst, um deine persönlichen Entscheidungen zu
                        treffen. Wir sind nicht dafür verantwortlich, welche eigenen Entscheidungen du auf Basis dieser
                        App und ihrer Inhalte triffst und was das für Auswirkungen hat.</p><br/>
                    <p class="has-text-left"><b>Wir bieten auch keine psychologische Einschätzung und Beratung an.</b>
                        Bitte
                        triff deine Entscheidungen nach sorgfältiger Recherche und Abwägung. Wenn du psychologische
                        Unterstützung brauchst, such dir Hilfe bei dir nahestehenden Personen oder von professioneller
                        Seite.</p>
                </template>
            </IntroVideoModal>
            <div v-if="future_skills.progress !== -1">
                <div v-if="future_me.progress < 1">
                    <FutureMeCard :progress="future_me.progress"
                                  previous-module-progress="1"
                                  :link="nextModuleLink"
                    />

                </div>
                <div v-else-if="future_skills.progress < 1">
                    <FutureSkillsCard :progress="future_skills.progress"
                                      :previous-module-progress="future_me.progress"
                                      :link="nextModuleLink"
                    />
                </div>
                <div v-else-if="future_work.progress < 1">
                    <FutureWorkCard :progress="future_work.progress"
                                    :previous-module-progress="future_skills.progress"
                                    :link="nextModuleLink"
                    />
                </div>
            </div>
            <button @click="redirectToModuleOverview" class="button is-primary is-fullwidth">
                <span v-if="future_work.progress < 1">
                Zur Modulübersicht
                </span>
                <span v-else>
                    Zur Kursübersicht
                </span>
            </button>
            <button v-if="future_work.progress == 1" @click="redirectToCertificate" class="button is-info is-fullwidth mt-4">
                Kurs-Zertifikat herunterladen
            </button>
        </div>
    </div>
    <div class="app-container" style="margin-top: -50px">
        <div class="app-content-container-no-nav">
            <DescriptionCard title="Zitat">
                {{ quoteOfTheDay }}
            </DescriptionCard>
            <h2 class="title is-3">Deine Kurse</h2>
            <div class="scrolling-wrapper">
                <div class="scrolling-card mr-5">
                    <CourseOverviewCard
                            title="Life Design Kurs"
                            link="/lifedesign"
                            image-url="lifedesign.webp"
                            :progress="total_progress"/>
                </div>
                <div class="scrolling-card">
                    <CourseOverviewCard
                            title="Skill Bootcamp"
                            image-url="skillsbootcamp.webp"
                            :disabled="true"
                            progress="0"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import '../../assets/color-overrides.scss';

.scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .scrolling-card {
        flex: 0 0 auto;
        min-width: 240px;
    }
}

.dashboard-container {
    background-color: $base-grey;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
</style>
