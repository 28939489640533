<script>
export default {
    name: 'ValueCard',
    props: {
        url: String,
        id: Number,
        name: String,
        isHighlightable: Boolean,
        maxReached: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            isSelected: false
        }
    },
    methods: {
        selectValue() {
            this.$emit('value-selected')
        },
    }
}
</script>

<template>
    <div class="card" @click="selectValue">
        <div class="card-content vision-image">
            <div class="has-text-centered">
                <img :src="url"/>
            </div>
            <div class="text-container">
                <div style="display: flex">
                    <h5 class="is-size-7 is-size-7-mobile has-text-centered mt-3"><b>{{ name }}</b></h5>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.vision-image {
  background-repeat: no-repeat;
  border-radius: 20px;
  background-size: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.text-container {
  min-height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.vision-image img {
  border-radius: 20px;
  height: auto;
  width: 100%;
  max-width: 250px;
  top: 0;
  left: 0;
}
</style>
