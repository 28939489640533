<script>

export default {
    name: "HeaderLogo"
}
</script>

<template>
    <div class="is-text-centered" @click="this.$router.push('/')">
        <img src="../../assets/images/yoloa-logo.svg" class="logo mb-4" alt="Logo von Yoloa" />
    </div>
</template>

<style scoped lang="scss">
</style>
