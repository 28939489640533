export default function getAuthTranslation(key)
{
    return translations[key]
}

const translations = {
    "This email already exists. Please sign in instead.": "Diese Email gibt's schon. Bitte melde dich an.",
    "Field is not optional": "Dieses Feld ist nicht optional",
    "Password must contain at least 8 characters, including a number": "Passwort muss mindestens 8 Zeichen haben, inklusive einer Zahl",
    "Password's length must be lesser than 100 characters": "Das Passwort darf nicht länger als 100 Zeichen sein",
    "Password must contain at least one alphabet": "Passwort muss mindestens einen Buchstaben enthalten",
    "Password must contain at least one number": "Passwort muss mindestens eine Zahl enthalten",
    "Email is not valid": "Ungültige Email",
    "Reset password link was not created because of account take over risk. Please contact support. (ERR_CODE_001)": "Link zum Zurücksetzen des Passworts wurde aufgrund eines Übernahme-Risikos des Kontos nicht erstellt. Bitte wende dich an den Support. (ERR_CODE_001)",
    "Cannot sign up due to security reasons. Please try logging in, use a different login method or contact support. (ERR_CODE_007)": "Registrierung aufgrund von Sicherheitsgründen nicht möglich. Bitte versuche dich anzumelden, nutze eine andere Anmeldemethode oder wende dich an den Support. (ERR_CODE_007)",
    "Cannot sign in due to security reasons. Please try resetting your password, use a different login method or contact support. (ERR_CODE_008)": "Anmeldung aufgrund von Sicherheitsgründen nicht möglich. Bitte versuche dein Passwort zurückzusetzen, nutze eine andere Anmeldemethode oder wende dich an den Support. (ERR_CODE_008)",
}
