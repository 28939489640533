<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "FutureWorkCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
        link: {
            type: String,
            default: '/futurework',
        }
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            image-url="futurework.png"
            :link="link"
            title="Future Work"
            :is-exercise-card="false"
            description="Finde heraus, wie sich die Arbeitswelt der Zukunft verändert."
    />
</template>
