<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "Navbar",
    components: {FontAwesomeIcon},
    data() {
        return {
            showNavbar: true,
            lastScrollPosition: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    computed: {
        homeClass() {
            return this.$store.state.is_home_active ? 'icon-active' : ''
        },
        userClass() {
            return this.$store.state.is_profile_active ? 'icon-active' : ''
        },
        exploreClass() {
            return this.$store.state.is_explore_active ? 'icon-active' : ''
        },
    },
    methods: {
        onScroll() {
            // Get the current scroll position
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return
            }
            // Here we determine whether we need to show or hide the navbar
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            // Set the current scroll position as the last scroll position
            this.lastScrollPosition = currentScrollPosition
        },
        directHome() {
            this.$store.commit('setHomeActive')
            this.$router.push(this.$store.state.home_destination)
        },
        directProfile() {
            this.$store.commit('setProfileActive')
            this.$router.push('/profile')
        },
        directExplore() {
            this.$store.commit('setExploreActive')
            this.$router.push('/explore')
        }
    }
}
</script>

<template>
    <nav class="navbar is-fixed-bottom-touch is-hidden-desktop custom-navbar"
         :class="{ 'navbar--hidden': !showNavbar }">
        <div class="touchbar-content">
            <div @click="directHome" class="nav-icon" :class="homeClass">
                <font-awesome-icon :icon="['fas', 'home']" size="xl"/>
            </div>
            <div @click="directExplore" class="nav-icon" :class="exploreClass">
                <font-awesome-icon :icon="['fas', 'compass']" size="xl"/>
            </div>
            <div @click="directProfile" class="nav-icon" :class="userClass">
                <font-awesome-icon :icon="['fas', 'user']" size="xl"/>
            </div>
        </div>
    </nav>
    <nav class="navbar is-fixed-bottom custom-navbar is-hidden-touch" role="navigation" aria-label="main navigation">
        <div class="navbar-menu is-active">
            <div class="navbar-center">
                <a class="navbar-item" @click="directHome" :class="homeClass">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'home']" size="xl"/>
                    </span>
                    <span>&nbsp; Home</span>
                  </span>
                </a>
                <a class="navbar-item" @click="directExplore" :class="exploreClass">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'compass']" size="xl"/>
                    </span>
                    <span>&nbsp; Inspirationen</span>
                  </span>
                </a>
                <a class="navbar-item" @click="directProfile" :class="userClass">
                  <span class="icon-text">
                    <span class="icon">
                          <font-awesome-icon :icon="['fas', 'user']" size="xl"/>
                    </span>
                    <span>&nbsp;Mein Profil</span>
                  </span>
                </a>
            </div>
        </div>
    </nav>
</template>

<style scoped lang="scss">
.custom-navbar {
  border-top: solid rgb(230, 230, 230) 1px;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  box-shadow: 0 2px 10px rgba(120, 120, 120, 0.5);
}

.navbar-center {
display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-center .navbar-item {
  margin-right: 3rem; /* Adjust the spacing to your preference */
}

/* Remove margin for the last item to maintain symmetry */
.navbar-center .navbar-item:last-child {
  margin-right: 0;
}

.navbar-menu {
  justify-content: center; /* Center the items in the navbar */
  min-height: 70px;
}

.custom-navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, 100%, 0);
}

.nav-icon {
  color: rgb(210, 210, 210);
  cursor: pointer;
}

.icon-active {
  color: rgb(30, 30, 30)
}

.touchbar-content {
  padding-top: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
