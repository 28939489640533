<script>

export default {
    name: 'HoveringSection',
    props: {
        isClickable: false,
        infoText: '',
    },
    computed: {
        buttonClassname() {
            return this.isClickable ? 'is-primary' : ''
        }
    },
    methods: {
        emit_click_event() {
            if (this.isClickable) {
                this.$emit('click-button')
            }
        },
    },
    emits: ['click-button']
}
</script>

<template>
    <div class="hovering-section">
        <div class="section-content">
            <slot class="section-slot">
                <p class="mb-3 has-text-centered has-text-info"><span class="text-background" v-html="infoText"></span>
                </p>
                <button class="button is-fullwidth" :class="buttonClassname" :disabled="!isClickable"
                        @click="emit_click_event">Fertig
                </button>
            </slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../../assets/yoloa.scss';

.hovering-section {
  position: fixed;
  width: 100%;
  bottom: 80px;
  left: 0;
  right: 0;
  height: 150px;
}

.section-content {
  max-width: $app-container-width;
  margin: auto;
    padding-left: $app-container-padding;
    padding-right: $app-container-padding;
}

.text-background {
  padding: 10px 20px 10px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
}
</style>
