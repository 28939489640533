<script>

export default {
    name: "InfoTooltip",
    props: {
        title: String,
        text: String,
        direction: {
            type: String,
            default: 'right'
        }
    }
}
</script>

<template>
    <font-awesome-icon :icon="['fas', 'circle-info']" class="has-tooltip-arrow" v-if="false"
                       :class="'has-tooltip-text-' + direction" :data-tooltip="text"/>
</template>

<style scoped lang="scss">

</style>
