<script>
import axios from "axios";
import ProgressBar from "@/components/BasicUI/ProgressBar.vue";

export default {
    name: 'IntroVideoModal',
    components: {ProgressBar},
    props: {
        module: {
            default: 'future_me',
            type: String,
        },
        classname: {
            default: "is-primary",
            type: String
        },
        isActive: {
            default: false,
            type: Boolean,
        },
        slideCount: {
            default: 1,
            type: Number,
        },
    },
    data() {
        return {
            currentSlide: 0,
        }
    },
    methods: {
        incrementSlide() {
            this.currentSlide++;
        },
        saveIntroProgress() {
            let data;
            if (this.module === 'future_me') {
                data = {
                    future_me_intro: true,
                }
            } else if (this.module === 'future_skills') {
                data = {
                    future_skills_intro: true,
                }
            } else if (this.module === 'future_work') {
                data = {
                    future_work_intro: true,
                }
            } else {
                data = {
                    general_intro: true,
                }
            }
            axios.post('/api/v1/introprogress/', data).then((result) => {
                this.$emit('close-modal')
                console.log(result)
                this.currentSlide = 0;
            })
        },
    },
    computed: {
        source() {
            switch (this.module) {
                case 'future_me':
                    return '/media/futureme'
                case 'future_skills':
                    return '/media/futureskills'
                case 'future_work':
                    return '/media/futurework'
                default:
                    return '/media/introduction'
            }
        },
        poster_source() {
            switch (this.module) {
                case 'future_me':
                    return '/illustrations/futureme.png'
                case 'future_skills':
                    return '/illustrations/futureme.png'
                case 'future_work':
                    return '/illustrations/futureme.png'
                default:
                    return '/illustrations/lifedesign-transparent.webp'

            }
        },
        title() {
            switch (this.module) {
                case 'future_me':
                    return 'Future Me: Einführung'
                case 'future_skills':
                    return 'Future Skills: Einführung'
                case 'future_work':
                    return 'Future Work: Einführung'
                default:
                    return 'Schnelle Einführung'
            }
        },
        description() {
            switch (this.module) {
                case 'future_me':
                    return 'In diesem Modul geht es ganz um dich und die Frage, von was für einem Leben du träumst und was du brauchst, um später glücklich und zufrieden zu sein. Denn um dich zu entscheiden, welchen beruflichen Weg du einschlagen möchtest, musst du dich erst einmal selber besser kennenlernen.'
                case 'future_skills':
                    return 'Wie gut bist du darauf vorbereitet, tatsächlich ins Berufsleben einzusteigen? In diesem Modul lernst du, welche Fähigkeiten in Zukunft gefragt sein werden und woran du zukunftssichere Jobs erkennst – und findest heraus, wie gut deine eigenen Future Skills aktuell sind.'
                case 'future_work':
                    return 'Mithilfe deiner Vorarbeit suchst du in diesem letzten Kursmodul deinen zukünftigen Traumjob: Finde heraus, welche Vorstellungen und Anforderungen du dafür mitbringst, erkunde verschiedene Berufsfelder und deren Hauptmerkmale und erstelle am Ende deine persönliche Job-Wunschliste.'
                default:
                    return 'Willkommen in der Yoloa Life Design App! In diesem kurzen Video verraten dir, worum es geht und wie die App funktioniert.'
            }
        }
    },
    emits: ['close-modal']
}
</script>

<template>
    <div class="modal modal-lg" :class="isActive? 'is-active' : ''">
        <div class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body">
                <p class="title is-4">{{ title }}</p>
                <ProgressBar :progress="currentSlide === 0? 0 : ((currentSlide + 1) / slideCount) * 100" v-if="slideCount > 2"/>
                <div v-if="currentSlide === 0">
                    <video class="mb-3" type="video/quicktime" :poster="poster_source" controls>
                        <source :src="source + '.webm'" type="video/mp4">
                        <source :src="source + '.mp4'" type="video/mp4">
                        Dein Browser unterstützt keine Videos. Bitte nutze einen anderen Browser.
                    </video>
                    <p class="subtitle is-6 has-text-left">{{ description }}</p>
                </div>
                <div v-for="slide in slideCount-1">
                    <slot :name="'slide' + slide" v-if="currentSlide === slide" class="has-text-left">

                    </slot>
                </div>
                <div v-if="currentSlide === slideCount - 1">
                    <button class="button is-fullwidth mt-4" :class="classname" @click="saveIntroProgress">Einführung
                        abschließen
                    </button>
                </div>
                <div v-else-if="slideCount > 1">
                    <button class="button is-fullwidth mt-5" :class="classname" @click="incrementSlide">Weiter
                    </button>
                </div>
            </section>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="this.$emit('close-modal')"></button>
    </div>
</template>

<style scoped lang="scss">
.modal-card {
  padding-left: 20px;
  padding-right: 20px;
}

.modal-card-body {
  border-radius: 20px;
  text-align: center;
}
</style>
