<template>
    <TopNavbar title="Admin-Übersicht"/>
    <div class="app-container">
        <div class="app-content-container">
            <div class="box">
                <h3 class="title is-5">Modulabschlussstatistiken</h3>
                <div class="select">
                    <select v-model="selectedUserGroup" @change="fetchDebouncedDashboardData">
                        <option value="-1">Alle Benutzergruppen</option>
                        <option v-for="group in userGroups" :key="group.value" :value="group.value">{{
                            group.text
                            }}
                        </option>
                    </select>
                </div>
                <canvas ref="moduleCompletionChartCanvas"></canvas>
                <p v-if="selectedUserGroupStats">Durchschnittsalter: {{ selectedUserGroupStats.average_age }}</p>
            </div>
            <div class="box">
                <h3 class="title is-5">Nutzerverteilung</h3>
                <canvas ref="userGroupChartCanvas" id="userGroupChart"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import {mapState} from "vuex";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import _ from 'lodash';

export default {
    name: 'AdminPanel',
    components: {
        TopNavbar,
    },
    created() {
        this.fetchDashboardData();
        this.fetchUserGroupData(); // Fetch user group data for the chart
    },
    mounted() {
        this.renderModuleCompletionChart()
    },
    data() {
        return {
            selectedUserGroup: -1,
            dashboardData: null,
            userGroupChart: null, // Add userGroupChart data property
            userGroupData: null,
            moduleCompletionChart: null, // New chart object
        }
    },
    methods: {
        async fetchDebouncedDashboardData() {
            _.debounce(this.fetchDashboardData, 300);
        },
        async fetchDashboardData() {
            const params = this.selectedUserGroup ? {userGroup: this.selectedUserGroup} : {};
            try {
                const response = await axios.get('/api/v1/admin/dashboard/', {params});
                this.dashboardData = response.data;
                this.$nextTick(() => {
                    this.renderModuleCompletionChart();
                });
            } catch (error) {
                console.error("Failed to fetch dashboard data:", error);
            }
        },
        async fetchUserGroupData() {
            try {
                const response = await axios.get('/api/v1/user-group-distribution/');
                this.userGroupData = response.data;
                this.renderChart();
            } catch (error) {
                console.error("Failed to fetch user group data:", error);
                // Handle error appropriately
            }
        },
        renderChart() {
            if (!this.userGroupData) return;

            const chartData = {
                labels: Object.keys(this.userGroupData),
                datasets: [{
                    data: Object.values(this.userGroupData),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }],
            };

            const config = {
                type: 'doughnut',
                data: chartData,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Verteilung der Benutzergruppen',
                        },
                    },
                },
            };

            if (this.userGroupChart) {
                this.userGroupChart.destroy();
            }

            const ctx = this.$refs.userGroupChartCanvas.getContext('2d');
            this.userGroupChart = new Chart(ctx, config);
        },
        renderModuleCompletionChart() {
            this.$nextTick(() => {
                if (!this.dashboardData || !this.dashboardData.module_completion || !this.$refs.moduleCompletionChartCanvas) return;

                // If the chart instance already exists, destroy it before creating a new one
                if (this.moduleCompletionChart) {
                    this.moduleCompletionChart.destroy();
                    this.moduleCompletionChart = null; // Reset the chart instance to null after destroying it
                }

                // Ensure the canvas element is accessible through refs and the getContext method can be called
                const ctx = this.$refs.moduleCompletionChartCanvas.getContext('2d');
                if (!ctx) return; // Guard clause if for any reason the context could not be retrieved

                const moduleNames = Object.keys(this.dashboardData.module_completion);
                let completionRates = [];

                // Check if 'selectedUserGroup' is set to view aggregated stats
                if (this.selectedUserGroup == -1) {
                    // Use aggregated data
                    completionRates = moduleNames.map(moduleName => {
                        return this.dashboardData.module_completion[moduleName]['overall'] || 0;
                    });
                } else {
                    // Use specific user group data
                    completionRates = moduleNames.map(moduleName => {
                        const groupRate = this.dashboardData.module_completion[moduleName][this.selectedUserGroup];
                        return groupRate ? groupRate : 0;
                    });
                }

                const chartData = {
                    labels: ['Life Vision', 'Core Values', 'Life Cockpit', 'Kalender', 'Kompetenzspinne'],
                    datasets: [{
                        label: 'Modulabschlussrate (%)',
                        data: completionRates,
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    }],
                };

                const config = {
                    type: 'bar',
                    data: chartData,
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: 'Abschlussrate (%)'
                                }
                            }
                        },
                        responsive: true,
                    },
                };

                // Creating a new chart instance
                this.moduleCompletionChart = new Chart(ctx, config);
            });
        },
    },
    computed: {
        ...mapState(['userGroups']),
        selectedUserGroupStats() {
            if (!this.dashboardData || !this.dashboardData.age_statistics_per_group) return null;

            if (this.selectedUserGroup) {
                // Return stats for the selected group
                return this.dashboardData.age_statistics_per_group.find(stats => stats.user_group === this.selectedUserGroup) || null;
            } else {
                // Calculate and return aggregated stats
                const totalAge = this.dashboardData.age_statistics_per_group.reduce((acc, cur) => acc + cur.average_age, 0);
                const count = this.dashboardData.age_statistics_per_group.length;
                return {
                    average_age: count > 0 ? (totalAge / count).toFixed(2) : 0
                };
            }
        }
    }
}
</script>

<style>
#userGroupChart {
    max-width: 300px;
    margin: 0 auto 0 auto;
}
</style>
