<template>
    <div class="app-container">
        <div class="app-content-container">
            <div @click="this.$router.push('/')" style="cursor: pointer;" class="is-text-centered">
                <img src="../assets/images/yoloa-logo.svg" class="logo mb-4"/><br/>
                <button class="button has-text">Zurück zur Startseite</button>
                <br/><br/>
            </div>

            <h1 class="title is-text-centered">Datenschutzerklärung</h1>

            <ul>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Allgemeine Hinweise</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="1"><span>Diese Datenschutzerklärung enthält ausführliche Informationen darüber, was mit Ihren persönlichen Daten passiert, wenn Sie unsere Website yoloa.de oder eine ihrer Subdomains (Unterseiten) besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie sich persönlich identifizieren können. Wir halten uns bei der Verarbeitung Ihrer Daten streng an die gesetzlichen Bestimmungen, insbesondere die Datenschutzgrundverordnung („DSGVO“), und legen großen Wert darauf, dass Ihr Besuch auf unserer Website absolut sicher ist.</span><br>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Verantwortliche Stelle</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="2"><span>Datenschutzrechtlich verantwortlich für die Erhebung und Verarbeitung von personenbezogenen Daten auf dieser Website ist:</span><br><br><strong>Name: </strong>Yoloa
                    GmbH<br><br><strong>Straße, Hausnummer: </strong>Planegger Straße 22g<br><strong>Postleitzahl,
                        Ort: </strong>82131 Gauting<br><strong>Land: </strong>Deutschland<br><strong>E-Mail: </strong>kontakt@yoloa.de<br><strong>Tel.: </strong>+49
                    1579 2487 321<br>
                </li>
                <!--<li class="lhl" style="margin-bottom:18.0pt;">
                    <p><strong>Präambel: Unser Verständnis von Datenschutz</strong>
                    </p>
                </li>
                <li>
                    <p>
                        Wir sind uns der besonderen Sensibilität und des Vertrauens bewusst, das Sie uns
                        entgegenbringen,
                        indem Sie unsere App nutzen und dabei personenbezogene sowie potenziell sensible Daten mit uns
                        teilen. Der
                        Schutz Ihrer Daten hat für uns höchste Priorität. Aus diesem Grund haben wir umfassende
                        technische
                        und organisatorische Maßnahmen ergriffen, um sicherzustellen, dass Ihre Daten sicher und
                        verschlüsselt gespeichert werden.<br /><br/></p>
                    <p>
                        <b>Ihre personenbezogenen Daten verbleiben stets in Deutschland, genauer gesagt am Serverstandort Nürnberg. Sie
                        werden zu
                        keinem Zeitpunkt außerhalb dieses Standortes übertragen oder verarbeitet. Dies gibt Ihnen die
                        Gewissheit, dass Ihre personenbezogenen Daten unter den strengen Datenschutzrichtlinien Deutschlands behandelt
                        werden.</b><br /><br />
                    </p>
                    <p>
                        Wir laden Sie ein, unsere Datenschutzerklärung aufmerksam zu lesen, um ein klares Verständnis
                        dafür
                        zu bekommen, wie wir Ihre Daten erfassen, verarbeiten und schützen. Bei Fragen oder Bedenken
                        stehen
                        wir Ihnen selbstverständlich zur Verfügung.<br /><br /></p></li>-->
                <li class="lhl" style="margin-bottom:18.0pt;">
                    <p><strong>Cookies</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="3"><span>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Cookies können keine Programme ausführen oder Viren auf Ihr Computersystem übertragen.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="4"><span>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir haben ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung unserer Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="5"><span>Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="6"><span>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</span><br>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;">
                    <p><strong>Fotos auf Community Events</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="3"><span>
                Zur Öffentlichkeitsarbeit, Darstellung der Aktivitäten von Yoloa und Erhöhung des Bekanntheitsgrad,
                fertigen wir auf den Community Events an und veröffentlichen diese auf der Website und in Social Media
                Kanälen. Dies ist ein berechtigtes Interesse nach iSd Art 6 Abs 1 lit f DSGVO sowie §§ 12, 13 DSG. Sie
                haben das Recht, gegen die Verarbeitung Widerspruch zu erheben – mündlich auf dem Events oder per E-Mail
                an datenschutz@yoloa.de. Sofern aus besonders berücksichtigungswürdigen Gründen die Rechte und Freiheiten einer
                abgebildeten Person verletzt sein sollten, werden wir durch geeignete Maßnahmen die weitere Verarbeitung
                unterlassen. Eine Unkenntlichmachung in Printmedien, die bereits ausgegeben sind, kann nicht erfolgen.
                Eine Löschung auf der Website oder in Social Media Kanälen erfolgt im Rahmen der technischen
                    Möglichkeiten.</span></li>
                <li class="lh" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Webanalyse Tools und Werbung</strong>
                    </p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Newsletter</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="7"><span>Sofern Sie ausdrücklich eingewilligt haben, senden wir an Ihre E-Mail-Adresse regelmäßig unseren Newsletter. Zum Erhalt unseres Newsletters müssen Sie uns Ihre E-Mail-Adresse mitteilen und sie anschließend verifizieren. Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt ausschließlich für den Versand des Newsletters.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="8"><span>Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail oder Sie melden sich über den „Austragen“-Link im Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="9"><span>Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.</span><br>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Kontaktformular</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="10"><span>Sofern Sie mit uns Kontakt per E-Mail oder über ein Kontaktformular aufnehmen, werden übermittelte Daten einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="11"><span>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="12"><span>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</span><br>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong>Speicherung und Verarbeitung von Daten durch Dritte</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="12">
                    <p>Wir nutzen für die Speicherung und Verarbeitung Ihrer
                        Daten die Dienste der Firma Hetzner Online GmbH.
                        Unsere Daten werden auf Servern im Standort Nürnberg, Deutschland, gehostet. Hetzner ist ein
                        renommierter Hosting-Anbieter, der alle notwendigen technischen und organisatorischen Maßnahmen
                        trifft,
                        um Ihre Daten sicher zu verarbeiten. Um sicherzustellen, dass Hetzner Ihre Daten im Einklang mit
                        der
                        Datenschutz-Grundverordnung (DSGVO) und nur gemäß unseren Anweisungen verarbeitet, haben wir
                        eine
                        Vereinbarung zur Auftragsverarbeitung (AVV) mit Hetzner abgeschlossen.

                        Weitere Informationen zu den Datenschutzmaßnahmen von Hetzner und die Details unserer
                        Vereinbarung
                        können Sie in deren Datenschutzerklärung und den zugehörigen Dokumenten einsehen:
                        <ul>
                            <li><a href="https://www.hetzner.com/de/legal/privacy-policy" target="_blank">Datenschutzerklärung
                                von Hetzner</a></li>
                            <li><a href="/auftragsverarbeitung.pdf" target="_blank">AVV-Dokument</a></li>
                        </ul>

                    </p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Nutzerkonto</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="17">
                    Wenn Sie ein Nutzerkonto eröffnen, willigen Sie damit ein, dass Ihre Bestandsdaten wie Name, Adresse
                    und E-Mail-Adresse sowie Ihre Nutzungsdaten (Benutzername, Passwort) gespeichert werden. Dadurch
                    haben Sie die Möglichkeit, sich mit Ihrer E-Mail-Adresse sowie Ihrem persönlichen Passwort bei uns
                    einzuloggen.

                    Zusätzlich zu den oben genannten Daten werden durch das Ausfüllen von Übungen in der App
                    möglicherweise sensible Daten erfasst und gespeichert. Diese Daten werden streng vertraulich
                    behandelt und nur für den in dieser Datenschutzerklärung beschriebenen Zweck (z.B. Bereitstellung
                    einer Selbsteinschätzung) verwendet. Eine Weitergabe dieser sensiblen
                    Daten an Dritte erfolgt nicht, es sei denn, wir sind gesetzlich dazu verpflichtet oder Sie haben uns
                    vorher
                    Ihre Zustimmung gegeben.

                    Wir legen großen Wert auf den Schutz Ihrer Daten, insbesondere Ihrer sensiblen Daten (siehe
                    Abschnitt "Umgang mit sensiblen Daten"). Alle
                    gesammelten Daten werden sicher und entsprechend den geltenden Datenschutzbestimmungen gespeichert
                    und verarbeitet.
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Datenverwendung und -weitergabe</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="18"><span>Die personenbezogenen Daten, die Sie uns z.B. per E-Mail oder bei Benutzung der App mitteilen (z.B. Ihr Name oder Ihre E-Mail-Adresse), werden wir weder an Dritte verkaufen noch anderweitig vermarkten. Ihre personenbezogenen Daten werden nur zur Korrespondenz mit Ihnen und nur für den Zweck verarbeitet, zu dem Sie uns die Daten zur Verfügung gestellt haben.</span><br>
                    <p style="text-align:left;"><br>Die Verwendung von Daten, die bei Ihrem Besuch unserer Website
                        automatisch erhoben werden, erfolgt nur zu den vorstehend genannten Zwecken. Eine anderweitige
                        Verwendung der Daten findet nicht statt.
                    </p>
                    <p style="text-align:left;"><br/>Wir versichern, dass wir Ihre personenbezogenen Daten im Übrigen
                        nicht
                        an
                        Dritte weitergeben, es sei denn, dass wir dazu gesetzlich verpflichtet wären oder Sie uns vorher
                        Ihre Zustimmung gegeben haben.
                    </p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong>Anonymisierung Ihrer Daten</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="18">
                    <p style="text-align:left;">
                        In einigen Fällen anonymisieren wir personenbezogene Daten, die wir über Sie gesammelt haben, um
                        sie für statistische Analysen, Berichte oder andere Zwecke zu verwenden. Anonymisierung
                        bedeutet, dass Ihre Daten so bearbeitet werden, dass sie nicht mehr Ihnen oder einer anderen
                        konkreten Person zugeordnet werden können und nicht mehr als personenbezogene Daten gelten. Dies
                        ermöglicht es uns, Trends und Muster in unseren Daten zu erkennen, ohne Ihre Privatsphäre zu
                        beeinträchtigen.

                        Es ist wichtig zu beachten, dass einmal anonymisierte Daten nicht rückgängig gemacht und nicht
                        wieder zu personenbezogenen Daten gemacht werden können. Diese anonymisierten Daten werden
                        getrennt von Ihren personenbezogenen Daten gespeichert und sind nicht mit diesen verknüpft.

                    </p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong>Verkauf anonymisierter Daten</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="18">
                    <p style="text-align:left;">
                        Wir möchten Sie darüber informieren, dass wir in einigen Fällen entscheiden können,
                        anonymisierte Daten an Dritte zu verkaufen. Da diese Daten vollständig anonymisiert sind, können
                        sie nicht zu Ihnen oder einer anderen konkreten Person zurückverfolgt werden. Dieser Verkauf
                        dient dazu, unser Geschäftsmodell zu unterstützen und sicherzustellen, dass wir weiterhin
                        Dienstleistungen anbieten können. Wir verkaufen diese Daten nur an vertrauenswürdige Dritte, die
                        sie in Übereinstimmung mit geltenden Datenschutzgesetzen verwenden.
                    </p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong>Umgang mit sensiblen Daten</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="18">
                    <p style="text-align:left;">
                        Im Rahmen der Nutzung unserer App bitten wir Sie um die Eingabe bestimmter Daten, die als
                        "sensible
                        Daten" gelten können. Hierzu zählen insbesondere Informationen aus Fragebögen zu Ihren
                        persönlichen
                        Werten.</p>

                    <p>Zweck der Datenerhebung: Diese Daten werden erhoben, um Ihnen mittels spezialisierter
                        Auswertungstools
                        eine Selbsteinschätzung bieten zu können.</p>

                    <p>Schutz Ihrer Daten: Wir sind uns der besonderen Sensibilität dieser Daten bewusst und setzen
                        hohe
                        Standards in Bezug auf Datensicherheit und Datenschutz. Ihre sensiblen Daten werden mit höchster
                        Sorgfalt und unter Verwendung modernster Sicherheitstechnologien behandelt.</p>

                    <p>Anonymisierung: In bestimmten Fällen könnten wir beabsichtigen, die von Ihnen angegebenen Daten
                        zu
                        anonymisieren, um sie für interne Analysen und Berichte zu verwenden. Diese Daten können nicht
                        dazu
                        verwendet werden, Sie als Individuum zu identifizieren.</p>

                    <p>Weitergabe und Verkauf: Es sei denn, Sie geben uns Ihre ausdrückliche Zustimmung oder es besteht
                        eine
                        gesetzliche Verpflichtung, werden Ihre sensiblen Daten nicht an Dritte weitergegeben oder
                        verkauft.</p>

                    <p>Ihre Rechte: Sie haben das Recht, Auskunft über die von uns gespeicherten sensiblen Daten zu
                        erhalten,
                        sie zu korrigieren, zu löschen oder deren Verarbeitung einzuschränken. Weiterhin können Sie der
                        Verarbeitung widersprechen und haben das Recht auf Datenübertragbarkeit. Bitte kontaktieren Sie
                        uns,
                        wenn Sie von Ihren Rechten Gebrauch machen möchten.</p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong>Datenschutz für Minderjährige</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="19">
                    <p>Wir nehmen den Schutz von Daten Minderjähriger sehr ernst. Nutzer unter 16 Jahren dürfen unsere
                        App
                        nicht nutzen.</p>

                    <p>Altersüberprüfung: Bei der Erstellung eines Kontos oder bei der ersten Nutzung unserer App fragen
                        wir
                        das Alter des Nutzers ab. Nutzer unter 16 Jahren werden darauf hingewiesen, dass sie unsere
                        Dienste nicht nutzen dürfen und der Zugang wird ihnen verweigert.</p>
                    <p>Rechte von Minderjährigen: Minderjährige Nutzer, die das Alter von 16 Jahren erreicht haben,
                        haben das
                        Recht, ihre datenschutzrechtlichen Rechte in Anspruch zu nehmen, einschließlich ihres Rechts auf
                        Löschung von Daten oder ihres Rechts, eine erteilte Zustimmung zurückzuziehen.</p>
                    <p>Schutz von Daten Minderjähriger: Wir setzen alle uns zur Verfügung stehenden technischen und
                        organisatorischen Maßnahmen ein, um die Daten von Minderjährigen sicher zu verarbeiten und zu
                        schützen.</p>
                    <p>Weiterführende Informationen: Wir ermutigen Eltern und Erziehungsberechtigte dazu, sich aktiv mit
                        den
                        Online-Aktivitäten ihrer Kinder auseinanderzusetzen und sie über den sicheren und
                        verantwortungsvollen
                        Umgang mit ihren persönlichen Daten im Internet zu informieren.</p>
                </li>
                <li class="lhl" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>SSL- bzw. TLS-Verschlüsselung</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="19"><span>Unsere Website benutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://” auf „https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</span><br>
                </li>
                <li style="margin-bottom:18.0pt;" value="20"><span>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</span><br>
                </li>
                <li class="lh" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Speicherdauer</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="21"><span>Personenbezogene Daten, die uns über unsere Website mitgeteilt worden sind, werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu dem sie uns anvertraut wurden. Soweit handels- und steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.</span><br>
                </li>
                <li class="lh" style="margin-bottom:18.0pt;"><span> </span>
                    <p><strong> </strong><strong>Betroffenenrechte</strong>
                    </p>
                </li>
                <li>
                    <p>Hinsichtlich der Sie betreffenden personenbezogenen Daten haben als Betroffener der
                        Datenverarbeitung
                        nach Maßgabe der gesetzlichen Bestimmungen folgende Rechte gegenüber dem
                        Verantwortlichen:<br><br/>
                    </p>
                    <ol start="1" class=" subnumberedList">
                        <li style="margin-bottom:18.0pt;" value="1"><span> </span>
                            <p><strong>Widerrufsrecht</strong><br>
                            </p>
                            <p style="text-align:left;">Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                                ausdrücklichen
                                Einwilligung möglich. Sofern die Verarbeitung Ihrer Daten auf Ihrer Einwilligung beruht,
                                haben Sie das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten
                                gemäß
                                Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den
                                Widerruf
                                der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                                erfolgten Verarbeitung nicht berührt. Speicherung der Daten für Abrechnungs- und
                                buchhalterische Zwecke bleibt von einem Widerruf nicht berührt.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="2"><span> </span>
                            <p><strong>Auskunftsrecht</strong><br>
                            </p>
                            <p>Sie haben das Recht, gemäß Art. 15 DSGVO von uns eine Bestätigung darüber zu verlangen,
                                ob
                                wir personenbezogene Daten verarbeiten, die Sie betreffen. Liegt eine solche
                                Verarbeitung
                                vor, haben Sie das Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen
                                Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen
                                Daten,
                                die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
                                wurden
                                oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der
                                Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
                                Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde,
                                die
                                Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen
                                einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
                                aussagekräftige
                                Informationen über die involvierte Logik und die Sie betreffende Tragweite und die
                                angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung,
                                welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
                                bestehen.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="3"><span> </span>
                            <p><strong>Recht auf Berichtigung</strong><br>
                            </p>
                            <p style="text-align:left;">Sie haben das Recht, gemäß Art. 16 DSGVO jederzeit unverzügliche
                                Berichtigung Sie betreffender unrichtiger personenbezogener Daten und/oder die
                                Vervollständigung Ihrer unvollständigen Daten zu verlangen.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="4"><span> </span>
                            <p><strong>Recht auf Löschung</strong><br>
                            </p>
                            <p style="text-align:left;">Sie haben das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer
                                personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe zutrifft:
                            </p>
                            <ol start="1" class=" recital">
                                <li style="margin-bottom:18.0pt;" value="1"><span> </span>
                                    <p>Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf
                                        sonstige Weise verarbeitet wurden, nicht mehr notwendig;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="2"><span> </span>
                                    <p>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs.
                                        1
                                        lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer
                                        anderweitigen
                                        Rechtsgrundlage für die Verarbeitung;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="3"><span> </span>
                                    <p>Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und
                                        es
                                        liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie
                                        legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="4"><span> </span>
                                    <p>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="5"><span> </span>
                                    <p>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                                        Verpflichtung nach dem Unionsrecht oder dem Recht des Mitgliedstaates
                                        erforderlich,
                                        dem wir unterliegen;
                                    </p>
                                </li>
                                <li style="margin-bottom:0.0pt;" value="6"><span> </span>
                                    <p>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                                        Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben;
                                    </p>
                                </li>
                            </ol>
                            <p>Dieses Recht besteht jedoch nicht, soweit die Verarbeitung erforderlich ist:
                            </p>
                            <ol start="1">
                                <li style="margin-bottom:18.0pt;" value="1"><span> </span>
                                    <p>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="2"><span> </span>
                                    <p>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem
                                        Recht
                                        der Union oder des Mitgliedstaates, dem wir unterliegen, erfordert, oder zur
                                        Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
                                        öffentlicher Gewalt erfolgt, die uns übertragen wurde;
                                    </p>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="3"><span> </span>
                                    <p>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit
                                        gemäß
                                        Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                                    </p>
                                </li>
                                <li style="margin-bottom:0.0pt;" value="4"><span> </span>
                                    <p>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                                        historische Forschungszwecke oder für statistische Zwecke gemäß Art. 89 Abs. 1
                                        DSGVO, soweit das Betroffenenrecht voraussichtlich die Verwirklichung der Ziele
                                        dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder<br>zur
                                        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                                    </p>
                                </li>
                            </ol>
                            <p style="text-align:left;">Haben wir Ihre personenbezogenen Daten öffentlich gemacht und
                                sind
                                wir nach Vorstehendem zu deren Löschung verpflichtet, so treffen wir unter
                                Berücksichtigung
                                der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
                                technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen
                                Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die
                                Löschung aller Links zu Ihren personenbezogenen Daten oder von Kopien oder Replikationen
                                dieser personenbezogenen Daten verlangt haben.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="5"><span> </span>
                            <p><strong>Recht auf Einschränkung der Verarbeitung</strong><br>
                            </p>
                            <p style="text-align:left;">Sie haben das Recht, gemäß Art. 18 DSGVO die Einschränkung der
                                Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie
                                sich
                                jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
                                Einschränkung der Verarbeitung besteht in folgenden Fällen:<br>
                            </p>
                            <ol start="1" class=" recital">
                                <li style="margin-bottom:18.0pt;" value="1"><span>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </span><br>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="2"><span>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen. </span><br>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="3"><span>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </span><br>
                                </li>
                                <li style="margin-bottom:0.0pt;" value="4"><span>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</span><br>
                                </li>
                            </ol>
                            <p style="text-align:left;">Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                                eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
                                Ihrer
                                Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                oder
                                zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
                                Gründen
                                eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
                                verarbeitet werden.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="6"><span> </span>
                            <p><strong>Recht auf Unterrichtung</strong><br>
                            </p>
                            <p style="text-align:left;">Haben Sie das Recht auf Berichtigung, Löschung oder
                                Einschränkung
                                der Verarbeitung gegenüber uns geltend gemacht, sind wir verpflichtet, allen Empfängern,
                                denen Ihre personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung
                                der
                                Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich
                                als
                                unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gemäß
                                Art.
                                19 DSGVO das Recht zu, nach Verlangen über diese Empfänger unterrichtet zu werden.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="7"><span> </span>
                            <p><strong>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung –
                                einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden</strong><br>
                            </p>
                            <p style="text-align:left;">Sie haben das Recht, gemäß Art. 22 DSGVO nicht einer
                                ausschließlich
                                auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden
                                Entscheidung
                                unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in
                                ähnlicher Weise erheblich beeinträchtigt.
                            </p>
                            <p style="text-align:left;">Dies gilt nicht, wenn die Entscheidung
                            </p>
                            <ol start="1" class=" recital">
                                <li style="margin-bottom:18.0pt;" value="1"><span>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,</span><br>
                                </li>
                                <li style="margin-bottom:18.0pt;" value="2"><span>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</span><br>
                                </li>
                                <li style="margin-bottom:0.0pt;" value="3"><span>mit Ihrer ausdrücklichen Einwilligung erfolgt.</span><br>
                                </li>
                            </ol>
                            <p style="text-align:left;">Allerdings dürfen die Entscheidungen in den in (a) bis (c)
                                genannten
                                Fällen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO
                                beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder lit. g gilt und angemessene Maßnahmen
                                zum
                                Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
                            </p>
                            <p style="text-align:left;">In den in (a) und (c) genannten Fällen treffen wir angemessene
                                Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren,
                                wozu
                                mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
                                Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der
                                Entscheidung
                                gehört.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="8"><span> </span>
                            <p><strong>Recht auf Datenübertragbarkeit</strong><br>
                            </p>
                            <p style="text-align:left;">Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6 Abs.
                                1
                                lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1
                                lit.
                                b DSGVO beruht und mithilfe automatisierter Verfahren erfolgt, haben Sie das Recht,
                                gemäß
                                Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                                strukturierten, gängigen und maschinenlesebaren Format zu erhalten und einem anderen
                                Verantwortlichen zu übermitteln oder die Übermittlung an einen anderen Verantwortlichen
                                zu
                                verlangen, soweit dies technisch machbar ist.
                            </p>
                        </li>
                        <li style="margin-bottom:18.0pt;" value="9"><span> </span>
                            <p><strong>Widerspruchsrecht</strong><br>
                            </p>
                            <p style="text-align:left;">Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf
                                die
                                Interessenabwägung nach Art. 6 Abs. 1 lit. f DSGVO stützen, haben Sie jederzeit das
                                Recht,
                                aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung
                                Ihrer
                                personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese
                                Bestimmung
                                gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine Verarbeitung beruht,
                                entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
                                Ihre
                                betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können
                                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                                Rechte
                                und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                                Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
                            </p>
                            <p style="text-align:left;">Werden Ihre personenbezogenen Daten verarbeitet, um
                                Direktwerbung zu
                                betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie
                                betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt
                                auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn
                                Sie
                                widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke
                                der
                                Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
                            </p>
                            <p style="text-align:left;">Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
                                Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG - Ihr
                                Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische
                                Spezifikationen verwendet werden.
                            </p>
                        </li>
                        <li style="margin-bottom:0.0pt;" value="10"><span> </span>
                            <p><strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde gemäß Art. 77
                                DSGVO<br></strong>
                            </p>
                            <p style="text-align:left;">Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                                Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                                gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes
                                zu.
                                Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                                gerichtlicher Rechtsbehelfe.
                            </p>
                            <p style="text-align:left;">Die für uns zuständige Aufsichtsbehörde ist:<br>
                            </p>
                            <p>Bayerisches Landesamt für Datenschutzaufsicht<br><br>Promenade 18<br>91522
                                Ansbach<br><br>Postanschrift:
                                <br>Postfach 1349, 91504 Ansbach<br> <br>Telefon: 0981/180093-0<br>E-Mail:
                                poststelle@lda.bayern.de<br>Internet: https://www.lda.bayern.de<br/><br/>
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="lhl">
                    <p><strong> </strong><strong>Gültigkeit und Änderung dieser Datenschutzerklärung</strong>
                    </p>
                </li>
                <li style="margin-bottom:18.0pt;" value="23"><span>Diese Datenschutzerklärung gilt ab dem 27. September 2023. Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung der geltenden Datenschutzvorschriften zu ändern. Dies kann z.B. zur Einhaltung neuer Gesetzesbestimmungen oder zur Berücksichtigung der Änderungen unserer Website bzw. neuer Dienstleistungen auf unserer Website erforderlich sein. Es gilt die zum Zeitpunkt Ihres Besuchs abrufbare Fassung.</span><br>
                </li>
                <li style="margin-bottom:0.0pt;" value="24"><span>Sollte diese Datenschutzerklärung geändert werden, beabsichtigen wir, Änderungen an unserer Datenschutzerklärung auf dieser Seite bekannt zu geben, sodass Sie umfassend darüber informiert sind, welche personenbezogene Daten wir sammeln, wie wir sie verarbeiten und unter welchen Umständen sie weitergegeben werden können.</span><br>
                </li>
            </ul>
        </div>
    </div>


</template>
<script setup lang="ts">
</script>
