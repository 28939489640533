<script>

import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";

export default {
    name: "CalendarExerciseCard",
    components: {ModuleOverviewCard},
    props: {
        progress: Number,
        previousModuleProgress: Number,
    }
}
</script>

<template>
    <ModuleOverviewCard
            :progress="progress"
            :previous-module-progress="previousModuleProgress"
            link="/futureme/calendar"
            image-url="futureme/calendar.png"
            title="Mein perfekter Tag"
            description="Welche Aktivitäten geben dir Energie im Alltag und was zieht dich eher runter?"
    />
</template>
