<script>
import axios from "axios";
import ModuleStartButton from "@/components/ModuleStartButton.vue";
import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import SkillSpiderwebCard from "@/components/ModuleOverviewCards/futureskills/SkillSpiderwebCard.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";
import IntroVideoModal from "@/components/BasicUI/IntroVideoModal.vue";

export default {
    name: 'FutureSkills',
    components: {
        IntroVideoModal,
        DescriptionCard,
        SkillSpiderwebCard, TopNavbar, ModuleOverviewCard, ModuleStartButton
    },
    beforeCreate() {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    //Transfer user to page to fill in user data
                    this.$router.push('/dashboard/precourse')
                }
            })
    },
    created() {
        axios.get('/api/v1/progress/').then(response => {
            const { future_skills_quiz } = response.data.future_skills

            this.future_skills_quiz = future_skills_quiz
            this.user_group = response.data.user_group
            this.future_skills_intro = response.data.intros.future_skills_intro
            this.future_skills_video_progress = response.data.videos.future_skills_video? 1 : 0

        })
    },
    data() {
        return {
            future_skills_quiz: -1,
            future_skills_video_progress: -1,
            intro_modal_active: true,
            future_skills_intro: true,
        }
    }
}
</script>

<template>
    <TopNavbar title="Future Skills" back-link="/"/>
    <div class="app-container">
        <div class="app-content-container">
            <IntroVideoModal module="future_skills"
                             :is-active="intro_modal_active"
                             @close-modal="this.intro_modal_active = false"
                             v-if="!future_skills_intro"
            />
            <DescriptionCard
                    title="Einführung"
                    :show-button="true"
                    @rewatch-intro="() => { this.intro_modal_active = true; this.future_skills_intro = false}"
            >
                Wie gut bist du darauf vorbereitet, tatsächlich ins Berufsleben einzusteigen? In diesem Modul lernst du,
                welche Fähigkeiten in Zukunft gefragt sein werden und woran du zukunftssichere Jobs erkennst – und
                findest heraus, wie gut deine eigenen Future Skills aktuell sind.
            </DescriptionCard>
            <ModuleOverviewCard
                    :progress="future_skills_video_progress"
                    :previous-module-progress="1"
                    results-button-text="Erneut ansehen"
                    link="/futureskills/video"
                    image-url="futureskills/futureskills.png"
                    title="Was sind Future Skills?"
                    description="Auf welche Fähigkeiten kommt es in der Zukunft wirklich an? Das erklären wir dir in diesem Video."
            />
            <SkillSpiderwebCard
                    :progress="future_skills_quiz"
                    :previous-module-progress="future_skills_video_progress"
            />
            <button v-if="future_skills_quiz > 0" class="button is-primary is-fullwidth" @click="this.$router.push('/futurework')">Zum nächsten Modul</button>

        </div>
    </div>
</template>
