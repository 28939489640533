export const slider_values = [
    {name: "Mit Zahlen und Daten arbeiten", value: 'data'},
    {name: "Mit Menschen arbeiten", value: 'people'},
    {name: "Handwerklich arbeiten", value: 'craftsmanship'},
    {name: "Kreativ arbeiten", value: 'creativity'},
    {name: "Im Freien arbeiten", value: 'randomness'},
    {name: "Ortsunabhängig arbeiten", value: 'balance'},
    {name: "In verschiedenen Ländern arbeiten", value: 'internationality'},
    {name: "Wie stellst du dir deine Bezahlung im Job vor?", value: 'income'},
]

export const company_values = [
    "Mein Job muss mir persönlich viel Spaß bereiten.",
    "Ich möchte viele abwechslungsreiche Aufgaben machen.",
    "Ich möchte schnell die Karriereleiter hochklettern und in eine hohe Position kommen können.",
    "Ich würde einen Job nur dann annehmen, wenn mir auch die Umgebung des Standorts gefällt.",
    "Für meinen Job würde ich an einen anderen Ort ziehen.",
    "Zur Arbeit möchte ich nicht länger als 30 Minuten brauchen.",
    "Meine Firma soll auf der ganzen Welt Standorte haben und mir die Möglichkeit geben, mit Menschen aus anderen Ländern zusammenzuarbeiten.",
    "Ich möchte die Möglichkeit haben, für eine begrenzte Zeit über meinen Job im Ausland zu leben und zu arbeiten.",
    "Mir ist ausdrückliche Wertschätzung (z.B. Lob durch meine*n Chef*in) sehr wichtig.",
    "Die beste Wertschätzung ist für mich eine angemessene Bezahlung.",
    "Mir ist wichtig, dass ich von meiner Firma für meinen Job mehr Geld bekomme als bei Konkurrenz-Firmen.",
    "Regelmäßige Meetings mit Kolleg*innen zur Besprechung von Projekten sind mir wichtig.",
    "Meine Firma sollte ein Programm anbieten, um mich bei meinem Einstieg gut in den Job einzuarbeiten.",
    "Ich würde gerne auch außerhalb meiner Arbeitszeiten an gemeinsamen Aktivitäten mit meinem Team teilnehmen (z.B. Essen gehen, Ausflüge, Sport-Sessions).",
    "Am besten fühlt sich mein Arbeitsteam an wie eine zweite Familie.",
    "Mein Team sollte mit Personen aus jeder Altersgruppe besetzt sein.",
    "Meine Firma sollte ausgeglichen mit Männern und Frauen besetzt sein, auch in der Chefetage.",
    "In meiner Firma sollten Menschen aus verschiedenen Ländern, Kulturen und Religionen zusammenarbeiten.",
    "In meiner Firma sollten sich Menschen jeder sexuellen Orientierung und Geschlechtsidentität wohlfühlen.",
    "Das Büro sollte modern aussehen und schick eingerichtet sein.",
    "Extra-Angebote meiner Firma wie Gratis-Getränke oder Tisch-Kicker im Büro oder Food Trucks sind mir wichtig.",
    "Eine Firma wird für mich attraktiver, wenn sie mir z.B. eine Fitnessstudio-Mitgliedschaft oder mein ÖPNV-Ticket finanziert.",
    "Ich wünsche mir regelmäßige gegenseitige Feedback-Gespräche mit Vorgesetzten.",
    "Ich möchte meine Arbeitszeiten selbst und flexibel einteilen dürfen.",
    "Meine Firma sollte Kernarbeitszeiten haben, in denen alle da sind (z.B. 10-14 Uhr), und ansonsten flexible Arbeitszeiten anbieten.",
    "Ich möchte gerne in einer 4-Tage-Woche arbeiten.",
    "Ich möchte die Möglichkeit, vollständig von Zuhause aus zu arbeiten.",
    "Meine Firma sollte mir die Möglichkeit bieten, im Rahmen einer \"Workation\" für längere Zeit vom Ausland aus zu arbeiten.",
    "Das wichtigste an meinem Job ist, dass er sicher für die Zukunft ist.",
    "Wenn ich mal bei einer Firma einsteige, möchte ich ihr lange treu bleiben (z.B. über 10 Jahre).",
    "Ich möchte in einer jungen und dynamischen Firma arbeiten, die noch keine allzu festen Strukturen hat (z.B. Start-Up).",
    "Meine Arbeit soll immer nach den gleichen Routinen ablaufen und nicht ständig unerwartete Überraschungen bieten.",
    "Mir ist wichtig, dass ich auf Augenhöhe auch mit den ranghöchsten Mitgliedern der Firma sprechen kann.",
    "Ich möchte, dass mir meine Firma genügend Spielräume zur Verfügung stellt, um eigene Geschäftsideen zu erproben (z.B. eigenes Start-up, experimentelle Projekte in der Firma).",
    "Mir ist wichtig, dass meine Firma nachhaltig und umweltfreundlich handelt.",
    "Mir ist wichtig, dass meine Firma Produkte und Dienstleistungen bereitstellt, die ethischen Standards genügen und Menschen helfen.",
    "Mir ist wichtig, dass meine Firma die neuesten Technologien einsetzt und sich traut, neue Dinge auszuprobieren und Vorhandenes zu hinterfragen.",
    "Meine Firma soll soziale Programme durchführen und unterstützen, z.B. für benachteiligte Kinder oder Menschen mit Behinderung.",
    "Meine Firma muss mir einen Firmenwagen stellen.",
    "Mir ist wichtig, dass ich mit einer*m Chef*in zusammenarbeite, der*die mir sympathisch ist.",
    "Ich möchte nie stillstehen, sondern von der Firma die Möglichkeit bekommen, mich ständig mit Onlinekursen, Seminaren, etc. weiterzubilden.",
]

export const balance_values = [
    {name: "Klare Vorgaben bekommen, wie ich arbeiten soll und mich nach diesen richten können | Früh selbstständig Entscheidungen treffen dürfen", value: 'independence'},
    {name: "Für einen Chef arbeiten | Eigene Firma gründen / selbstständig sein", value: 'independence'},
    {name: "Festgelegte Aufgaben immer wieder durchführen | Immer wieder neue Situationen meistern müssen", value: 'independence'},
    {name: "Sicheres und etabliertes Arbeitsumfeld | Startup-Mentalität mit Risiko und vielen Neuerungen", value: 'independence'},
    {name: "Schreibtisch-Arbeit | Handwerkliche Arbeit", value: 'craftsmanship'},
    {name: "Theoretische Konzepte und Strategien erarbeiten | Angewandt-praktisch arbeiten und direkt Ergebnisse sehen", value: 'craftsmanship'},
    {name: "Mir ist wichtig, was meine Eltern und meine Freunde über meine Entscheidungen denken | Mir ist am wichtigsten, dass ich selber zufrieden bin mit meiner Entscheidung", value: 'randomness'}, //TODO
    {name: "Langfristig in Deutschland bleiben | International leben und arbeiten", value: 'internationality'},
    {name: "An einem festen Ort arbeiten | Viel herumreisen für den Job", value: 'internationality'},
    {name: "Alleine und flexibel leben | Viel Zeit mit meine*r Partner*in / Familie verbringen können", value: 'balance'},
    {name: "Auch mal einspringen / Nachtschichten / Wochenenden arbeiten | Geregelte, planbare Arbeitszeiten", value: 'balance'},
    {name: "Jeden Tag von 9 bis 17 Uhr arbeiten | Pflichtzeit von 10-14 Uhr, sonst selbst flexibel einteilen", value: 'balance'},
    {name: "Auch mal länger arbeiten, wenn das Projekt es gerade erfordert | Nach Feierabend bzw. im Urlaub nicht mehr erreichbar sein", value: 'balance'},
    {name: "Für den Job anwesend im Büro sein | Arbeit von Zuhause, aus dem Ausland, etc.", value: 'balance'},
]
