<template>
  <div class="field mt-4">
    <div class="control">
      <button class="button is-white is-fullwidth" @click="$emit('click')">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fab', 'apple']"/>
        </span>
        <span>Weiter mit Apple</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppleButton',
}
</script>
