<script>

export default {
    name: "ScrollableBox",
    props: {
        title: String,
        description: String,
        maxHeight: {
            type: Number,
            default: 600,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssProps() {
            return {
                'max-height': this.maxHeight + 'px',
            }
        }
    }
}
</script>

<template>
    <div class="fixed-height" :style="cssProps">
        <slot></slot>
    </div>
</template>

<style scoped lang="scss">
.fixed-height {
    overflow-y: scroll;
}
</style>
