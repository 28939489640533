<script>
import axios from "axios";
import ModuleStartButton from "@/components/ModuleStartButton.vue";
import ModuleOverviewCard from "@/components/ModuleOverviewCards/ModuleOverviewCard.vue";
import TopNavbar from "@/components/BasicUI/TopNavbar.vue";
import LifeVisionCard from "@/components/ModuleOverviewCards/futureme/LifeVisionCard.vue";
import ValueCompassCard from "@/components/ModuleOverviewCards/futureme/ValueCompassCard.vue";
import LifeCockpitExercise from "@/views/futureme/LifeCockpitExercise.vue";
import CalendarExerciseCard from "@/components/ModuleOverviewCards/futureme/CalendarExerciseCard.vue";
import LifeCockpitCard from "@/components/ModuleOverviewCards/futureme/LifeCockpitCard.vue";
import IntroVideoModal from "@/components/BasicUI/IntroVideoModal.vue";
import DescriptionCard from "@/components/BasicUI/DescriptionCard.vue";

export default {
    name: 'FutureMe',
    components: {
        DescriptionCard,
        IntroVideoModal,
        LifeCockpitCard,
        CalendarExerciseCard,
        LifeCockpitExercise, ValueCompassCard, LifeVisionCard, TopNavbar, ModuleOverviewCard, ModuleStartButton
    },
    beforeCreate() {
        axios.get('/api/v1/yoloausers/has_filled_user_information/')
            .then(response => {
                if (response.data === 'unfilled') {
                    //Transfer user to page to fill in user data
                    this.$router.push('/dashboard/precourse')
                }
            })
    },
    created() {
        axios.get('/api/v1/progress/').then(response => {
            const { vision_board, core_values, life_cockpit, calendar } = response.data.future_me

            this.vision_board = vision_board
            this.core_values = core_values
            this.life_cockpit = life_cockpit
            this.calendar = calendar

            this.user_group = response.data.user_group
            this.future_me_intro = response.data.intros.future_me_intro
        })
    },
    data() {
        return {
            vision_board: -1,
            core_values: -1,
            life_cockpit: -1,
            progress: -1,
            calendar: -1,
            user_group: -1,
            intro_modal_active: true,
            future_me_intro: true,
        }
    }
}
</script>

<template>
    <TopNavbar title="Future Me" back-link="/"/>
    <div class="app-container">
        <div class="app-content-container">
            <IntroVideoModal :is-active="intro_modal_active" @close-modal="this.intro_modal_active = false"
                             v-if="!future_me_intro"/>
            <DescriptionCard
                    title="Einführung"
                    :show-button="true"
                    @rewatch-intro="() => { this.intro_modal_active = true; this.future_me_intro = false}"
            >
                Hier geht es ganz um dich und die Frage, von was für einem Leben du
                träumst und was du brauchst, um später glücklich und zufrieden zu sein. Denn um dich zu entscheiden,
                welchen
                beruflichen Weg du einschlagen möchtest, musst du dich erst einmal selber besser kennenlernen.
            </DescriptionCard>
            <LifeVisionCard
                    :progress="vision_board"
                    :previous-module-progress="1"
            />
            <ValueCompassCard
                    :progress="core_values"
                    :previous-module-progress="vision_board"
            />
            <LifeCockpitCard
                    :progress="life_cockpit"
                    :previous-module-progress="core_values"
            />
            <CalendarExerciseCard
                    v-if="user_group > 0"
                    :progress="calendar"
                    :previous-module-progress="life_cockpit"
            />
            <button v-if="(user_group > 0 && calendar == 1) || (user_group == 0 && life_cockpit == 1)"
                    class="button is-primary is-fullwidth" @click="this.$router.push('/futureskills')">Zum nächsten
                Modul
            </button>
        </div>
    </div>
</template>
