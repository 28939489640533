<script>
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import LoadingSpinner from "@/components/BasicUI/LoadingSpinner.vue";

export default {
    components: {LoadingSpinner},
    mounted: async function () {
        try {
            const response = await ThirdPartyEmailPassword.thirdPartySignInAndUp();

            if (response.status !== "OK") {
                // either the user did not complete the login process, or something else went wrong.
                console.log(response)

                return window.location.assign("/auth?error=signin");
            }

            // sign in successful.
            // The session tokens are handled automatically via our SDK.
            window.location.assign("/");
        } catch (_) {
            window.location.assign("/auth?error=signin");
        }
    }
}
</script>

<template>
    <div class="app-container-small">
        <div class="app-content-container">
            <LoadingSpinner/>
            <p class="mt-4 has-text-centered is-size-4">Du wirst zur App weitergeleitet...</p>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
